import React from 'react'

import iconsMapping from 'services/helpers/icons_mapping'

import {
  STEP_STATUSES,
  LOCATION_TYPE_INLAND,
  LOCATION_TYPE_COMPANY,
  LOCATION_TYPE_ADDRESS,
  FACILITY_TYPE_INLAND,
  FACILITY_TYPE_DEPOT,
} from 'constants/shipments'

import Icon from 'components/icon'
import Flag, { FlagType } from 'components/flag'
import {
  StyledStepHead,
  StyledStep,
  StyledStepName,
  StyledStepFlag,
  StyledStepIcon,
  StyledStepMilestones,
  StyledStepProgress,
} from 'components/timeline/style'
import HoverTooltip from 'components/hover_tooltip'

import { isPresent } from 'services/helpers/values'

import { Location } from 'views/shipments/types/shipment'

import type { TransportType, FacilityType, LocationTypes, VehicleType } from 'constants/shipments'

export interface TimelineStepProps {
  type: TransportType | FacilityType | LocationTypes
  location: Location
  status: typeof STEP_STATUSES[number]
  inTransit: boolean
  vehicleType?: VehicleType | null
  testId?: string
  children?: any
}

const TimelineStep = ({
  type,
  location,
  inTransit,
  status,
  children,
  vehicleType,
  testId,
}: TimelineStepProps) => {
  const tooltip = [location.address, location.city, location.country]
    .filter((locationElement) => isPresent(locationElement))
    .join(', ')
  return (
    <StyledStep $status={status} $inTransit={inTransit} data-testid={testId}>
      <StyledStepHead>
        <StyledStepIcon>
          <Icon name={iconsMapping(type, 'timeline')} testId={`${testId}-icon`} />
        </StyledStepIcon>
        {[
          LOCATION_TYPE_INLAND,
          LOCATION_TYPE_COMPANY,
          LOCATION_TYPE_ADDRESS,
          FACILITY_TYPE_DEPOT,
          FACILITY_TYPE_INLAND,
        ].some((element) => element === type) ? (
          <HoverTooltip content={tooltip} size='large' ellipsis>
            <StyledStepName>{location.name}</StyledStepName>
          </HoverTooltip>
        ) : (
          <StyledStepName>{location.name}</StyledStepName>
        )}
        {location.name && location.countryCode && (
          <StyledStepFlag
            as={Flag}
            countryCode={location.countryCode as FlagType}
            testId={`${testId}-flag`}
          />
        )}
      </StyledStepHead>

      <StyledStepMilestones>{children}</StyledStepMilestones>
      <StyledStepProgress>
        {vehicleType && <Icon name={iconsMapping(vehicleType, 'transportation')} />}
      </StyledStepProgress>
    </StyledStep>
  )
}

export default TimelineStep
