import React from 'react'

import AlertListItem from 'components/alert_list_item'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

const AlertList = ({ children }: { children?: typeof AlertListItem }) => (
  <div>
    <ul>{children}</ul>
  </div>
)

AlertList.propTypes = {
  /** AlertListItem */
  children: validateChildrenOfTypes([AlertListItem]),
}

AlertList.defaultProps = {
  children: undefined,
}

export default AlertList
