import React from 'react'
import ContentLoader from 'react-content-loader'

import {
  StyledSavedFiltersItem,
  StyledSavedFiltersSection,
} from 'components/shipment_filters_saved_item/style'

const ShipmentFiltersSavedItemSkeleton = () => (
  <StyledSavedFiltersItem>
    <StyledSavedFiltersSection>
      <ContentLoader viewBox='0 0 115 23' style={{ width: '100%' }}>
        <rect x='0' y='4' rx='3' ry='3' width='70' height='3' />
        <rect x='0' y='14' rx='5' ry='5' width='28' height='8' />
        <rect x='32' y='14' rx='5' ry='5' width='28' height='8' />
        <rect x='64' y='14' rx='5' ry='5' width='28' height='8' />
      </ContentLoader>
    </StyledSavedFiltersSection>
  </StyledSavedFiltersItem>
)

ShipmentFiltersSavedItemSkeleton.propTypes = {}

ShipmentFiltersSavedItemSkeleton.defaultProps = {}

export default ShipmentFiltersSavedItemSkeleton
