import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { MultiSelectProps, Tvalue } from 'components/select'

import { ISearch } from 'views/select_options/slice'

import MultiSelect from 'components/multiselect'

import type { AsyncThunkAction } from '@reduxjs/toolkit'

export interface ISearchMultiSelectProps extends MultiSelectProps {
  action: (search: ISearch) => AsyncThunkAction<any, any, any>
}

const SearchMultiSelect: FC<ISearchMultiSelectProps> = ({
  action,
  isClearable = true,
  ...props
}) => {
  const actionParams = (v: Tvalue) => ({ search: v })
  const fetch = ({ value: v }: { value: Tvalue }) => action(actionParams(v))
  const fetchOnFocus = () => action(actionParams(null))
  const { t } = useTranslation()

  return (
    <MultiSelect
      placeholder={t('actions.search')}
      isClearable={isClearable}
      isSearchable
      async
      fetch={fetch}
      fetchOnFocus={fetchOnFocus}
      // Prop spreading is allowed here because SearchMultiSelect is a wrapper around MultiSelect:
      // it sets default props and fetch utilities. Any other props are standard MultiSelect props.
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default SearchMultiSelect
