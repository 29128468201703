import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'

import { selectUsers, selectUsersStatus, selectUserstotalCount } from 'views/users/slice'

// TypeScript inference works great here, no need to define interface twice
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUsers = () => {
  const users = useSelector(selectUsers)
  const status = useStatus(useSelector(selectUsersStatus))
  const totalCount = useSelector(selectUserstotalCount)
  return { users, status, totalCount }
}

export default useUsers
