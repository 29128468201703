import React, { useEffect, useRef, useState } from 'react'

import AboveModal from 'components/above_modal'
import Tooltip from 'components/tooltip'
import { StyledIconTooltip, StyledIconWrap } from 'components/hover_tooltip/style'
import { TooltipPlacementType, TooltipSizeType } from 'components/tooltip/types'

// TODO: REFACTOR
// Interface should pick or extend TooltipProps
interface HoverTooltipProps {
  placement?: TooltipPlacementType
  size?: TooltipSizeType
  delayed?: boolean
  content: React.ReactChild
  ellipsis?: boolean
  inline?: boolean
  testId?: string
  color?: 'white' | 'primary' | 'warn'
}

// TODO: REFACTOR
// HoverTooltip wraps children in another component, making children css properties conflicting
// Maybe the ref used should be children ref
const HoverTooltip: React.FC<HoverTooltipProps> = ({
  placement = 'top',
  size,
  content,
  delayed,
  children,
  ellipsis = false,
  inline = false,
  testId,
  color,
}) => {
  const [isChildrenHover, setIsChildrenHover] = useState(false)
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const ref = useRef<HTMLInputElement>(null)
  const rect = ref.current?.getBoundingClientRect()

  useEffect(() => {
    if (rect) {
      const { x, y, width: w, height: h } = rect
      switch (placement) {
        case 'top':
          setPosition({ x: x + w / 2, y })
          break
        case 'top-left':
          setPosition({ x: x + w / 2, y })
          break
        case 'bottom':
          setPosition({ x: x + w / 2, y: y + h })
          break
        case 'left':
          setPosition({ x, y: y + h / 2 })
          break
        case 'right':
          setPosition({ x: x + w, y: y + h / 2 })
          break
        default:
          setPosition({ x: x + w / 2, y: y + h / 2 })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rect?.x, rect?.y])

  return (
    <StyledIconTooltip
      onMouseEnter={() => setIsChildrenHover(true)}
      onMouseLeave={() => setIsChildrenHover(false)}
      ref={ref}
      $ellipsis={ellipsis}
      $inline={inline}
      data-testid={testId}
    >
      {children}
      {isChildrenHover && (
        <AboveModal>
          <StyledIconWrap>
            <Tooltip
              color={color}
              placement={placement}
              size={size}
              content={content}
              position={position}
              visible={isChildrenHover}
              delayed={delayed}
            />
          </StyledIconWrap>
        </AboveModal>
      )}
    </StyledIconTooltip>
  )
}

export default HoverTooltip
