// SharedShipment is a minimalist view of Shipment
// It therefore shares a lot of elements, suchas components, styles, services

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Page from 'components/page'
import Button from 'components/button'

import useTracker from 'services/analytics/hooks/use_tracker'
import useOnce from 'services/hooks/use_once'

import ShipmentTimeline from 'views/shipment/components/timeline'
import ShipmentAlertList from 'views/shipment/components/alert_list'
import ShipmentQuickInfo from 'views/shipment/components/quick_info'
import ShipmentMap from 'views/shipment/components/map'
import SharedReferences from 'views/shipment/components/shared_references'
import SharedHeader from 'views/shipment/components/shared_header'

import ShipmentContext from 'views/shipment/context'
import {
  StyledShipment,
  StyledShipmentLeftCol,
  StyledShipmentMain,
  StyledShipmentContent,
  StyledShipmentInformationContent,
  StyledShipmentStateSwitchButton,
  StyledShipmentWrap,
  StyledShipmentContentTabs,
} from 'views/shipment/style'
import { fetchSharedUser } from 'views/iam/slices/iamSlice'
import { fetchSharedShipment } from 'views/shipment/slice'
import { addNotification } from 'views/notifications/slice'
import ViewSwitcher from 'components/view_switcher'
import { Tab } from 'components/tabs'

const VIEW_TIMELINE = 'timeline'
const VIEW_ALERTS = 'alerts'

const SharedShipment = React.memo(() => {
  const [viewState, setViewState] = useState('content')
  const [currentView, setCurrentView] = useState(VIEW_TIMELINE)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { track } = useTracker()
  const { token } = useParams()

  useOnce(() => {
    dispatch(fetchSharedShipment({ token }))
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })

    dispatch(fetchSharedUser({ token }))
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  })

  return (
    <Page plain>
      <ShipmentContext.Provider value={{ id: token }}>
        <StyledShipment $viewState={viewState}>
          <StyledShipmentStateSwitchButton
            as={Button}
            onClick={() => setViewState(viewState === 'timeline' ? 'content' : 'timeline')}
            icon={viewState === 'timeline' ? 'filesheet' : 'time_clock'}
            rounded
          />

          {/* Left col */}
          <StyledShipmentLeftCol>
            <ShipmentQuickInfo />
            <ViewSwitcher
              label={`${t('actions.view')} ${
                currentView === VIEW_TIMELINE ? t('shipments.timeline') : t('alerts.list')
              }`}
              onSwitch={() => {
                const targettedView = currentView === VIEW_TIMELINE ? VIEW_ALERTS : VIEW_TIMELINE
                setCurrentView(targettedView)
                track(`Shipment / ${targettedView}`)
              }}
            />
            {currentView === VIEW_TIMELINE && <ShipmentTimeline />}
            {currentView === VIEW_ALERTS && <ShipmentAlertList />}
          </StyledShipmentLeftCol>

          {/* Main */}
          <StyledShipmentMain>
            <ShipmentMap />

            {/* Back button */}
            <StyledShipmentWrap>
              {/* Content */}
              <StyledShipmentContent>
                <SharedHeader />
                <StyledShipmentContentTabs>
                  <Tab id='information' label={t('shipments.information')} key='tab-information'>
                    <StyledShipmentInformationContent>
                      <SharedReferences />
                    </StyledShipmentInformationContent>
                  </Tab>
                </StyledShipmentContentTabs>
              </StyledShipmentContent>
            </StyledShipmentWrap>
          </StyledShipmentMain>
        </StyledShipment>
      </ShipmentContext.Provider>
    </Page>
  )
})

SharedShipment.propTypes = {}

SharedShipment.defaultProps = {}

export default SharedShipment
