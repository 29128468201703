import { createSlice, createAsyncThunk, createEntityAdapter, Comparer } from '@reduxjs/toolkit'

import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'
import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import DateHelper from 'services/helpers/date_helper'
import useUrlParams from 'services/api/hooks/use_url_params'

import { STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED } from 'constants/api'
import { isPresent } from 'services/helpers/values'

import type { Status } from 'constants/api'
import type { RootState } from 'services/store/store'
import type { CustomKpi } from 'views/custom_kpis/types'

export const fetchCustomKpis = createAsyncThunk(
  'customKpis/fetchCustomKpis',
  async (_, thunkAPI) => {
    const { teams } = useThunkTeamsParam(thunkAPI)
    const url = useUrlParams('/custom_kpis', { teams })

    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const createCustomKpi = createAsyncThunk(
  'customKpis/createCustomKpi',
  async ({ customKpi }: { customKpi: CustomKpi }, thunkAPI) => {
    const { teams } = useThunkTeamsParam(thunkAPI)
    return InternalClient.post('/custom_kpis', { ...customKpi, teams })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const updateCustomKpi = createAsyncThunk(
  'customKpis/updateCustomKpi',
  async (
    { id, changes }: { id: number; changes: Record<string, Partial<CustomKpi>> },
    thunkAPI
  ) => {
    const { teams } = useThunkTeamsParam(thunkAPI)
    return InternalClient.patch(`/custom_kpis/${id}`, { ...changes, teams })
      .then((r) => r.data)
      .then((customKpi) => ({ id: customKpi.id, changes: customKpi }))
      .catch(onError(thunkAPI))
  }
)

export const deleteCustomKpi = createAsyncThunk(
  'customKpis/deleteCustomKpi',
  async ({ id }: { id: number }, thunkAPI) =>
    InternalClient.delete(`custom_kpis/${id}`)
      .then((r) => r.data)
      .then((customKpi) => customKpi.id)
      .catch(onError(thunkAPI))
)

const sortByLastUpdatedDesc: Comparer<CustomKpi> = (a, b) =>
  new DateHelper(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1
const customKpisAdapter = createEntityAdapter<CustomKpi>({
  sortComparer: sortByLastUpdatedDesc,
})
const initialState = customKpisAdapter.getInitialState<{
  selectedCustomKpiId?: number
  status: Status
}>({
  selectedCustomKpiId: undefined,
  status: STATUS_PENDING,
})

const customKpisSlice = createSlice({
  name: 'customKpis',
  initialState,
  reducers: {
    setSelectedCustomKpiId: (state, action) => {
      state.selectedCustomKpiId = action.payload.id
    },
    unsetSelectedCustomKpiId: (state) => {
      state.selectedCustomKpiId = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomKpis.fulfilled, (state, action) => {
      customKpisAdapter.setAll(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchCustomKpis.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchCustomKpis.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(createCustomKpi.fulfilled, (state, action) => {
      customKpisAdapter.addOne(state, action.payload)
    })
    builder.addCase(updateCustomKpi.fulfilled, (state, action) => {
      customKpisAdapter.updateOne(state, action.payload)
    })
    builder.addCase(deleteCustomKpi.fulfilled, customKpisAdapter.removeOne)
  },
})

export const { setSelectedCustomKpiId, unsetSelectedCustomKpiId } = customKpisSlice.actions
export const selectSelectedCustomKpiId = (state: RootState) => state.customKpis.selectedCustomKpiId

export const { selectById, selectAll: selectCustomKpis } = customKpisAdapter.getSelectors(
  (state: RootState) => state.customKpis
)

export const selectCustomKpisStatus = (state: RootState) => state.customKpis.status

export const selectCustomKpi = (state: RootState, id: number | undefined) =>
  isPresent(id) ? selectById(state, id) : undefined
export default customKpisSlice.reducer
