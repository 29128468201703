import React, { useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import BlockDocuments from 'components/block_documents'
import BlockDocumentsItem from 'components/block_documents/block_documents_item'

import { DOCUMENT_DELETE } from 'constants/permissions'
import { DOCUMENTS_UPLOAD } from 'constants/organization_display_settings'

import useUserCan from 'views/iam/hooks/use_user_can'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import {
  deleteDocument as deleteDocumentRequest,
  fetchShipment,
  resetDocumentUploadProgresses,
  selectDocuments,
  uploadDocument,
  selectDocumentUploadProgresses,
} from 'views/shipment/slice'
import ShipmentContext from 'views/shipment/context'
import DocumentContext from 'views/documents/contexts/document_context'
import { addNotification } from 'views/notifications/slice'
import { isPresent, toCamelCase } from 'services/helpers/values'
import useShallowSelector from 'services/hooks/use_shallow_selector'
import ModalUploadDocument from 'components/modal_upload_document'

import {
  TEST_ID_SHIPMENT_DOCUMENT_LIST,
  TEST_ID_TRANSPORT_DOCUMENT_ITEM_DELETE,
  TEST_ID_TRANSPORT_DOCUMENT_ITEM_DOWNLOAD,
} from 'tests/e2e/test_ids'

const ShipmentDocumentList = () => {
  const userCan = useUserCan()
  const dispatch = useDispatch()
  const { id } = useContext(ShipmentContext)
  const { t } = useTranslation()
  const user = useCurrentUser()
  const organizationCan = useOrganizationCan()
  const canUploadDocument = organizationCan.display(DOCUMENTS_UPLOAD)
  const uploadProgresses = useShallowSelector(selectDocumentUploadProgresses)

  const documentContextValue = useMemo(
    () => ({ canUpload: canUploadDocument }),
    [canUploadDocument]
  )

  const documents = useSelector(selectDocuments({ id }))

  const deleteDocument =
    ({ id: documentId, name }) =>
    (e) => {
      e.preventDefault()
      dispatch(deleteDocumentRequest({ documentId }))
        .unwrap()
        .then(() => {
          dispatch(
            addNotification({
              type: 'success',
              title: t('documents.deletion.title'),
              text: t('documents.deletion.successMessage', { name }),
            })
          )
          dispatch(fetchShipment({ id }))
        })
        .catch(() => {
          dispatch(
            addNotification({
              type: 'alert',
              title: t('documents.deletion.title'),
              text: t('documents.deletion.errorMessage', { name }),
            })
          )
        })
    }

  return (
    <DocumentContext.Provider value={documentContextValue}>
      <ModalUploadDocument
        onClear={() => dispatch(resetDocumentUploadProgresses())}
        onUpload={(data) => dispatch(uploadDocument({ id, ...data }))}
        uploadProgresses={uploadProgresses}
        onSuccessUpload={() => {
          // TODO: REFACTOR:
          // The shipment documents implementation comes from v2 where a single payload contains all shipment data, including documents.
          // Therefore, it is not possible to update only documents list.
          // A new /documents route should be created in order to manipulate this list only.
          // Alternatively, document creation may return the record, with its id, so that it can be added to the list via a store action.
          // It would allow document deletion and download immediately
          dispatch(fetchShipment({ id }))
        }}
      />
      <BlockDocuments>
        {documents.map((document) => {
          const { id: did, userId, name, documentType, url, createdAt } = document
          const isExternalDocument = isPresent(userId)
          const documentBelongsToUser = user.profile.id === userId
          const canDelete =
            isExternalDocument && (userCan(DOCUMENT_DELETE) || documentBelongsToUser)
          return (
            <BlockDocumentsItem
              key={`document-${did}`}
              name={name}
              type={t(`static.documentTypes.${toCamelCase(documentType)}`)}
              createdAt={createdAt}
              canDelete={canDelete}
              onDelete={deleteDocument(document)}
              link={url}
              testId={TEST_ID_SHIPMENT_DOCUMENT_LIST}
              deleteButtonTestId={TEST_ID_TRANSPORT_DOCUMENT_ITEM_DELETE}
              downloadLinkTestId={TEST_ID_TRANSPORT_DOCUMENT_ITEM_DOWNLOAD}
            />
          )
        })}
      </BlockDocuments>
    </DocumentContext.Provider>
  )
}

ShipmentDocumentList.propTypes = {}

ShipmentDocumentList.defaultProps = {}

export default ShipmentDocumentList
