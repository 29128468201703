import styled from 'styled-components'

import {
  StyledModalHeader,
  StyledModalHead,
  StyledModalContent,
  StyledModalClose,
  StyledModalFooter,
  StyledModal,
} from 'components/modal/style'
import {
  StyledShipmentListItem,
  StyledShipmentListItemHead,
  StyledShipmentListItemIcon,
} from 'components/shipment_list_item/style'
import { StyledTimeline } from 'components/shipment_timeline/style'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { shadow } from 'styles/utils/shadow'
import { buttonSmall } from 'styles/utils/texts'

export const StyledModalShipmentsPrevNext = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.white};
  width: 100%;
  height: 100%;
  transform: translateY(20px) scale(0.97);
  z-index: -1;
  opacity: 0.2;
  ${borderRadiusMedium};
  ${shadow};
  &:before {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.white};
    width: 100%;
    height: 100%;
    transform: translateY(20px) scale(0.97);
    z-index: -1;
    opacity: 0.2;
    ${borderRadiusMedium};
    ${shadow};
  }
`

export const StyledModalShipments = styled(StyledModal)`
  overflow: visible;
  min-height: 230px;
  display: flex;
  flex-direction: column;

  ${StyledModalHeader} {
    height: 44px;
    font-size: 15px;
    ${borderRadiusMedium};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  ${StyledModalHead} {
    display: flex;
    align-items: center;
  }
  ${StyledModalClose} {
    top: 10px;
  }
  ${StyledModalContent} {
    flex-grow: 1;
    padding: 15px 20px;
  }
  ${StyledShipmentListItem} {
    padding: 0;
    &:not(:last-child) {
      border-bottom: none;
    }
  }
  ${StyledShipmentListItemHead} {
    width: 100% !important;
  }
  ${StyledShipmentListItemIcon} {
    display: none;
  }
  ${StyledModalFooter} {
    height: 43px;
    overflow: hidden;
    ${borderRadiusMedium};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  ${StyledTimeline} {
    padding-left: 0;
  }
`

export const ModalShipmentsHeadingTitle = styled.p`
  text-transform: capitalize;
`

export const ModalShipmentsHeadingIcon = styled.div`
  width: 23px;
  fill: ${({ theme }) => theme.white};
  margin-right: 15px;
`

export const ModalShipmentsNavPrev = styled.button`
  ${buttonSmall}
  color: ${({ theme }) => theme.mediumGray};
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
export const ModalShipmentsNavNext = styled.button`
  ${buttonSmall}
  color: ${({ theme }) => theme.mediumGray};
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.lightGray};
  cursor: pointer;
`

export const ModalShipmentsNavArrow = styled.div`
  width: 23px;
  fill: ${({ theme }) => theme.mediumGray};
`
