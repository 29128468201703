import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { IFilter } from 'services/hooks/use_filter'

import Form from 'components/form'
import Grid from 'components/grid'

import { BookingMerchandiseContainerContent } from 'views/booking/slices/types'

import Select from 'components/select'
import Input from 'components/input'

import { BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import {
  StyledAddMerchandiseDetailsWrapper,
  StyledAddMerchandiseItemWrapper,
  StyledRemoveContainerRequestButtonWrapper,
} from 'views/booking/components/form/style'

import Button from 'components/button'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

import { getTestIdForMerchandiseContainer } from 'tests/e2e/test_ids'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'
import MerchandiseTotalDetails from 'views/booking/components/form/merchandise/merchandise_total_details'

interface MerchandiseTotalProps {
  merchandiseFilter: IFilter<'custom'>
}

const MerchandiseTotal: FC<MerchandiseTotalProps> = ({
  merchandiseFilter,
}: MerchandiseTotalProps) => {
  const { s } = useStaticLocales()
  const { features } = useOrganizationCan()
  const { t } = useTranslation()

  return (
    <Form.Group>
      <Form.Insert subtitle={t('bookings.merchandise.containersRequest')}>
        <Grid columns={3}>
          {merchandiseFilter.value.total.containers.map(
            (_container: BookingMerchandiseContainerContent, index: number) => (
              <Grid.Row compact testId={getTestIdForMerchandiseContainer(index)}>
                <Grid.Column>
                  <Select
                    label={t('bookings.merchandise.type')}
                    placeholder={t('bookings.merchandise.containerType')}
                    options={Object.entries(s('containerTypes')).map(([key, value]) => ({
                      value: key,
                      label: value,
                    }))}
                    name={`containerType-${index}`}
                    value={merchandiseFilter.value.total.containers[index]?.containerType}
                    onChange={({ value }) => {
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.total.containers[index]) state.total.containers[index] = {}
                        state.total.containers[index].containerType = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    isDisabled={merchandiseFilter.isDisabled}
                    inputId={`${merchandiseFilter.name}-containerType-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    type='number'
                    label={t('bookings.merchandise.quantity')}
                    placeholder={t('bookings.merchandise.quantity')}
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY}
                    name={`containerType-${index}`}
                    error={merchandiseFilter.error?.total?.containers[index]?.quantity}
                    value={merchandiseFilter.value.total?.containers[index]?.quantity}
                    onChange={({ target: { value } }) => {
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.total.containers[index]) state.total.containers[index] = {}
                        state.total.containers[index].quantity = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    disabled={merchandiseFilter.isDisabled}
                    id={`${merchandiseFilter.name}-quantity-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  {index !== 0 && (
                    <StyledRemoveContainerRequestButtonWrapper>
                      <Button
                        variant='icon'
                        icon='trash'
                        disabled={merchandiseFilter.isDisabled}
                        onClick={() => {
                          merchandiseFilter.onChange((state: any) => {
                            state.total.containers.splice(index, 1)
                          })
                        }}
                      />
                    </StyledRemoveContainerRequestButtonWrapper>
                  )}
                </Grid.Column>
              </Grid.Row>
            )
          )}
          <Grid.Row>
            <Grid.Column size={3}>
              <StyledAddMerchandiseItemWrapper>
                <Button
                  onClick={() =>
                    merchandiseFilter.onChange((state: any) => {
                      state.total.containers[state.total.containers.length] = {}
                    })
                  }
                  disabled={merchandiseFilter.isDisabled}
                  icon='plus_outline'
                  variant='dashed'
                  text={`${t('actions.addNew', { context: 'male' })} ${t(
                    'bookings.merchandise.containerRequest'
                  ).toLowerCase()}`}
                />
              </StyledAddMerchandiseItemWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Insert>
      {features(WITH_BOOKING_OLD_WORKFLOW) ? (
        <MerchandiseTotalDetails
          merchandiseFilter={merchandiseFilter}
          withMultipleDetails={false}
        />
      ) : (
        <Form.Insert subtitle={t('bookings.merchandise.merchandiseDetails')}>
          <MerchandiseTotalDetails merchandiseFilter={merchandiseFilter} withMultipleDetails />
          <StyledAddMerchandiseDetailsWrapper>
            <StyledAddMerchandiseItemWrapper>
              <Button
                disabled={merchandiseFilter.isDisabled}
                onClick={() =>
                  merchandiseFilter.onChange((state: any) => {
                    state.total.products[state.total.products.length] = {}
                  })
                }
                icon='plus_outline'
                variant='dashed'
                text={`${t('actions.addNew', { context: 'plural' })} ${t(
                  'bookings.merchandise.merchandiseDetails'
                ).toLowerCase()}`}
              />
            </StyledAddMerchandiseItemWrapper>
          </StyledAddMerchandiseDetailsWrapper>
        </Form.Insert>
      )}
    </Form.Group>
  )
}

export default MerchandiseTotal
