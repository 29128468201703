export const ACCESS_TOKEN_KEY = 'wakeo_access_token'
export const AUTHENTICATION_TYPE_KEY = 'wakeo_authentication_type'
export const REFRESH_TOKEN_KEY = 'wakeo_refresh_token'

export const OAUTH_AUTHENTICATION_TYPE = 'oauth2'

export const saveAccessToken = (access_token: string) =>
  window.localStorage.setItem(ACCESS_TOKEN_KEY, access_token)

export const saveAuthenticationType = (authentication_type: string) =>
  window.localStorage.setItem(AUTHENTICATION_TYPE_KEY, authentication_type)

export const saveRefreshToken = (refresh_token: string) =>
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token)

export const saveTokens = (headers: { [key: string]: string }) => {
  saveAuthenticationType(headers[AUTHENTICATION_TYPE_KEY])
  saveRefreshToken(headers[REFRESH_TOKEN_KEY])
  saveAccessToken(headers[ACCESS_TOKEN_KEY])
}

export const removeTokens = () => {
  window.localStorage.removeItem(AUTHENTICATION_TYPE_KEY)
  window.localStorage.removeItem(ACCESS_TOKEN_KEY)
  window.localStorage.removeItem(REFRESH_TOKEN_KEY)
}

export const createHeadersForAPIAuthentication = (headers: { [key: string]: string }) => ({
  ...headers,
  [ACCESS_TOKEN_KEY]: getAccessToken(),
  [AUTHENTICATION_TYPE_KEY]: getAuthenticationType(),
})

export const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY)

export const getAuthenticationType = () => window.localStorage.getItem(AUTHENTICATION_TYPE_KEY)

export const getRefreshToken = () => window.localStorage.getItem(REFRESH_TOKEN_KEY)

export const isOAUTH2AuthenticationType = () =>
  getAuthenticationType() === OAUTH_AUTHENTICATION_TYPE
