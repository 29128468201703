import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import {
  STATUS_PENDING,
  STATUS_FULFILLED,
  STATUS_REJECTED,
  Status,
  STATUS_UNSTARTED,
} from 'constants/api'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useUrlParams from 'services/api/hooks/use_url_params'
import useFormData from 'services/api/hooks/use_form_data'

import type { User } from 'views/users/types'
import type { RootState } from 'services/store/store'

export const DEFAULT_USERS_PER_PAGE = 30

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (
    {
      page = 1,
      per = DEFAULT_USERS_PER_PAGE,
      organizationId,
      filtered,
    }: { page: number; per?: number; filtered?: string; organizationId?: number },
    thunkAPI
  ) => {
    // Filters are named "filtered" and "forOrganization" because of an implementation detail on our backend.
    // The name of the filter is the name of the model scope we use.
    const url = useUrlParams('/users', { page, per, filtered, forOrganization: organizationId })

    return InternalClient.get(url)
      .then((r) => r.data)
      .then(({ pagination: { totalCount }, users }) => ({ totalCount, users }))
      .catch(onError(thunkAPI))
  }
)

type UserSettings = Pick<User, 'email' | 'settings' | 'firstName' | 'lastName' | 'id' | 'roles'>
export const fetchUserSettings = createAsyncThunk<
  UserSettings,
  { id: number },
  { state: RootState }
>('users/fetchUserSettings', async ({ id }, thunkAPI) =>
  InternalClient.get(`users/settings/${id}`)
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const toggleNotifications = createAsyncThunk(
  'users/toggleNotifications',
  async ({ id }: { id: number }, thunkAPI) =>
    InternalClient.post(`/users/toggle_notifications/${id}`)
      .then((r) => r.data)
      .then((user) => ({ id: user.id, changes: user }))
      .catch(onError(thunkAPI))
)

export const toggleActivation = createAsyncThunk(
  'users/toggleActivation',
  async ({ id }: { id: number }, thunkAPI) =>
    InternalClient.get(`/users/toggle_activate_user/${id}`)
      .then((r) => r.data)
      .then((user) => ({ id: user.id, changes: user }))
      .catch(onError(thunkAPI))
)

export const sendNewCredentials = createAsyncThunk(
  'users/sendNewCredentials',
  async ({ id }: { id: number }, thunkApi) =>
    InternalClient.put(`/users/send_new_creds/${id}`)
      .then((r) => r.data)
      .then((user) => ({ id: user.id, changes: user }))
      .catch(onError(thunkApi))
)

export const sendSsoWelcomeEmail = createAsyncThunk(
  'users/sendSsoWelcomeEmail',
  async ({ id }: { id: number }, thunkApi) =>
    InternalClient.put(`/users/send_sso_welcome_mail/${id}`)
      .then((r) => r.data)
      .then((user) => ({ id: user.id, changes: user }))
      .catch(onError(thunkApi))
)

export type UserData = Pick<
  User,
  'firstName' | 'lastName' | 'email' | 'organizationId' | 'companyId' | 'organizationCodes'
> & {
  roleIds: number[]
  id?: number
}

export const createUser = createAsyncThunk(
  'users/createUser',
  async (userData: { user: UserData }, thunkAPI) =>
    InternalClient.post('/users.json', userData)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const importUsers = createAsyncThunk(
  'users/importUsers',
  async ({ file }: { file: File }, thunkAPI) => {
    const formData = useFormData({
      file,
    })
    return InternalClient.post('/users/import_users', formData)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const downloadImportUsersTemplate = createAsyncThunk(
  'users/downloadImportUsersTemplate',
  async (_, thunkAPI) =>
    InternalClient.get('/users/template_import_users', { responseType: 'blob' })
      .then((r) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'users_import_template.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(onError(thunkAPI))
)

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (userData: { user: UserData }, thunkAPI) =>
    InternalClient.patch('/users/admin_update.json', userData)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

const usersAdapter = createEntityAdapter<User>()
const initialState = usersAdapter.getInitialState<{
  status: Status
  totalCount: number
  importStatus: Status
  toggleActivationStatus: Status
}>({
  status: STATUS_PENDING,
  totalCount: 0,
  importStatus: STATUS_UNSTARTED,
  toggleActivationStatus: STATUS_UNSTARTED,
})

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetImportStatus: (state) => {
      state.importStatus = STATUS_UNSTARTED
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.totalCount = action.payload.totalCount
      if (action.meta.arg.page <= 1) {
        usersAdapter.setAll(state, action.payload.users)
      } else {
        usersAdapter.setMany(state, action.payload.users)
      }
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchUsers.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchUsers.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(toggleNotifications.fulfilled, usersAdapter.updateOne)
    builder.addCase(toggleActivation.fulfilled, (state, action) => {
      state.toggleActivationStatus = STATUS_FULFILLED
      usersAdapter.updateOne(state, action)
    })
    builder.addCase(toggleActivation.pending, (state) => {
      state.toggleActivationStatus = STATUS_PENDING
    })
    builder.addCase(toggleActivation.rejected, (state) => {
      state.toggleActivationStatus = STATUS_REJECTED
    })
    builder.addCase(importUsers.fulfilled, (state) => {
      state.importStatus = STATUS_FULFILLED
    })
    builder.addCase(importUsers.pending, (state) => {
      state.importStatus = STATUS_PENDING
    })
    builder.addCase(importUsers.rejected, (state) => {
      state.importStatus = STATUS_REJECTED
    })
  },
})

export const { resetImportStatus } = usersSlice.actions

export const { selectAll: selectUsers } = usersAdapter.getSelectors(
  (state: RootState) => state.users
)
export const selectUsersStatus = (state: RootState) => state.users.status
export const selectUserstotalCount = (state: RootState) => state.users.totalCount
export const selectUsersImportStatus = (state: RootState) => state.users.importStatus
export const selectToggleActivationStatus = (state: RootState) => state.users.toggleActivationStatus

export default usersSlice.reducer
