export const PortType = 'PORT'
export const AirportType = 'AIRPORT'
export const RailStationType = 'RAIL_STATION'
export const CityType = 'CITY'
export const DepotType = 'DEPOT'

export const possibleHubTypes = [
  PortType,
  AirportType,
  RailStationType,
  CityType,
  DepotType,
] as const

export type HubType = typeof possibleHubTypes[number]

interface BaseHub {
  token: string
  name: string
  type: HubType
  position: { longitude: number; latitude: number } | null
  cluster: { name: string; token: string }
  country: { name: string; token: string }
  state: { name: string; token: string } | null
}

interface Port extends BaseHub {
  type: typeof PortType
  locode: string
  locodeAliases: string[]
}

interface Airport extends BaseHub {
  type: typeof AirportType
  locode: string
  locodeAliases: string[]
  iataCode: string | null
  icaoCode: string | null
}

interface RailStation extends BaseHub {
  type: typeof RailStationType
  locode: string
  locodeAliases: string[]
}

interface City extends BaseHub {
  type: typeof CityType
  locode: string
  locodeAliases: string[]
  postalCode: string | null
}

interface Depot extends BaseHub {
  type: typeof DepotType
}

export type Hub = Port | Airport | RailStation | City | Depot
