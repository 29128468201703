import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useHistory, useLocation } from 'react-router-dom'

import S from 'views/trusted_routes/components/search_bar/style'
import {
  fetchPorts,
  setStatusFulfilled,
  startNewSubscription,
  selectTrustedRoutesStatus,
  fetchTrustedRoutes,
  selectActiveQueryParams,
  updateActiveQueryParams,
  subscribeTrustedRoutes,
  selectSubscriptionID,
} from 'views/trusted_routes/slice'
import useForm from 'services/hooks/use_form'
import Select from 'components/select'
import useFilter from 'services/hooks/use_filter'
import Button from 'components/button'
import useCategory from 'services/hooks/use_category'
import DateHelper from 'services/helpers/date_helper'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { STATUS_PENDING } from 'constants/api'
import InputDatepicker from 'components/input_datepicker'
import useQueryParams from 'services/hooks/use_query_params'
import useOnce from 'services/hooks/use_once'
import { isEmptyObject } from 'services/helpers/values'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import InputCheckbox from 'components/input_checkbox'

const inFuture = (value: string | Date): boolean => new DateHelper(value).isBetweenDates(new Date())
const extractNameFromSelectLabel = (value: string | undefined): string =>
  value?.match(/^(.*?) \([a-zA-Z0-9]+\)$/)?.[1] || ''

const INTERVAL_BETWEEN_POOLING = 10 * 1000
const MAX_POOLING_DURATION = 3 * 60 * 1000

const SearchBar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const status = useSelector(selectTrustedRoutesStatus)
  const subscriptionID = useSelector(selectSubscriptionID)
  const activeQueryParams = useSelector(selectActiveQueryParams)
  const history = useHistory()
  const location = useLocation()
  const currentQueryParams = useQueryParams()
  const user = useCurrentUser()
  const withRatesSetting = !!user.trustedRouteSettings?.features.withRates
  const [includeRates, setIncludeRates] = useState<boolean>(false)

  const departureFilter = useFilter({
    name: 'departure',
    type: 'select',
    required: true,
    defaultValue:
      currentQueryParams.departureLocode && currentQueryParams.departure
        ? {
            value: currentQueryParams.departureLocode,
            label: `${currentQueryParams.departure} (${currentQueryParams.departureLocode})`,
          }
        : activeQueryParams.departure,
  })
  const arrivalFilter = useFilter({
    name: 'arrival',
    type: 'select',
    required: true,
    defaultValue:
      currentQueryParams.arrivalLocode && currentQueryParams.arrival
        ? {
            value: currentQueryParams.arrivalLocode,
            label: `${currentQueryParams.arrival} (${currentQueryParams.arrivalLocode})`,
          }
        : activeQueryParams.arrival,
  })
  const fromDateFilter = useFilter({
    name: 'fromDate',
    type: 'date',
    required: true,
    defaultValue:
      currentQueryParams.fromDate && inFuture(currentQueryParams.fromDate)
        ? currentQueryParams.fromDate
        : activeQueryParams.fromDate,
    customValidation: (value) => !!value && inFuture(value),
  })

  const trustedRoutesCategory = useCategory({
    name: 'trustedRoute',
    filters: [departureFilter, arrivalFilter, fromDateFilter],
    toQueryParams: (_filters, filtersHash) => ({
      departure: filtersHash.departure?.value?.value,
      arrival: filtersHash.arrival?.value?.value,
      fromDate: filtersHash.fromDate?.value,
    }),
  })
  const categories = [trustedRoutesCategory]

  useEffect(() => {
    if (!subscriptionID) return
    if (status !== STATUS_PENDING) return

    dispatch(fetchTrustedRoutes(subscriptionID))
    const interval = setInterval(
      () => dispatch(fetchTrustedRoutes(subscriptionID)),
      INTERVAL_BETWEEN_POOLING
    )
    const timeout = setTimeout(() => dispatch(setStatusFulfilled()), MAX_POOLING_DURATION)

    // eslint-disable-next-line
    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionID, status])

  const { apply, queryParams, isValid } = useForm({
    categories,
    onApply: () => {
      dispatch(startNewSubscription())
      dispatch(
        updateActiveQueryParams({
          departure: departureFilter.value,
          arrival: arrivalFilter.value,
          fromDate: fromDateFilter.value,
          withRates: includeRates,
        })
      )
      history.replace({
        pathname: location.pathname,
        search: Object.entries({
          departureLocode: departureFilter.value?.value,
          departure: extractNameFromSelectLabel(departureFilter.value?.label),
          arrivalLocode: arrivalFilter.value?.value,
          arrival: extractNameFromSelectLabel(arrivalFilter.value?.label),
          fromDate: fromDateFilter.value,
        })
          .map(([k, v]) => `${k}=${v}`)
          .join('&'),
      })

      dispatch(
        subscribeTrustedRoutes({
          ...queryParams,
          withRates: includeRates,
          fromDate: queryParams.fromDate.match(/(\d{4}-\d{2}-\d{2})/)[1],
        })
      )
    },
  })

  useOnce(() => {
    if (
      activeQueryParams.departure &&
      activeQueryParams.arrival &&
      activeQueryParams.fromDate &&
      isEmptyObject(currentQueryParams)
    ) {
      history.replace({
        pathname: location.pathname,
        search: Object.entries({
          departureLocode: activeQueryParams.departure.value,
          departure: extractNameFromSelectLabel(activeQueryParams.departure.label),
          arrivalLocode: activeQueryParams.arrival.value,
          arrival: extractNameFromSelectLabel(activeQueryParams.arrival.label),
          fromDate: activeQueryParams.fromDate,
        })
          .map(([k, v]) => `${k}=${v}`)
          .join('&'),
      })
    }
  })

  return (
    <S.Searchbar>
      <S.Container>
        <S.FormFields>
          <S.FormField>
            <Select
              onChange={departureFilter.onChange}
              isClearable
              isSearchable
              async
              label={t('trustedRoutes.departure.label')}
              defaultValue={departureFilter.value}
              placeholder={t('trustedRoutes.departure.placeholder')}
              name={departureFilter.name}
              fetch={({ value }) => fetchPorts({ value })}
              fetchOnFocus={() => fetchPorts({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map(({ name, locode }: { name: string; locode: string }) => ({
                  value: locode,
                  label: `${name} (${locode})`,
                }))
              }
            />
          </S.FormField>
          <S.FormField>
            <Select
              onChange={arrivalFilter.onChange}
              isClearable
              isSearchable
              async
              label={t('trustedRoutes.arrival.label')}
              defaultValue={arrivalFilter.value}
              placeholder={t('trustedRoutes.arrival.placeholder')}
              name={arrivalFilter.name}
              fetch={({ value }) => fetchPorts({ value })}
              fetchOnFocus={() => fetchPorts({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map(({ name, locode }: { name: string; locode: string }) => ({
                  value: locode,
                  label: `${name} (${locode})`,
                }))
              }
            />
          </S.FormField>
          <S.FormField>
            <InputDatepicker
              withPortal
              label={t('trustedRoutes.fromDate.label')}
              placeholder={t('trustedRoutes.fromDate.placeholder')}
              name={fromDateFilter.name}
              onChange={fromDateFilter.onChange}
              startDate={fromDateFilter.value}
              onToggleOpen={(opened) => {
                if (!opened && !fromDateFilter.isPresent) {
                  fromDateFilter.reset()
                }
              }}
              filterDate={inFuture}
            />
          </S.FormField>
          {withRatesSetting && (
            <S.RateFormField>
              <InputCheckbox
                id='includeRates'
                name='includeRates'
                text={t('trustedRoutes.includeRates.label')}
                onChange={() => setIncludeRates(!includeRates)}
                checked={includeRates}
              />
            </S.RateFormField>
          )}
        </S.FormFields>
        <Button
          text={t('actions.search')}
          variant='highlight'
          onClick={apply}
          padded
          disabled={!isValid || status === STATUS_PENDING}
        />
      </S.Container>
    </S.Searchbar>
  )
}

export default SearchBar
