import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { TRANSPORT_TYPES } from 'constants/shipments'

import iconsMapping from 'services/helpers/icons_mapping'

import Icon from 'components/icon'
import {
  StyledShipmentTransit,
  StyledShipmentTransitIcon,
  StyledShipmentTransitText,
  StyledShipmentTransitDestination,
} from 'components/shipment_transit/style'

const ShipmentTransit = ({ transportType, destination }) => {
  const { t } = useTranslation()
  return (
    <StyledShipmentTransit>
      <StyledShipmentTransitIcon as={Icon} name={iconsMapping(transportType, 'transportation')} />
      <StyledShipmentTransitText>
        {t('dashboard.map.modal.inTransitTo')}{' '}
        <StyledShipmentTransitDestination>{destination}</StyledShipmentTransitDestination>
      </StyledShipmentTransitText>
    </StyledShipmentTransit>
  )
}

ShipmentTransit.propTypes = {
  /** Transport mode */
  transportType: PropTypes.oneOf(TRANSPORT_TYPES),
  /** future */
  destination: PropTypes.string,
}

ShipmentTransit.defaultProps = {
  transportType: undefined,
  destination: undefined,
}

export default ShipmentTransit
