import type { NotificationTypes } from 'components/notif_message'

/* sign in */
export const TEST_ID_SIGN_IN_BUTTON = 'test-id-sign-in-button'

export const TEST_ID_HEADER_PROFILE_BUTTON = 'test-id-header-profile-button'
export const TEST_ID_HEADER_NETWORK_BUTTON = 'test-id-header-network-button'

/* search bar */
export const TEST_ID_SEARCH_BUTTON = 'test-id-search-button'
export const TEST_ID_SEARCH_LIST_BUTTON = 'test-id-search-list-button'
export const TEST_ID_SEARCH_ITEM = 'test-id-search-item'
/**
 *
 * @param index the research item index
 * @returns the test id
 */

export const getTestIdForSearchItem = (index: number) => `${TEST_ID_SEARCH_ITEM}-${index}`

/* nav bar */
export const TEST_ID_NAV_BAR = 'test-id-nav-bar'

/* sidebar */
export const TEST_ID_SIDE_BAR_BUTTON = 'test-id-side-bar-button'
export const TEST_ID_SIDE_BAR_CLOSE_BUTTON = 'test-id-side-bar-close-button'
export const TEST_ID_FILTERS_TEMPLATE_INPUT = 'test-id-filters-template-input'
export const TEST_ID_BOOKING_TEMPLATE_INPUT = 'test-id-booking-template-input'
export const TEST_ID_BOOKING_TEMPLATE_SAVE = 'test-id-booking-template-save'
export const TEST_ID_BOOKING_TEMPLATE_ITEM = 'test-id-booking-template-item'
export const TEST_ID_SIDE_BAR_CLEAR_BUTTON = 'test-id-side-bar-clear-button'
export const TEST_ID_SIDE_BAR_APPLY_BUTTON = 'test-id-side-bar-apply-button'

export const TEST_ID_FILTERS_TAG_APPLIED = 'test-id-filters-tag-applied'
export const getTestIdForBookingFiltersTagApplied = (index: number) =>
  `${TEST_ID_FILTERS_TAG_APPLIED}-${index}`

export const TEST_ID_FILTERS_TEMPLATE_SAVED_ITEM = 'test-id-filters-template-saved-item'
export const getTestIdForBookingFiltersTemplateSavedItem = (index: number) =>
  `${TEST_ID_FILTERS_TEMPLATE_SAVED_ITEM}-${index}`

export const TEST_ID_FILTERS_TEMPLATE_SAVED_ITEM_TAG = 'test-id-filters-template-saved-item-tag'
export const getTestIdForBookingFiltersTemplateSavedItemTag = (
  indexTemplate: number,
  index: number
) => `${TEST_ID_FILTERS_TEMPLATE_SAVED_ITEM_TAG}-${indexTemplate}-${index}`

/* shipment */
export const TEST_ID_SHIPMENT_SIDE_BAR_FILTER = 'test-id-shipment-side-bar-filter'
export const TEST_ID_SHIPMENT_SIDE_BAR_REF_FILTER = 'test-id-shipment-side-bar-ref-filter'
export const TEST_ID_SHIPMENT_ITEM = 'test-id-shipment-item'
export const TEST_ID_SHIPMENT_EXPORT_FROM_DATE = 'test-id-shipment-export-from-date'
export const TEST_ID_SHIPMENT_EXPORT_TO_DATE = 'test-id-shipment-export-to-date'
export const TEST_ID_SHIPMENT_SWITCH_TO_V2_BUTTON = 'test-id-shipment-switch-to-v2-button'
export const TEST_ID_SHIPMENT_ACTION_MENU = 'test-id-shipment-action-menu'
export const TEST_ID_SHIPMENT_DELETE_SHIPMENT_BUTTON = 'test-id-shipment-delete-shipment-button'
export const TEST_ID_SHIPMENT_REPORT_SHIPMENT_BUTTON = 'test-id-shipment-report-shipment-button'
export const TEST_ID_SHIPMENT_FLAG_REASON_SELECT = 'test-id-shipment-flag-reason-select'
export const TEST_ID_SHIPMENT_EDIT_PATH_BUTTON = 'test-id-shipment-edit-path-button'
export const TEST_ID_SHIPMENT_EDIT_PATH_INPUT = 'test-id-shipment-edit-path-input'
export const TEST_ID_SHIPMENT_EDIT_PATH_ERROR = 'test-id-shipment-edit-path-error'
export const TEST_ID_SHIPMENT_EDIT_PATH_SAVE_BUTTON = 'test-id-shipment-edit-path-save-button'
export const TEST_ID_SHIPMENT_REFERENCES_EDIT_BUTTON = 'test-id-shipment-references-edit-button'
export const TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_WEIGHT =
  'test-id-shipment-references-product-information-weight'
export const TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_VOLUME =
  'test-id-shipment-references-product-information-volume'
export const TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_DESCRIPTION =
  'test-id-shipment-references-product-information-description'
export const TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_PACKAGE_NUMBER =
  'test-id-shipment-references-product-information-package-number'
export const TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_SAVE =
  'test-id-shipment-references-product-information-save'
export const TEST_ID_SHIPMENT_REFERENCES_BOOKING_NUMBER =
  'test-id-shipment-references-booking-number'
export const TEST_ID_SHIPMENT_REFERENCES_REF_FORWARDER = 'test-id-shipment-references-ref-forwarder'
export const TEST_ID_SHIPMENT_REFERENCES_CLIENT = 'test-id-shipment-references-client'
export const TEST_ID_SHIPMENT_REFERENCES_SHIPMENT = 'test-id-shipment-references-shipment'
export const TEST_ID_SHIPMENT_REFERENCES_SAVE = 'test-id-shipment-references-save'
export const TEST_ID_AUDIT_TRAIL = 'test-id-audit-trail'
export const TEST_ID_SHIPMENT_DOCUMENT_LIST = 'test-id-shipment-document-list'

/** shipment show */
export const TEST_ID_SHIPMENT_SHOW_SEGMENT = 'test-id-shipment-show-segment'
export const TEST_ID_SHIPMENT_SHOW_REFERNECE = 'test-id-shipment-show-reference'
export const TEST_ID_SHIPMENT_SHOW_GO_TO_BOOKING = 'test-id-shipment-show-go-to-booking'

export const TEST_ID_SHIPMENT_SHOW_IOT_POSITON_MARKER = 'test-id-shipment-show-iot-position-marker'
export const TEST_ID_SHIPMENT_MAP_FULLSCREEN_TOGGLE = 'test-id-shipment-map-fullscreen-toggle'

/**
 *
 * @param index the shipment item index
 * @returns the test id
 */
export const getTestIdForShipmentItem = (index: number) => `${TEST_ID_SHIPMENT_ITEM}-${index}`

export const getTestIdForShipmentSegment = (index: number) =>
  `${TEST_ID_SHIPMENT_SHOW_SEGMENT}-${index}`

/* network */
export const TEST_ID_NETWORK_COMPANY_LIST_ITEM = 'test-id-network-company-list-item'
export const TEST_ID_NETWORK_COMPANY_FORM_NAME_INPUT = 'test-id-network-company-form-name-input'
export const TEST_ID_NETWORK_COMPANY_FORM_CITY_INPUT = 'test-id-network-company-form-city-input'
export const TEST_ID_NETWORK_COMPANY_FORM_ADDRESS_INPUT =
  'test-id-network-company-form-address-input'
export const TEST_ID_NETWORK_COMPANY_FORM_COUNTRY_SELECT =
  'test-id-network-company-form-country-select'
export const TEST_ID_NETWORK_COMPANY_FORM_LATITUDE_INPUT =
  'test-id-network-company-form-latitude-input'
export const TEST_ID_NETWORK_COMPANY_FORM_LONGITUDE_INPUT =
  'test-id-network-company-form-longitude-input'
export const TEST_ID_NETWORK_COMPANY_FORM_CAPACITIES_SELECT =
  'test-id-network-company-form-capacities-select'

export const getTestIdForNetworkCompanyListItemInfo = (
  index: number,
  infoType: 'button' | 'name' | 'details'
) => `${TEST_ID_NETWORK_COMPANY_LIST_ITEM}-${index}-${infoType}`

/* booking */
export const TEST_ID_BOOKING_TOTAL = 'test-id-booking-amount'
export const TEST_ID_BOOKING_NEW_BOOKING_BUTTON = 'test-id-booking-new-booking-button'
export const TEST_ID_BOOKING_SEND_REQUEST_BUTTON = 'test-id-booking-send-request-button'
export const TEST_ID_BOOKING_EDIT_BUTTON = 'test-id-booking-edit-button'
export const TEST_ID_BOOKING_VALIDATE_PROPOSAL_BUTTON = 'test-id-booking-validate-proposal-button'
export const TEST_ID_BOOKING_CONFIRM_BUTTON = 'test-id-booking-confirm-button'
export const TEST_ID_BOOKING_ACCEPT_BUTTON = 'test-id-booking-accept-button'
export const TEST_ID_BOOKING_FORM_CUSTOM_REFERENCE = 'test-id-booking-form-custom-reference'
export const TEST_ID_BOOKING_FORM_SHIPPER = 'test-id-booking-form-shiper'
export const TEST_ID_BOOKING_FORM_CUSTOM_FIELD = 'test-id-booking-form-custom-field'
export const TEST_ID_BOOKING_FORM_SHIPPER_FIELD = 'test-id-booking-form-shipper-field'
export const TEST_ID_BOOKING_CANCEL_BUTTON = 'test-id-booking-cancel-button'
export const TEST_ID_BOOKING_DECLINE_BUTTON = 'test-id-booking-decline-button'
export const TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_SEA = 'test-id-booking-form-transport-type-sea'
export const TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_AIR = 'test-id-booking-form-transport-type-air'
export const TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_ROAD = 'test-id-booking-form-transport-type-road'
export const TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_RAIL = 'test-id-booking-form-transport-type-rail'
export const TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_SWITCH =
  'test-id-booking-form-routing-destination-switch'
export const TEST_ID_BOOKING_FORM_ROUTING_DEPARTURE_SWITCH =
  'test-id-booking-form-routing-departure-switch'
export const TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_LOCATION_SELECT =
  'test-id-booking-form-routing-departure-location-select'
export const TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTA = 'test-id-booking-form-routing-origin-pta'
export const TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTD = 'test-id-booking-form-routing-origin-ptd'
export const TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTA =
  'test-id-booking-form-routing-destination-pta'
export const TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTD =
  'test-id-booking-form-routing-destination-ptd'
export const TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_LOCATION_SELECT =
  'test-id-booking-form-routing-destination-location'
export const TEST_ID_BOOKING_FORM_TRANSIT_STEP_TYPE_SELECT =
  'test-id-booking-form-transit-step-type-select'
export const TEST_ID_BOOKING_FORM_ROUTING_POL = 'test-id-booking-form-routing-pol'
export const TEST_ID_BOOKING_FORM_ROUTING_POD = 'test-id-booking-form-routing-pod'
export const TEST_ID_BOOKING_FORM_ROUTING_ADD_TRANSSHIPMENT =
  'test-id-booking-form-add-transshipment'
export const TEST_ID_BOOKING_FORM_ROUTING_ADD_POL = 'test-id-booking-form-add-pol'
export const TEST_ID_BOOKING_FORM_ROUTING_ADD_POD = 'test-id-booking-form-add-pod'
export const TEST_ID_BOOKING_FORM_ROUTING_TRANSSHIPMENT = 'test-id-booking-form-transshipment'
export const TEST_ID_BOOKING_FORM_ROUTING_TRANSSHIPMENT_PTA =
  'test-id-booking-form-transshipment-pta'
export const TEST_ID_BOOKING_FORM_ROUTING_TRANSSHIPMENT_PTD =
  'test-id-booking-form-transshipment-ptd'
export const TEST_ID_BOOKING_FORM_ROUTING_POL_PTD = 'test-id-booking-form-pol-ptd'
export const TEST_ID_BOOKING_FORM_ROUTING_POD_PTD = 'test-id-booking-form-pod-ptd'
export const TEST_ID_BOOKING_FORM_ROUTING_POD_PTA = 'test-id-booking-form-pod-pta'
export const TEST_ID_BOOKING_FORM_ROUTING_POL_PTA = 'test-id-booking-form-pol-pta'
export const TEST_ID_BOOKING_FORM_VGM_CUT_OFF = 'test-id-booking-form-vgm-cut-off'
export const TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE = 'test-id-booking-form-minimal-temperature'
export const TEST_ID_BOOKING_FORM_MAXIMAL_TEMPERATURE = 'test-id-booking-form-maximal_temperature'

export const TEST_ID_BOOKING_FORM_VESSEL_CUT_OFF = 'test-id-booking-form-vessel-cut-off'
export const TEST_ID_BOOKING_TITLE = 'test-id-booking-title'
export const TEST_ID_BOOKING_STATUS_TAG = 'test-id-booking-status'
const TEST_ID_BOOKING_ITEM = 'test-id-booking-item'
export const TEST_ID_BOOKING_FORM_SHIPPING_MODE_FCL = 'test-id-booking-form-shipping-mode-fcl'
export const TEST_ID_BOOKING_FORM_SHIPPING_MODE_LCL = 'test-id-booking-form-shipping-mode-lcl'
export const TEST_ID_BOOKING_FORM_MERCHANDISE_TYPE_LCL = 'test-id-booking-form-merchandise-type-lcl'
export const TEST_ID_BOOKING_FORM_MERCHANDISE_CONTAINER =
  'test-id-booking-form-merchandise-container'
export const TEST_ID_BOOKING_FORM_MERCHANDISE_PACKAGE = 'test-id-booking-form-merchandise-package'
export const TEST_ID_BOOKING_FORM_MERCHANDISE_PRODUCT = 'test-id-booking-form-merchandise-product'
export const TEST_ID_BOOKING_FORM_SHIPMENT = 'test-id-booking-form-merchandise-shipment'
export const TEST_ID_BOOKING_FORM_RATE_PROPOSAL_AMOUNT = 'test-id-booking-form-rate-proposal-amount'
export const TEST_ID_BOOKING_FORM_RATE_BOOKING_NUMBER = 'test-id-booking-form-booking-number'

export const ARIA_LABEL_BOOKING_FORM_RATE_PROPOSAL_CURRENCY =
  'aria-label-booking-form-rate-proposal-currency'
export const TEST_ID_BOOKING_FORM_COMMERCIAL_VALUE_CURRENCY =
  'test-id-booking-form-commercial-value-currency'
export const TEST_ID_BOOKING_FORM_COMMERCIAL_VALUE_AMOUNT =
  'test-id-booking-form-commercial-value-amount'
export const TEST_ID_BOOKING_FORM_ADD_DOCUMENTS = 'test-id-booking-form-add-documents'
export const TEST_ID_BOOKING_FORM_ASK_RATE_CONFIRMATION =
  'test-id-booking-form-ask-rate-confirmation'
export const TEST_ID_BOOKING_FORM_SELECT_DOCUMENT_TYPE = 'test-id-booking-form-select-document-type'
export const TEST_ID_BOOKING_SHOW_MASTER_BL = 'test-id-booking-show-master-bl'
export const TEST_ID_BOOKING_SHOW_MERCHANDISE_CONTENT = 'test-id-booking-show-merchandise-content'

export const TEST_ID_BOOKING_FILTERS_TRANSPORT_TYPE_SEA =
  'test-id-booking-filters-transport-type-sea'
export const TEST_ID_BOOKING_FILTERS_TRANSPORT_TYPE_AIR =
  'test-id-booking-filters-transport-type-air'
export const TEST_ID_BOOKING_FILTERS_ACTION_REQUIRED = 'test-id-booking-filters-action-required'
export const TEST_ID_BOOKING_FILTERS_STATUS_IN_REVIEW = 'test-id-booking-filters-status-in-review'
export const TEST_ID_BOOKING_FILTERS_STATUS_ACCEPTED = 'test-id-booking-filters-status-accepted'
export const TEST_ID_BOOKING_FILTERS_STATUS_CONFIRMED = 'test-id-booking-filters-status-confirmed'
export const TEST_ID_BOOKING_FILTERS_STATUS_CANCELLED = 'test-id-booking-filters-status-cancelled'
export const TEST_ID_BOOKING_FILTERS_STATUS_DECLINED = 'test-id-booking-filters-status-declined'
export const TEST_ID_BOOKING_FILTERS_STATUS_COMPLETED = 'test-id-booking-filters-status-completed'
export const TEST_ID_BOOKING_FILTERS_REFERENCE = 'test-id-booking-filters-reference'
export const TEST_ID_BOOKING_FILTERS_ROUTING_ORIGIN = 'test-id-booking-filters-routing-origin'
export const TEST_ID_BOOKING_FILTERS_EXPECTED_ORIGIN_TIME =
  'test-id-booking-filters-expected-origin-time'

export const TEST_ID_BOOKING_FILTERS_APPLY = 'test-id-booking-filters-apply'

export const TEST_ID_BOOKING_SHOW_DESTINATION_PROPOSAL_DATE =
  'test-id-booking-show-destination-proposal-date'
export const TEST_ID_BOOKING_SHOW_ON_CARRIAGE_DATE = 'test-id-booking-show-on-carriage-date'
export const TEST_ID_BOOKING_SHOW_ORIGIN_PROPOSAL_DATE = 'test-id-booking-show-origin-proposal-date'
export const TEST_ID_BOOKING_SHOW_PRE_CARRIAGE_DATE = 'test-id-booking-show-pre-carriage-date'
export const TEST_ID_BOOKING_SHOW_VESSEL_CUT_OFF_DATE = 'test-id-booking-show-vessel-cut-off'
export const TEST_ID_BOOKING_SHOW_VGM_CUT_OFF_DATE = 'test-id-booking-show-vgm-cut-off'
export const TEST_ID_BOOKING_SHOW_POL_PTD = 'test-id-booking-show-pol-ptd'
export const TEST_ID_BOOKING_SHOW_POL_PTA = 'test-id-booking-show-pol-pta'
export const TEST_ID_BOOKING_SHOW_POD_PTD = 'test-id-booking-show-pod-ptd'
export const TEST_ID_BOOKING_SHOW_POD_PTA = 'test-id-booking-show-pod-pta'
export const TEST_ID_BOOKING_SHOW_ORIGIN_STEP = 'test-id-booking-show-origin-step'
export const TEST_ID_BOOKING_SHOW_POL_STEP = 'test-id-booking-show-pol-step'
export const TEST_ID_BOOKING_SHOW_TRANSSHIPMENT_STEP = 'test-id-booking-show-transshipment-step'
export const TEST_ID_BOOKING_SHOW_POD_STEP = 'test-id-booking-show-pod-step'
export const TEST_ID_BOOKING_SHOW_DESTINATION_STEP = 'test-id-booking-show-destination-step'
export const TEST_ID_BOOKING_DOCUMENT = 'test-id-booking-show-document'
export const TEST_ID_FORGOTTEN_PASSWORD = 'test-id-forgotten-password'
export const TEST_ID_BOOKING_FORM_SHIPMENTS_BLOCK = 'test-id-booking-form-shipments-block'
export const TEST_ID_BOOKING_FORM_ADD_SHIPMENT = 'test-id-booking-form-add-shipment'
export const TEST_ID_BOOKING_SHOW_TRANSPORT_DETAILS_SECTION =
  'test-id-booking-show-transport-details-section'
export const TEST_ID_BOOKING_SHOW_TRANSSHIPMENT_PTA = 'test-id-booking-show-transshipment-pta'
export const TEST_ID_BOOKING_SHOW_TRANSSHIPMENT_PTD = 'test-id-booking-show-transshipment-ptd'
export const TEST_ID_BOOKING_SHOW_DOCUMENTS_TAB = 'test-id-booking-show-documents-tab'
export const TEST_ID_BOOKING_LIST_ITEM_CLIENT_REFERENCE =
  'test-id-booking-list-item-client-reference'
export const TEST_ID_BOOKING_LIST_ITEM_CLIENT_BOOKING_NUMBER =
  'test-id-booking-list-item-client-booking-number'
export const TEST_ID_BOOKING_LIST_ITEM_TRANSPORTATION_ICON =
  'test-id-booking-list-item-transportation-icon'
export const TEST_ID_BOOKING_LIST_ITEM_STATUS_ICON = 'test-id-booling-list-item-status-icon'
export const TEST_ID_BOOKING_LIST_ITEM_STATUS_TEXT = 'test-id-booling-list-item-status-text'
export const TEST_ID_BOOKING_LIST_ITEM_ORIGIN_ADDRESS = 'test-id-booking-list-item-origin-address'
export const TEST_ID_BOOKING_LIST_ITEM_DESTINATION_ADDRESS =
  'test-id-booking-list-item-destination-address'
export const TEST_ID_BOOKING_LIST_ITEM_DATE = 'test-id-booking-list-item-date'
export const TEST_ID_BOOKING_LIST_ITEM_LAST_UPDATED = 'test-id-booking-list-item-last-updated'
export const TEST_ID_BOOKING_BLOCK_DOCUMENT_ITEM_NAME = 'test-id-booking-block-document-item-name'
export const TEST_ID_SELECT_TRANSPORT_DOCUMENT_TYPE = 'test_id_select_transport_document_type'
export const TEST_ID_TRANSPORT_DOCUMENT_ITEM_DELETE = 'test_id_transport_document_item_delete'
export const TEST_ID_TRANSPORT_DOCUMENT_ITEM_DOWNLOAD = 'test_id_transport_document_item_download'
export const TEST_ID_BOOKING_SIDENAV_CUSTOM_FILTERS_TAB =
  'test-id-booking-sidenav-custom-filters-tab'
export const TEST_ID_BOOKING_SIDENAV_SAVED_FILTERS_TAB =
  'test-id-booking-sidenav-custom-filters-tab'
export const TEST_ID_BOOKING_FILTERS_NOTIFICATION_SECTION =
  'test-id-booking-filters-notification-section'
export const TEST_ID_BOOKING_FILTERS_STATUS_SECTION = 'test-id-booking-filters-status-section'
export const TEST_ID_BOOKING_FILTERS_REFERENCE_SECTION = 'test-id-booking-filters-reference-section'
export const TEST_ID_BOOKING_FILTERS_ROUTING_SECTION = 'test-id-booking-filters-routing-section'
export const TEST_ID_BOOKING_FILTERS_TRANSPORT_MODE_SECTION =
  'test-id-booking-filters-transport-mode-section'
export const TEST_ID_BOOKING_FILTERS_EXPECTED_TIME_SECTION =
  'test-id-booking-filters-expected-time-section'

export const TEST_ID_USER_INDEX_LEGEND_FULLNAME = 'test-id-user-fullname-legend'
export const TEST_ID_USER_INDEX_LEGEND_EMAIL = 'test-id-user-email-legend'
export const TEST_ID_USER_INDEX_LEGEND_ORGANIZATION = 'test-id-user-organization-legend'
export const TEST_ID_USER_INDEX_LEGEND_COMPANY = 'test-id-user-company-legend'
export const TEST_ID_USER_INDEX_LEGEND_ROLES = 'test-id-user-roles-legend'
export const TEST_ID_USER_INDEX_LEGEND_ACTIVATED = 'test-id-user-activated-legend'
export const TEST_ID_USER_INDEX_LEGEND_ACTIONS = 'test-id-user-actions-legend'

export const TEST_ID_USERS_NEW_USER_BUTTON = 'test-id-users-new-user-button'
export const TEST_ID_USERS_CREATE_USER_BUTTON = 'test-id-users-create-user-button'
export const TEST_ID_USERS_SAVE_USER_BUTTON = 'test-id-users-save-user-button'
export const TEST_ID_USERS_FORM_FIRST_NAME = 'test-id-users-form-first-name'
export const TEST_ID_USERS_FORM_LAST_NAME = 'test-id-users-form-last-name'
export const TEST_ID_USERS_FORM_EMAIL = 'test-id-users-form-email'
export const TEST_ID_USERS_FORM_ORGANIZATION = 'test-id-users-form-organization'
export const TEST_ID_USERS_FORM_COMPANY = 'test-id-users-form-company'
export const TEST_ID_USERS_FORM_MAIN_ROLES = 'test-id-users-form-main-roles'
export const TEST_ID_USERS_FORM_OPTIONAL_ROLES = 'test-id-users-form-optional-roles'
export const TEST_ID_USERS_LIST = 'test-id-users-list'
export const TEST_ID_USERS_SETTINGS_INFORMATION_TAB = 'test-id-users-settings-information-tab'

export const TEST_ID_ROOT_LOADER_WAKEO = 'test-id-root-loader-wakeo'

export const TEST_ID_NETWORK_LIST = 'test-id-network-list'
export const TEST_ID_NETWORK_SEARCHBAR = 'test-id-network-searchbar'
export const TEST_ID_NETWORK_SEARCHBAR_CLEAR_BUTTON = 'test-id-network-searchbar-clear-button'

export const getTestIdForUserFullname = (index: number) => `test-id-user-fullname-${index}`
export const getTestIdForUserEmail = (index: number) => `test-id-user-email-${index}`
export const getTestIdForUserOrganization = (index: number) => `test-id-user-organization-${index}`
export const getTestIdForUserCompany = (index: number) => `test-id-user-company-${index}`
export const getTestIdForUserRoles = (index: number) => `test-id-user-roles-${index}`
export const getTestIdForUserActivated = (index: number) => `test-id-user-activated-${index}`
export const getTestIdForUserSettingsButton = (index: number) => `user-settings-button-${index}`

export const getTestIdForBookingTemplateItem = (index: number) =>
  `${TEST_ID_BOOKING_TEMPLATE_ITEM}-${index}`
export const getTestIdForBookingDocument = (index: number) => `${TEST_ID_BOOKING_DOCUMENT}-${index}`

export const getTestIdForCustomReferenceName = (index: number) =>
  `${TEST_ID_BOOKING_FORM_CUSTOM_REFERENCE}-${index}-name`

export const getTestIdForCustomReferenceReference = (index: number) =>
  `${TEST_ID_BOOKING_FORM_CUSTOM_REFERENCE}-${index}-reference`

export const getTestIdForCustomFieldName = (index: number) =>
  `${TEST_ID_BOOKING_FORM_CUSTOM_FIELD}-${index}-name`

export const getTestIdForCustomFieldReference = (index: number) =>
  `${TEST_ID_BOOKING_FORM_CUSTOM_FIELD}-${index}-reference`

/**
 *
 * @param index the booking item index
 * @returns the test id
 */
export const getTestIdForBookingItem = (index: number) => `${TEST_ID_BOOKING_ITEM}-${index}`

/* form */
const TEST_ID_SELECT_OPTION = 'test-id-select-option'

/**
 *
 * @param index the option index in the options list
 * @returns the test id
 */
export const getTestIdForOption = (index: number) => `${TEST_ID_SELECT_OPTION}-${index}`

export const getTestIdForTransshipment = (index: number) =>
  `${TEST_ID_BOOKING_FORM_ROUTING_TRANSSHIPMENT}-${index}`
export const getTestIdForTransshipmentPta = (index: number) =>
  `${TEST_ID_BOOKING_FORM_ROUTING_TRANSSHIPMENT_PTA}-${index}`
export const getTestIdForTransshipmentPtd = (index: number) =>
  `${TEST_ID_BOOKING_FORM_ROUTING_TRANSSHIPMENT_PTD}-${index}`
export const getTestIdShowTransshipmentPta = (index: number) =>
  `${TEST_ID_BOOKING_SHOW_TRANSSHIPMENT_PTA}-${index}`
export const getTestIdShowTransshipmentPtd = (index: number) =>
  `${TEST_ID_BOOKING_SHOW_TRANSSHIPMENT_PTD}-${index}`

export const getTestIdForMerchandiseContainer = (index: number) =>
  `${TEST_ID_BOOKING_FORM_MERCHANDISE_CONTAINER}-${index}`

export const getTestIdForMerchandisePackage = (index: number) =>
  `${TEST_ID_BOOKING_FORM_MERCHANDISE_PACKAGE}-${index}`

export const getTestIdForMerchandiseProduct = (index: number) =>
  `${TEST_ID_BOOKING_FORM_MERCHANDISE_PRODUCT}-${index}`

export const getTestIdForMerchandiseContainerProduct = (
  containerIndex: number,
  productIndex: number
) => `${TEST_ID_BOOKING_FORM_MERCHANDISE_PRODUCT}-${containerIndex}-${productIndex}`

export const getTestIdForMerchandiseProductMaximalTemperature = (
  productIndex: number,
  containerIndex?: number
) =>
  `${TEST_ID_BOOKING_FORM_MAXIMAL_TEMPERATURE}-${`${
    containerIndex ? `${containerIndex}-` : ''
  }${productIndex}`}`

export const getTestIdForMerchandiseProductCurrencyCode = (
  productIndex: number,
  containerIndex?: number
) =>
  `${TEST_ID_BOOKING_FORM_COMMERCIAL_VALUE_CURRENCY}-${`${
    containerIndex ? `${containerIndex}-` : ''
  }${productIndex}`}`

export const BOOKING_SHOW_MERCHANDISE_CONTENT_TYPES = {
  summary: 'summary',
  controlledTemperaturesIcon: 'controlled-temperatures-icon',
  hazardousGoodsIcon: 'hazardous-goods-icon',
}

type BookingShowMerchandiseContent = keyof typeof BOOKING_SHOW_MERCHANDISE_CONTENT_TYPES

export const getTestIdBookingShowMerchandiseContent = (
  index: number,
  item: BookingShowMerchandiseContent
) =>
  `${TEST_ID_BOOKING_SHOW_MERCHANDISE_CONTENT}-${BOOKING_SHOW_MERCHANDISE_CONTENT_TYPES[item]}-${index}`

export const getTestIdForShipment = (index: number) => `${TEST_ID_BOOKING_FORM_SHIPMENT}-${index}`

export const getTestIdForSelectDocumentType = (index: number) =>
  `${TEST_ID_BOOKING_FORM_SELECT_DOCUMENT_TYPE}-${index}`

/* modal */
export const TEST_ID_MODAL_ACTIONS = 'test-id-modal-actions'
export const TEST_ID_MODAL_TITLE = 'test-id-modal-title'

/* notification */
const TEST_ID_NOTIFICATION = 'test-id-notification'

/**
 *
 * @param type the notification type (see notification component)
 * @returns the test id
 */
export const getTestIdForNotification = (type: NotificationTypes) =>
  `${TEST_ID_NOTIFICATION}-${type}`

/**
 * Dashboard - Widget
 */
export const TEST_ID_ADD_WIDGET_BUTTON = 'test-id-add-widget-button'
export const TEST_ID_SAVE_CREATE_WIDGET_BUTTON = 'test-id-create-widget-button'
export const TEST_ID_WIDGET_LIST_QUICK_ACCESS = 'test-id-widget-lst-quick-access'
export const TEST_ID_EDIT_WIDGET_BUTTON = 'test-id-edit-widget-button'
export const TEST_ID_DELETE_WIDGET_BUTTON = 'test-id-delete-widget-button'
export const TEST_ID_FAVORITE_STAR_ICON = 'test-id-favorite-star-icon'

/**
 * Trusted Routes
 */
export const TEST_ID_TRUSTED_ROUTE_ITEM = 'test-id-trusted-route-item'
export const getTestIdForTrustedRouteItem = (index: number) =>
  `${TEST_ID_TRUSTED_ROUTE_ITEM}-${index}`
export const TEST_ID_TRUSTED_ROUTE_TAB_TRANSPORT_DETAILS =
  'test-id-trusted-route-tab-transport-details'
export const getTestIdForTrustedRouteTabTransportDetails = (index: number) =>
  `${TEST_ID_TRUSTED_ROUTE_TAB_TRANSPORT_DETAILS}-${index}`
export const TEST_ID_TRUSTED_ROUTE_TAB_RATES = 'test-id-trusted-route-tab-rates'
export const getTestIdForTrustedRouteTabRates = (index: number) =>
  `${TEST_ID_TRUSTED_ROUTE_TAB_RATES}-${index}`
