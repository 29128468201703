/* eslint-disable import/prefer-default-export */

import { v4 as uuidv4 } from 'uuid'

export interface Error {
  name?: string
  message?: string
  code?: number
  stack?: string
}
export class ErrorModel {
  error: Error

  constructor({ name, message, code, stack }: Error) {
    this.error = { name, message, code, stack }
  }

  get name() {
    return this.error.name
  }

  get message() {
    return this.error.message
  }

  get code() {
    return this.error.code
  }

  get stack() {
    return this.error.stack
  }

  get isClient() {
    return this.error.code !== 500
  }

  static isError(error: unknown) {
    return (
      Object.prototype.hasOwnProperty.call(error, 'name') &&
      Object.prototype.hasOwnProperty.call(error, 'message') &&
      Object.prototype.hasOwnProperty.call(error, 'code') &&
      Object.prototype.hasOwnProperty.call(error, 'stack')
    )
  }

  toRecord = () => ({
    id: uuidv4(),
    ...this.error,
  })

  toPayload = () => this.error
}
