/* eslint import/no-cycle: 0 */
import { isLocal } from 'services/helpers/environment'
import { APP_TYPE_SHARED } from 'constants/app_type'
import { ErrorModel } from 'views/errors/models'
import { signOut } from 'views/iam/slices/iamSlice'

const APIV1Error = (axiosError) =>
  new ErrorModel({
    name: axiosError.response.statusText,
    message: axiosError.response.data.error,
    code: axiosError.response.data.code,
    stack: axiosError.stack,
  })

const APIUnhandldedError = (axiosError) =>
  new ErrorModel({
    name: axiosError.response.statusText,
    message: axiosError.response.data,
    code: axiosError.response.status,
    stack: axiosError.stack,
  })

const UnhandledError = (axiosError) =>
  new ErrorModel({
    name: axiosError.message,
    message: axiosError.message,
    code: 500,
    stack: axiosError.stack,
  })

const APIError = (axiosError) => {
  if (ErrorModel.isError(axiosError)) return new ErrorModel(axiosError)

  if (!axiosError.response) {
    return UnhandledError(axiosError)
  }
  if (axiosError.response.data.error) {
    return APIV1Error(axiosError)
  }
  return APIUnhandldedError(axiosError)
}

const handleError = ({ axiosError, dispatch, type }) => {
  const error = APIError(axiosError)
  const { code } = error

  switch (code) {
    case 498:
      if (type !== APP_TYPE_SHARED) dispatch(signOut())
      break
    default:
      break
  }
  return error.toPayload()
}

const onError =
  ({ dispatch, rejectWithValue, getState }) =>
  (error) => {
    if (isLocal) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return rejectWithValue(
      handleError({ axiosError: error, dispatch, type: getState().application.type })
    )
  }

export const onExternalError = handleError

export default onError
