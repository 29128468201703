import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED } from 'constants/api'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useUrlParams from 'services/api/hooks/use_url_params'
import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'

import type { RootState } from 'services/store/store'
import type { Status } from 'constants/api'
import type { Vehicle } from 'views/dashboard/types'
import type { ShipmentResponse } from 'views/shipments/slice'

// ongoing means shipments active / with path / not flagged
export const fetchOngoingShipments = createAsyncThunk<Vehicle[]>(
  'dashboard/fetchOngoingShipments',
  async (_args, thunkAPI) => {
    const { teams: organizationCodes } = useThunkTeamsParam(thunkAPI)
    // TODO: old team naming
    const url = useUrlParams('/orders_map', { organizationCodes })

    return InternalClient.get(url)
      .then((r) => r.data)
      .then((d) => d.vehicles || [])
      .catch(onError(thunkAPI))
  }
)

export const fetchModalShipmentsByVehicleId = createAsyncThunk(
  'shipments/fetchModalShipmentsByVehicleId',
  async ({ vehicleId }: { vehicleId: string }, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI)
    const q = { dashboard_map_display_eq: true, dashboard_vehicle_id_eq: vehicleId }
    const url = useUrlParams('/shipments', {
      q,
      per: 500,
      ...teams,
    })

    return InternalClient.get(url)
      .then((r) => r.data)
      .then(({ shipments }) =>
        shipments.map(({ token, ...shipment }: ShipmentResponse) => ({ id: token, ...shipment }))
      )
      .catch(onError(thunkAPI))
  }
)

const dashboardAdapter = createEntityAdapter<Vehicle>()

const initialState = dashboardAdapter.getInitialState<{ status: Status; vehicles: Vehicle[] }>({
  status: STATUS_PENDING,
  vehicles: [],
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOngoingShipments.fulfilled, (state, action) => {
      dashboardAdapter.setAll(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchOngoingShipments.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchOngoingShipments.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(fetchModalShipmentsByVehicleId.fulfilled, (state, action) => {
      dashboardAdapter.updateOne(state, {
        id: action.meta.arg.vehicleId,
        changes: { shipments: action.payload },
      })
    })
  },
})
export const { selectAll: selectOngoingShipments, selectById: selectDashboardByVehicleById } =
  dashboardAdapter.getSelectors((state: RootState) => state.dashboard)

export const selectDashboardMapShipmentsByVehicleId = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectDashboardByVehicleById(state, id),
    (vehicle) => vehicle?.shipments || []
  )

export const selectOngoingShipmentsStatus = (state: RootState) => state.dashboard.status
export default dashboardSlice.reducer
