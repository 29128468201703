import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Address, ManuallyResolvedStatus, RejectedStatus } from 'views/atlas/types/address'
import Table from 'components/table'
import Button from 'components/button'
import S from 'views/atlas/addresses/components/item/style'
import Select from 'components/select'
import { fetchHubsSummary } from 'views/atlas/slices/hub'
import AddressFormData, {
  AddressFormDataBeforeValidation,
  resolveAddressSchema,
} from 'views/atlas/addresses/components/item/type'
import IconTooltip from 'components/icon_tooltip'
import { addNotification } from 'views/notifications/slice'
import { resolveAddress } from 'views/atlas/slices/address'
import useAppDispatch from 'services/hooks/use_app_dispatch'

interface ItemProps {
  address: Address
  variant: 'dark' | 'light'
}

const Item: React.FC<ItemProps> = ({ address, variant = 'light' }) => {
  const dispatch = useAppDispatch()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty, errors },
    setValue,
    watch,
    clearErrors,
  } = useForm<AddressFormDataBeforeValidation, undefined, AddressFormData>({
    resolver: yupResolver(resolveAddressSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      hub: address.hub
        ? { label: `${address.hub.name} (${address.hub.type})`, value: address.hub.token }
        : null,
      status: { label: address.status, value: address.status },
    },
  })

  const submit = (data: AddressFormData) => {
    const payload = {
      hubId: data.hub?.value || null,
      status: data.status.value,
      token: address.token,
    }
    dispatch(resolveAddress(payload))
      .unwrap()
      .then(() => setIsEditing(false))
      .catch((r: any) =>
        dispatch(
          addNotification({
            type: 'alert',
            title: 'Address Resolution',
            text: r.message?.detail || 'an error occurred during the resolution of the address',
          })
        )
      )
  }

  return (
    <Table.Row odd={variant === 'dark'} alignedTop>
      <Table.Cell>{address.rawValue}</Table.Cell>
      <Table.Cell>{address.dataProvider}</Table.Cell>
      <Table.Cell>{address.carrier}</Table.Cell>
      <Table.Cell>
        {isEditing ? (
          <Controller
            name='hub'
            control={control}
            render={({ field }) => (
              <Select
                async
                isSearchable
                name={field.name}
                value={field.value}
                onChange={({ value }) => {
                  if (value)
                    setValue(
                      'status',
                      {
                        label: ManuallyResolvedStatus,
                        value: ManuallyResolvedStatus,
                      },
                      { shouldValidate: true }
                    )
                  field.onChange(value)
                }}
                fetch={({ value }) =>
                  fetchHubsSummary({ value, hubTypes: address.constrainedHubTypes })
                }
                fetchOnFocus={() =>
                  fetchHubsSummary({ value: null, hubTypes: address.constrainedHubTypes })
                }
                fetchedOptionsFormat={(options) =>
                  options.map(({ name, token }: { name: string; token: string }) => ({
                    value: token,
                    label: name,
                  }))
                }
              />
            )}
          />
        ) : (
          watch('hub')?.label
        )}
      </Table.Cell>
      <Table.Cell>
        {isEditing ? (
          <Controller
            name='status'
            control={control}
            render={({ field }) => (
              <Select
                name={field.name}
                value={field.value}
                onChange={({ value }) => {
                  if (value.value === RejectedStatus) {
                    setValue('hub', null)
                    clearErrors('hub')
                  }
                  field.onChange(value)
                }}
                options={[RejectedStatus, ManuallyResolvedStatus].map((status) => ({
                  label: status,
                  value: status,
                }))}
              />
            )}
          />
        ) : (
          watch('status')?.label
        )}
      </Table.Cell>
      <Table.Cell>
        <S.SaveContainer>
          {isEditing ? (
            <>
              <Button
                disabled={!isDirty}
                type='submit'
                icon='save_outline'
                variant='verySmallIcon'
                onClick={handleSubmit(submit)}
              />
              <Button
                type='button'
                icon='close'
                variant='verySmallIcon'
                onClick={() => {
                  reset()
                  setIsEditing(false)
                }}
              />
            </>
          ) : (
            <Button
              type='button'
              icon='pencil'
              variant='verySmallIcon'
              onClick={() => {
                setIsEditing(true)
              }}
            />
          )}
          {errors.hub?.message || errors.status?.message ? (
            <IconTooltip
              content={
                <div>
                  {errors.hub?.message && <div>{errors.hub?.message}</div>}
                  {errors.status?.message && <div>{errors.status?.message}</div>}
                </div>
              }
              width={20}
              height={20}
              size='nowrap'
              color='warn'
              placement='top-left'
              variant='warning'
            />
          ) : null}
        </S.SaveContainer>
      </Table.Cell>
    </Table.Row>
  )
}

export default Item
