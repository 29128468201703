import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  REACT_DROPZONE_FILE_TOO_LARGE_ERROR,
  FILE_MAX_SIZE_B,
  FILE_MAX_SIZE_MB,
} from 'constants/files'

const FILE_STATE = { uploaded: false, uploading: false }

const useUploadFiles = () => {
  const [files, setFiles] = useState([])

  const onDropFiles = (acceptedFiles, fileRejections) => {
    const fileObject = {
      id: uuidv4(),
      nativeFile: null,
      state: FILE_STATE,
      errors: [],
    }
    const newFiles = acceptedFiles.map((file) => ({
      ...fileObject,
      nativeFile: file,
    }))
    const rejectedFiles = fileRejections.map(({ file, errors }) => {
      const formattedErrors = errors.map(({ code, message }) => {
        let formattedMessage = message
        if (code === REACT_DROPZONE_FILE_TOO_LARGE_ERROR) {
          formattedMessage = formattedMessage.replace(FILE_MAX_SIZE_B, FILE_MAX_SIZE_MB)
          formattedMessage = formattedMessage.replace('bytes', 'MB')
        }
        return { code, message: formattedMessage }
      })

      return {
        ...fileObject,
        nativeFile: file,
        errors: formattedErrors,
      }
    })

    setFiles((prevFiles) => [...prevFiles, ...newFiles, ...rejectedFiles])
  }

  const removeAllFiles = () => setFiles([])

  const getFile = (filesArray, file) => filesArray.find((f) => f === file)

  const removeFile = (file) => () => {
    const newFiles = [...files]
    newFiles.splice(
      newFiles.findIndex(({ nativeFile }) => nativeFile === file),
      1
    )
    setFiles(newFiles)
  }

  const setFileState = (file, { uploading, uploaded }) => {
    const newFiles = [...files]
    const newFilesFile = getFile(newFiles, file)
    newFilesFile.state = {
      uploading: uploading !== null ? uploading : file.state.uploading,
      uploaded: uploaded !== null ? uploaded : file.state.uploaded,
    }
    setFiles(newFiles)
  }

  const setFileType = (file, { label, value }) => {
    const newFiles = [...files]
    const newFilesFile = getFile(newFiles, file)
    newFilesFile.fileType = {
      label,
      value,
    }
    setFiles(newFiles)
  }

  const setFileError = (file, { code, message }) => {
    const newFiles = [...files]
    const newFilesFile = getFile(newFiles, file)
    newFilesFile.errors.push({
      code,
      message,
    })
    setFiles(newFiles)
  }

  return {
    onDropFiles,
    files,
    removeAllFiles,
    removeFile,
    setFileType,
    setFileState,
    setFileError,
  }
}

export default useUploadFiles
