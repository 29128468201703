import React from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'

import BookingListItemSkeleton from 'components/booking_list_item/skeleton'

import StyledBookingList from 'components/booking_list/style'
import { anyChildren } from 'services/helpers/prop_types'

const BookingListSkeletonWrapper = ({ isEmbedded, children }) =>
  isEmbedded ? children : <StyledBookingList>{children}</StyledBookingList>

BookingListSkeletonWrapper.propTypes = {
  children: anyChildren,
  isEmbedded: PropTypes.bool,
}

BookingListSkeletonWrapper.defaultProps = {
  children: undefined,
  isEmbedded: false,
}

const BookingListSkeleton = ({ count, isEmbedded }) => (
  <BookingListSkeletonWrapper isEmbedded={isEmbedded}>
    {Array.from(Array(count)).map(() => (
      <BookingListItemSkeleton key={uuidv4()} />
    ))}
  </BookingListSkeletonWrapper>
)

BookingListSkeleton.propTypes = {
  count: PropTypes.number,
  isEmbedded: PropTypes.bool,
}

BookingListSkeleton.defaultProps = {
  count: 3,
  isEmbedded: false,
}

export default BookingListSkeleton
