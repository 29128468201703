import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import useModal from 'components/modal/hooks/use_modal'
import ActionModalContent from 'views/booking/components/action_modal_content'
import Modal from 'components/modal'

import { createBooking } from 'views/booking/slices/booking_slice'
import { addNotification } from 'views/notifications/slice'

import Button from 'components/button'
import { uploadDocument } from 'views/booking/slices/documents_slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'

const ValidateCreateModal = ({ queryParams, uploadedFiles }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingValidateCreate')
  const history = useHistory()

  const onCloseModal = () => setOpen(false)

  const onClickSend = () => {
    dispatch(createBooking(queryParams))
      .unwrap()
      .then(async ({ token }) => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('bookings.alerts.createBooking.title'),
            text: t('bookings.alerts.createBooking.successMessage'),
          })
        )

        // eslint-disable-next-line no-restricted-syntax
        for (const {
          id,
          nativeFile,
          fileType: { value: documentType },
        } of uploadedFiles) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(
            uploadDocument({
              token,
              documentId: id,
              document: nativeFile,
              documentType,
              withEmailNotification: false,
            })
          )
            .unwrap()
            .catch(() =>
              dispatch(
                addNotification({
                  type: 'alert',
                  title: t('documents.uploadError.title'),
                  text: t('documents.uploadError.errorMessage'),
                })
              )
            )
        }
        history.push('/bookings')
      })
      .catch(() =>
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      )
  }

  return (
    <Modal position='center' onClose={onCloseModal} modalName='bookingValidateCreate'>
      <Modal.Header>{t('bookings.exchangeModal.createBooking.title')}</Modal.Header>
      <Modal.Content>
        <ActionModalContent actionKey='createBooking' />
      </Modal.Content>
      <Modal.Footer>
        <Button
          text={t('actions.cancel')}
          onClick={onCloseModal}
          rounded
          variant='clear'
          as={Button}
        />
        <Button
          text={t('actions.send')}
          onClick={onClickSend}
          rounded
          variant='highlight'
          as={Button}
        />
      </Modal.Footer>
    </Modal>
  )
}

ValidateCreateModal.propTypes = {
  /** queryParams */
  // eslint-disable-next-line
  queryParams: PropTypes.any,
  // eslint-disable-next-line
  uploadedFiles: PropTypes.arrayOf(PropTypes.any),
}

ValidateCreateModal.defaultProps = {
  queryParams: undefined,
  uploadedFiles: [],
}

export default ValidateCreateModal
