import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'

import { selectAlerts, selectAlertsStatus } from 'views/shipment/slice'

const useAlerts = ({ id }) => {
  const alerts = useSelector(selectAlerts({ id }))
  const status = useStatus(useSelector((state) => selectAlertsStatus(state, id)))
  return [alerts, status]
}

export default useAlerts
