import styled, { css, keyframes } from 'styled-components'
import { ReactSVG } from 'react-svg'

import StyledIcon from 'components/icon/style'
import { StyledAccordionItemHeadingArrow } from 'components/accordion_item/style'

import { borderRadiusMedium, borderRadiusSmall } from 'styles/utils/border_radius'
import { shadow } from 'styles/utils/shadow'
import { text14Bold, text14Regular, text24SemiBold } from 'styles/utils/texts'
import { tablet, desktopLarge } from 'styles/utils/breakpoints'

import {
  TrustedRoutesPagePadding,
  TrustedRoutesMaxWidth,
  TrustedRoutesGap,
} from 'views/trusted_routes/style'
import { TrustedRoutesSidebarWidth } from 'views/trusted_routes/components/filters/style'
import { easeQuartOut } from 'styles/utils/ease'
import { StyledMap } from 'components/map/style'

const ScacLogo = styled(ReactSVG)<{ $width: number; $height: number }>`
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${(props) =>
    props.$width &&
    css`
      width: ${props.$width}px;
    `}

  ${(props) =>
    props.$height &&
    css`
      height: ${props.$height}px;
    `}
`

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.mediumGray};
  gap: 20px;
  @media ${tablet.down} {
    flex-direction: column;
    align-items: center;
  }
`

const CardLeftContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 20px;
  @media ${desktopLarge.up} {
    gap: 40px;
  }
  min-width: 0;
  flex-basis: 60%;
`

const cardReveal = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-10px,0,0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`

const AlertIconTooltip = styled.div`
  position: absolute;
  right: 12px;
  top: 5px;
  svg {
    fill: ${({ theme }) => theme.warn};
  }
`

const AlertTimelineContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.warn};
  ${StyledIcon} {
    flex-shrink: 0;
  }
`

const AlertTimelineMessage = styled.div`
  padding: 5px 0;
`

const Container = styled.div`
  position: relative;
  animation: 0.3s ${cardReveal} ${easeQuartOut} forwards;
  max-width: calc(${TrustedRoutesMaxWidth}px - ${TrustedRoutesSidebarWidth + TrustedRoutesGap}px);
  width: calc(
    100vw - ${TrustedRoutesPagePadding * 2 + TrustedRoutesSidebarWidth + TrustedRoutesGap}px
  );
  background-color: ${(props) => props.theme.white};
  ${borderRadiusSmall};
  padding: 20px;
  &:hover {
    ${shadow};
  }

  ${StyledAccordionItemHeadingArrow} {
    margin-right: 0;
  }

  @media ${desktopLarge.down} {
    width: 100%;
    max-width: calc(100% - ${TrustedRoutesPagePadding}px);
  }

  @media ${tablet.down} {
    width: 100%;
    max-width: calc(100% - ${TrustedRoutesGap}px);
  }
`

const TransportContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  gap: 5px;
  min-width: 0;
  overflow: hidden;
`

const Criteria = styled.div`
  display: flex;
  align-items: stretch;
  flex-basis: 435px;
  justify-content: space-between;
  padding-left: 15px;
  gap: 20px;
  @media ${tablet.down} {
    padding-left: 0;
    flex-basis: 100%;
    gap: 40px;
  }
`

const Criterion = styled.div<{ $type: 'days' | 'reliability' | 'co2' | 'rate' }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ $type }) => $type === 'days' && 'flex-basis: 20%;'}
  ${({ $type }) => $type === 'reliability' && 'flex-basis: 20%;'}
  ${({ $type }) => $type === 'co2' && 'flex-basis: 40%;'}
  ${({ $type }) => $type === 'rate' && 'flex-basis: 20%;'}
`

const Vessel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const VesselName = styled.div<{ $faded?: boolean }>`
  color: ${({ theme, $faded }) => ($faded ? theme.mediumGray : theme.textDark)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Text = styled.div<{ $tabbed?: boolean; $faded?: boolean }>`
  color: ${({ theme, $faded }) => ($faded ? theme.mediumGray : theme.textDark)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${text14Regular};
  ${({ $tabbed }) => $tabbed && 'padding-left: 10px;'}
`

const CriterionContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  ${text24SemiBold};
  color: ${(props) => props.theme.textDark};
`

const ShipmentIcon = styled.div`
  fill: ${({ theme }) => theme.primary};
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`

const Circle = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 9px;
  background: ${(props) => props.color};
  color: ${(props) => props.theme.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

const BestRate = styled.div`
  ${text24SemiBold};
  color: ${(props) => props.theme.textDark};
`

const ArrowIcon = styled(StyledIcon)`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.mediumGray};
`

const VerticalBar = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.lightGray};
  border-left-width: 1px;
  @media ${tablet.down} {
    border-bottom-width: 1px;
    width: 100%;
  }
`

const TimelineWrapper = styled.div`
  width: 350px;
`

const CutOffDate = styled.div`
  color: ${(props) => props.theme.textDark};
  display: flex;
  align-items: center;
  gap: 5px;
  ${text14Regular}
`

const UnfoldedBaseContent = styled.div`
  margin-top: ${TrustedRoutesGap}px;
  gap: ${TrustedRoutesGap}px;
`

const UnfoldedContent = styled(UnfoldedBaseContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const UnfoldedContentTopBlock = styled.div`
  display: flex;
  gap: 40px;
  align-items: start;
`

const GoToOkargo = styled.div`
  padding: 20px 0 10px 0;
  color: ${(props) => props.theme.textDark};
  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;
  }
`

const MapContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 50px 10px 10px 10px;
`

const Map = styled.div`
  width: 100%;
  height: 400px;
  ${StyledMap} {
    ${borderRadiusMedium};
  }
`

const ReliabilityTooltipNbDay = styled.span`
  ${text14Bold};
`

const S = {
  ScacLogo,
  Card,
  CardLeftContainer,
  VerticalBar,
  TransportContent,
  Circle,
  Criteria,
  Criterion,
  Text,
  CriterionContent,
  ArrowIcon,
  ShipmentIcon,
  Vessel,
  VesselName,
  Container,
  CutOffDate,
  TimelineWrapper,
  UnfoldedContent,
  UnfoldedContentTopBlock,
  UnfoldedBaseContent,
  BestRate,
  GoToOkargo,
  AlertIconTooltip,
  AlertTimelineContainer,
  AlertTimelineMessage,
  MapContainer,
  Map,
  ReliabilityTooltipNbDay,
}

export default S
