import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { SSOSignOutContainer, StyledLink } from 'views/iam/style'
import Link from 'components/link'

const SSOSignOut: React.FC = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const loginUrl = searchParams.get('loginUrl')

  return (
    <SSOSignOutContainer>
      <h1> {t('iam.logout.title')} </h1>
      {loginUrl ? (
        <p>
          <Link to={loginUrl} onClick={() => {}}>
            <StyledLink> {t('iam.logout.login')}</StyledLink>
          </Link>
        </p>
      ) : null}
    </SSOSignOutContainer>
  )
}
export default SSOSignOut
