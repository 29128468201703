import React, {
  FC,
  memo,
  NamedExoticComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactModal from 'react-modal'

import ModalContext from 'components/modal/context'
import { StyledModal, StyledOverlay } from 'components/modal/style'
import Footer from 'components/modal/modal_footer'
import Content from 'components/modal/modal_content'
import Header from 'components/modal/modal_header'
import InternalModalContext from 'components/modal/internal_context'
import buildInWindowCoordinates from 'components/modal/helpers/build_in_window_coordinates'

import { ModalPosition } from 'components/modal/types/types'

import type { ModalName } from 'components/modal/types/names'

// Interface reference: http://reactcommunity.org/react-modal/
//
// Usage
// - add a unique name to APP_MODAL_NAMES in src/components/modal/types/names.ts
// - use it for modalName prop
// - declare your modale where you want to anchor it <MyModal/>
// - control modal opening with { setOpen } = useModal(); setOpen('myModalUniqueName')
//
// TODO: REFACTOR:
// Every modal created from this one should not be rendered as long as isOpen is false
// For now it is required to control the render from the exterior:
// {modals.myModalName && <MyModal/>}
// If not, code from MyModal is executed even when the modal is not rendered

interface ModalComp {
  Header: typeof Header
  Content: typeof Content
  Footer: typeof Footer
}

interface ModalProps {
  width?: 'small' | 'normal' | 'large' | 'full'
  height?: 'normal' | 'full'
  noPadding?: boolean
  className?: string
  modalName: ModalName
  shouldFocusAfterRender?: boolean
  onClose?: () => void
  position?: ModalPosition
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  height = 'normal',
  children,
  modalName,
  className,
  shouldFocusAfterRender = true,
  width = 'normal',
  noPadding = false,
  onClose,
  position = 'top',
}) => {
  const { modals, setOpen } = useContext(ModalContext)
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)
  const modalElement = useRef<HTMLDivElement | null>(null)
  const coordinates = buildInWindowCoordinates(position, contentRef)
  const onRequestClose = useCallback(() => {
    setOpen(modalName, false)
  }, [setOpen, modalName])
  const onAfterClose = useCallback(() => {
    if (onClose) onClose()
  }, [onClose])
  const style = useMemo(() => ({ overlay: StyledOverlay }), [])

  return (
    <InternalModalContext.Provider value={{ name: modalName }}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <StyledModal
        className={className}
        ref={modalElement}
        isOpen={modals[modalName]}
        onRequestClose={onRequestClose}
        style={style}
        ariaHideApp={false}
        $width={width}
        $height={height}
        $noPadding={noPadding}
        as={ReactModal}
        shouldFocusAfterRender={shouldFocusAfterRender}
        contentRef={setContentRef}
        onAfterClose={onAfterClose}
        $coordinates={coordinates}
        $position={position}
        appElement={document.getElementById('root')}
      >
        {children}
      </StyledModal>
    </InternalModalContext.Provider>
  )
}

const MemoModal = memo(Modal) as NamedExoticComponent<PropsWithChildren<ModalProps>> & ModalComp

MemoModal.Header = Header
MemoModal.Content = Content
MemoModal.Footer = Footer

export { MemoModal as MemoModalStoryBook }

export default MemoModal
