import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ORDER_USERS_READ, ORDER_ADMIN, AUDIT_TRAIL_READ } from 'constants/permissions'

import useTracker from 'services/analytics/hooks/use_tracker'
import DateHelper from 'services/helpers/date_helper'

import useUserCan from 'views/iam/hooks/use_user_can'
import ShipmentContext from 'views/shipment/context'
import useShipment from 'views/shipment/hooks/use_shipment'
import { selectUsers } from 'views/shipment/slice'
import { setFavorite, unsetFavorite } from 'views/shipments/slice'
import ShipmentUsersList from 'views/shipment/components/users_list'
import ShipmentEmissions from 'views/shipment/components/emissions'
import ErrorNotification from 'views/errors/error_notification'
import { addNotification } from 'views/notifications/slice'
import {
  StyledShipmentContentHead,
  StyledShipmentContentHeadTitle,
  StyledShipmentContentHeadRight,
  StyledShipmentContentCreationDate,
  StyledShipmentContentHeadLeft,
  StyledShipmentFavorite,
  StyledShipmentContentHeadLeftWrap,
} from 'views/shipment/style'

import Button from 'components/button'
import ModalShare from 'components/modal_share'
import Favorite from 'components/favorite'
import ActionMenu from 'views/shipment/components/action_menu'

import { TEST_ID_SHIPMENT_SHOW_REFERNECE, TEST_ID_AUDIT_TRAIL } from 'tests/e2e/test_ids'
import { routeShipmentAuditTrail } from 'services/helpers/routes'

import ModalDeleteShipment from 'components/modal_delete_shipment'
import useUserIs from 'views/iam/hooks/use_user_is'
import { ADMIN, OUTSOURCING, SUPER_ADMIN } from 'constants/roles'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import useBreakpoints from 'services/hooks/use_breakpoints'
import { WITH_SHAREABLE_LINK } from 'constants/organization_features'
import useModal from 'components/modal/hooks/use_modal'
import ModalReportShipment from 'components/modal_report_shipment'
import ModalPath from 'components/modal_path'
import { isAnyArray } from 'services/helpers/values'

const ShipmentHeader = React.memo(() => {
  const userCan = useUserCan()
  const userIs = useUserIs()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useContext(ShipmentContext)
  const { isLowerThanDesktop } = useBreakpoints()
  const organizationCan = useOrganizationCan()
  const users = useSelector(selectUsers({ id }))
  const [shipment, status] = useShipment({ id })
  const { favorite: isActive } = shipment || {}
  const { track } = useTracker()
  const { setOpen: setShareModaleOpen } = useModal('share')
  const toggleFavorite = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(isActive ? unsetFavorite({ id }) : setFavorite({ id }))
        .unwrap()
        .then(() => {
          track(`Shipment list / ${isActive ? 'unset' : 'set'} favourite`, { status: 'success' })
        })
        .catch(() => {
          track(`Shipment list / ${isActive ? 'unset' : 'set'} favourite`, { status: 'error' })
          dispatch(
            addNotification({
              type: 'alert',
              title: t('errors.notification.title'),
              text: t('errors.notification.content'),
            })
          )
        })
    },
    [dispatch, t, track, isActive, id]
  )

  let title = `${t('states.loading')}...`
  if (status.fulfilled && shipment) {
    title = shipment.reference || t('shipments.shipmentTitle')
  }

  if (status.rejected) {
    return <ErrorNotification />
  }

  return (
    <StyledShipmentContentHead>
      <ModalShare />
      <ModalDeleteShipment />
      <ModalReportShipment />
      <ModalPath />
      <StyledShipmentContentHeadLeft>
        <StyledShipmentContentHeadLeftWrap>
          <StyledShipmentFavorite>
            <Favorite active={isActive} onClick={toggleFavorite} big />
          </StyledShipmentFavorite>
          <StyledShipmentContentHeadTitle data-testid={TEST_ID_SHIPMENT_SHOW_REFERNECE}>
            {title}
          </StyledShipmentContentHeadTitle>
        </StyledShipmentContentHeadLeftWrap>
        <ShipmentEmissions />
      </StyledShipmentContentHeadLeft>
      <StyledShipmentContentHeadRight>
        {shipment?.createdAt && userCan(ORDER_ADMIN) && (
          <StyledShipmentContentCreationDate>{`${new DateHelper(shipment.createdAt).toLocale({
            hours: true,
          })}`}</StyledShipmentContentCreationDate>
        )}
        {isAnyArray(users) && userCan(ORDER_USERS_READ) && <ShipmentUsersList users={users} />}

        {userIs(ADMIN) || userIs(SUPER_ADMIN) || userIs(OUTSOURCING) ? (
          <ActionMenu />
        ) : (
          organizationCan.features(WITH_SHAREABLE_LINK) && (
            <Button
              icon='share_outline'
              rounded={isLowerThanDesktop}
              text={isLowerThanDesktop ? null : t('actions.share')}
              onClick={() => {
                track('Shipment / Share', { action: 'pre-share' })
                setShareModaleOpen(true)
              }}
            />
          )
        )}
        {userCan(AUDIT_TRAIL_READ) && (
          <Button
            testId={TEST_ID_AUDIT_TRAIL}
            icon='time_clock'
            rounded
            isLink
            to={routeShipmentAuditTrail(id)}
          />
        )}
      </StyledShipmentContentHeadRight>
    </StyledShipmentContentHead>
  )
})
ShipmentHeader.propTypes = {}

ShipmentHeader.defaultProps = {
  testId: undefined,
}

export default ShipmentHeader
