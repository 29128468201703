import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'

import { selectEmissions, selectEmissionsStatus } from 'views/shipment/slice'

const useEmissions = ({ id }) => {
  const emissions = useSelector(selectEmissions({ id }))
  const status = useStatus(useSelector((state) => selectEmissionsStatus(state, id)))
  return [emissions, status]
}

export default useEmissions
