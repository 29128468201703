import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Select, { SelectProps, Tvalue } from 'components/select'

import { ISearch } from 'views/select_options/slice'
import {
  SEARCH_TYPE_LOCATION_AIRPORT,
  SEARCH_TYPE_LOCATION_FACILITY,
  SEARCH_TYPE_LOCATION_PORT,
} from 'constants/search_selects'

import type { AsyncThunkAction } from '@reduxjs/toolkit'

export type TSearchType =
  | typeof SEARCH_TYPE_LOCATION_PORT
  | typeof SEARCH_TYPE_LOCATION_AIRPORT
  | typeof SEARCH_TYPE_LOCATION_FACILITY
export interface ISearchSelectProps extends SelectProps {
  action: (search: ISearch) => AsyncThunkAction<any, any, any>
  type?: TSearchType
  extraActionParams?: any
}

const SearchSelect: FC<ISearchSelectProps> = ({
  action,
  type,
  isClearable = true,
  name,
  extraActionParams,
  ...props
}) => {
  const actionParams = (v: Tvalue) => ({
    search: v,
    ...(extraActionParams ? { extraActionParams } : {}),
    ...(type ? { type } : {}),
  })
  const fetch = ({ value: v }: { value: Tvalue }) => action(actionParams(v))
  const fetchOnFocus = () => action(actionParams(null))
  const { t } = useTranslation()
  return (
    <Select
      name={name}
      inputId={name}
      placeholder={t('actions.search')}
      isClearable={isClearable}
      isSearchable
      async
      fetch={fetch}
      fetchOnFocus={fetchOnFocus}
      // Prop spreading is allowed here because SearchSelect is a wrapper around Select:
      // it sets default props and fetch utilities. Any other props are standard Select props.
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default SearchSelect
