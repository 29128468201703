import styled from 'styled-components'

import { listItem13 } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { StyledButton } from 'components/button/style'

export const StyledImportFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 11px 0;
  position: relative;
`
export const StyledImportFileWrapper = styled.div`
  border: 2px solid #e3e6ec;
  ${borderRadiusMedium};
  padding: 4px 15px;
`

export const StyledImportFileIcon = styled(StyledIcon)`
  flex: none;
  width: 17px;
  height: 19px;
  margin-right: 12px;
  fill: ${({ theme }) => theme.mediumGray};
`

export const StyledImportFileName = styled.p`
  flex: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  ${listItem13}
  padding-right: 10px;
  width: 90%;
  color: ${({ theme }) => theme.textDark};
`
export const StyledNotifMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const StyledErrorMessage = styled.li`
  list-style-type: disc;
  margin-bottom: 10px;
  margin-left: 10px;
  :first-child {
    margin-top: 10px;
  }
`

export const StyledImportFilesButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  ${StyledButton} {
    margin: 0 7px;
  }
`

export const StyledImportFileValidate = styled.div`
  width: 30px;
  height: 30px;
  fill: ${({ theme }) => theme.success};
  position: absolute;
  right: 0;
`

export const StyledImportFileWarning = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;

  ${StyledIcon} {
    fill: ${({ theme }) => theme.error};
    width: 100%;
  }
`
export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`
