import React from 'react'

import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

import Modal from 'components/modal'
import UserForm from 'views/users/components/form'
import useUserForm from 'views/users/components/form/hooks/use_user_form'
import Button from 'components/button'
import { createUser } from 'views/users/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import { TEST_ID_USERS_CREATE_USER_BUTTON } from 'tests/e2e/test_ids'
import useModal from 'components/modal/hooks/use_modal'

const ModalNewUser = ({ onClose }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isValid, filters, userPayload } = useUserForm()
  const { setOpen } = useModal('newUser')

  const create = () =>
    dispatch(createUser({ user: userPayload }))
      .unwrap()
      .then(() => {
        setOpen(false)
        dispatch(
          addNotification({
            type: 'success',
            title: t('users.alerts.create.title'),
            text: t('users.alerts.create.successMessage'),
          })
        )
        return onClose && onClose()
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })

  return (
    <Modal width='large' modalName='newUser' position='center'>
      <Modal.Header>{t('users.actions.newUser')}</Modal.Header>
      <Modal.Content>
        <UserForm isValid={isValid} filters={filters} />
      </Modal.Content>
      <Modal.Footer>
        <Button onClick={() => setOpen(false)} variant='clear' text={t('actions.cancel')} />
        <Button
          testId={TEST_ID_USERS_CREATE_USER_BUTTON}
          disabled={!isValid}
          variant='highlight'
          text={t('actions.create')}
          onClick={create}
        />
      </Modal.Footer>
    </Modal>
  )
}

ModalNewUser.propTypes = {
  onClose: PropTypes.func,
}

ModalNewUser.defaultProps = {
  onClose: () => {},
}

export default ModalNewUser
