import React, { useCallback, useMemo, useState, FC, FocusEvent } from 'react'

import { StyledMenu, StyledMenuDropdown } from 'components/menu/style'
import MenuContext from 'components/menu/context'

export { default as MenuItem } from 'components/menu/menu_item'

const Menu: FC<{
  control: React.ReactNode
  children: React.ReactNode
  disabled?: boolean
  onBlur?: (e?: FocusEvent<HTMLInputElement>) => void
  dropdownPosition?: 'default' | 'right'
}> = ({ control, children, disabled, onBlur, dropdownPosition = 'default' }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isMouseHover, setIsMouseHover] = useState(false)
  const onBlurInternal = useCallback(() => {
    if (!isMouseHover) setIsOpen(false)
    onBlur?.()
  }, [isMouseHover, onBlur])
  const closeMenu = useCallback(() => setIsOpen(false), [])
  const toggleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen])
  const hoverIn = useCallback(() => setIsMouseHover(true), [])
  const hoverOut = useCallback(() => setIsMouseHover(false), [])
  const contextValue = useMemo(() => ({ closeMenu }), [closeMenu])

  return (
    <MenuContext.Provider value={contextValue}>
      <StyledMenu onBlur={onBlurInternal} onMouseEnter={hoverIn} onMouseLeave={hoverOut}>
        <button disabled={disabled} type='button' onClick={toggleOpen}>
          {control}
        </button>
        {!disabled && (
          <StyledMenuDropdown $dropdownPosition={dropdownPosition} $open={isOpen}>
            {children}
          </StyledMenuDropdown>
        )}
      </StyledMenu>
    </MenuContext.Provider>
  )
}

export default Menu
