import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED, Status } from 'constants/api'
import { SEARCH_SOURCE } from 'constants/search_bar'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useUrlParams from 'services/api/hooks/use_url_params'
import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'

import { isPresent } from 'services/helpers/values'
import { ActiveValue } from 'views/shipments/types/filters'

import type { RootState } from 'services/store/store'
import type { Shipment } from 'views/shipments/types/shipment'

export type SearchSource = typeof SEARCH_SOURCE

export const SORT_BY_DEPARTURE_AT = 'departure_date'
export const SORT_BY_CREATED_AT = 'created_at'
export const SORT_BY_ARRIVED_AT = 'transportation_date'
export const SORT_BY_ETA_DIFFERENCE = 'eta_diff'
export const SORT_BY_LAST_ETA_CHANGE = 'last_eta_change'
export const SORT_BY_OPTIONS = [
  SORT_BY_CREATED_AT,
  SORT_BY_ARRIVED_AT,
  SORT_BY_ETA_DIFFERENCE,
  SORT_BY_LAST_ETA_CHANGE,
] as const

export const FILTER_BY_FAVORITE = 'favorite_user_ids_eq'

export const DIRECTION_ASCENDING = 'asc'
export const DIRECTION_DESCENDING = 'desc'
export const DIRECTIONS = [DIRECTION_ASCENDING, DIRECTION_DESCENDING] as const
export type Directions = typeof DIRECTIONS[number]
interface FetchShipmentsParams {
  filters?: Record<string, any>
  sortBy?:
    | typeof SORT_BY_DEPARTURE_AT
    | typeof SORT_BY_ARRIVED_AT
    | typeof SORT_BY_CREATED_AT
    | typeof SORT_BY_ETA_DIFFERENCE
    | typeof SORT_BY_LAST_ETA_CHANGE
  direction?: typeof DIRECTION_ASCENDING | typeof DIRECTION_DESCENDING
  page?: number
  per?: number
  source?: SearchSource
  user_id_visibility?: number
  favoriteUserId?: number
}

export type ShipmentResponse = Omit<Shipment, 'id'> & { token: string }

export const fetchShipments = createAsyncThunk(
  'shipments/fetchShipments',
  async (
    {
      filters,
      sortBy: sort,
      direction,
      page,
      per,
      source,
      user_id_visibility,
      favoriteUserId,
    }: FetchShipmentsParams = {},
    thunkAPI
  ) => {
    const activeFilters = selectActiveFilters(thunkAPI.getState() as RootState)
    const teams = useThunkTeamsParam(thunkAPI)
    const filterBarFilters = isPresent(filters) ? filters : activeFilters
    const favoriteFilter = isPresent(favoriteUserId) ? { [FILTER_BY_FAVORITE]: favoriteUserId } : {}
    const q = { ...filterBarFilters, ...favoriteFilter }
    const url = useUrlParams('/shipments', {
      q,
      sort,
      direction,
      page,
      per,
      ...teams,
      user_id_visibility,
    })

    return InternalClient.get(url)
      .then((r) => r.data)
      .then(({ totalCount, shipments }) => ({
        totalCount,
        shipments: shipments.map(({ token, ...shipment }: ShipmentResponse) => ({
          id: token,
          ...shipment,
          // TODO: remove this  after data migration
          parties: shipment.parties || {},
          source,
        })),
      }))
      .catch(onError(thunkAPI))
  }
)

export const fetchShipment = createAsyncThunk(
  'shipments/fetchShipment',
  async ({ token }: { token: string }, thunkAPI) =>
    InternalClient.get(`/shipments/${token}`)
      .then((r) => r.data)
      .then(({ token: t, ...shipment }) => ({ id: t, ...shipment }))
      .catch(onError(thunkAPI))
)

export const fetchFilters = createAsyncThunk(
  'shipments/fetchFilters',
  async (
    { name, value, filters: q }: { name: string; value: any; filters: Record<string, any> },
    thunkAPI
  ) => {
    const teams = useThunkTeamsParam(thunkAPI)
    const url = useUrlParams('/shipments/filters', { name, value, q, ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchRoutingFilters = createAsyncThunk(
  'shipments/fetchRoutingFilters',
  async (
    { suffix, value, filters: q }: { suffix: string; value: any; filters: Record<string, any> },
    thunkAPI
  ) => {
    const teams = useThunkTeamsParam(thunkAPI)
    const urlRouting = useUrlParams('/shipments/filters', {
      name: `routing_${suffix}`,
      value,
      q,
      ...teams,
    })
    const urlCountry = useUrlParams('/shipments/filters', {
      name: `routing_country_${suffix}`,
      value,
      q,
      ...teams,
    })
    return Promise.all([InternalClient.get(urlCountry), InternalClient.get(urlRouting)])
      .then(([resultCountry, resultRouting]) =>
        [
          ...resultCountry.data.map((name: string) => ({
            value: name,
            label: name,
            type: 'country',
          })),
          ...resultRouting.data.map((name: string) => ({
            value: name,
            label: name,
            type: 'location',
          })),
        ].slice(0, 50)
      )
      .catch(onError(thunkAPI))
  }
)

export const exportShipments = createAsyncThunk(
  'shipments/exportShipments',
  async ({ startDate, endDate }: { startDate: string; endDate: string }, thunkAPI) => {
    const url = useUrlParams('/export_orders', { startDate, endDate })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const exportAvailable = createAsyncThunk(
  'shipments/exportShipmentsAvailable',
  async (_, thunkApi) => {
    const url = '/export_available'
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkApi))
  }
)

export const setFavorite = createAsyncThunk(
  'shipments/setFavorite',
  async ({ id }: { id: string }, thunkAPI) =>
    InternalClient.post(`/orders/${id}/set_favorite`)
      .then((r) => r.data)
      .then(() => ({ id }))
      .catch(onError(thunkAPI))
)

export const unsetFavorite = createAsyncThunk(
  'shipments/unsetFavorite',
  async ({ id }: { id: string }, thunkAPI) =>
    InternalClient.post(`/orders/${id}/unset_favorite`)
      .then((r) => r.data)
      .then(() => ({ id }))
      .catch(onError(thunkAPI))
)

const shipmentsAdapter = createEntityAdapter<Shipment>()
interface ShipmentsInitialState {
  totalCount?: number
  status?: Status
  activeFilters: Record<string, any>[]
  activeValues: ActiveValue[]
  sortBy:
    | typeof SORT_BY_ARRIVED_AT
    | typeof SORT_BY_CREATED_AT
    | typeof SORT_BY_ETA_DIFFERENCE
    | typeof SORT_BY_LAST_ETA_CHANGE
  direction: typeof DIRECTION_ASCENDING | typeof DIRECTION_DESCENDING
  filterByFavorites: boolean
}
const initialState = shipmentsAdapter.getInitialState<ShipmentsInitialState>({
  totalCount: undefined,
  status: undefined,
  activeFilters: [],
  activeValues: [],
  sortBy: SORT_BY_CREATED_AT,
  direction: DIRECTION_DESCENDING,
  filterByFavorites: false,
})

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    saveActiveFilters: (state, action) => {
      state.activeFilters = action.payload.activeFilters
    },
    saveActiveValues: (state, action) => {
      state.activeValues = action.payload.activeValues
    },
    saveDirection: (state, action) => {
      state.direction = action.payload
    },
    saveSortBy: (state, action) => {
      state.sortBy = action.payload
    },
    saveFilterByFavorites: (state, action) => {
      state.filterByFavorites = action.payload
    },
    removeAll: shipmentsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShipments.fulfilled, (state, action) => {
      if (action.meta.arg.source === SEARCH_SOURCE) return

      state.totalCount = action.payload.totalCount
      if (action.meta.arg.page && action.meta.arg.page <= 1) {
        shipmentsAdapter.setAll(state, action.payload.shipments)
      } else {
        shipmentsAdapter.setMany(state, action.payload.shipments)
      }
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchShipments.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchShipments.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(setFavorite.fulfilled, (state, action) => {
      shipmentsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { favorite: true },
      })
    })
    builder.addCase(unsetFavorite.fulfilled, (state, action) => {
      shipmentsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { favorite: false },
      })
    })
  },
})
export const { selectAll: selectShipments } = shipmentsAdapter.getSelectors(
  (state: RootState) => state.shipments
)
export const {
  saveActiveFilters,
  removeAll,
  saveActiveValues,
  saveDirection,
  saveSortBy,
  saveFilterByFavorites,
} = shipmentsSlice.actions
export const selectShipmentsStatus = (state: RootState) => state.shipments.status
export const selectShipmentsTotalCount = (state: RootState) => state.shipments.totalCount
export const selectActiveFilters = (state: RootState) => state.shipments.activeFilters
export const selectActiveValues = (state: RootState) => state.shipments.activeValues
export const selectSortBy = (state: RootState) => state.shipments.sortBy
export const selectDirection = (state: RootState) => state.shipments.direction
export const selectFilterByFavorites = (state: RootState) => state.shipments.filterByFavorites
export const selectShipmentsFromSource = (source: SearchSource) =>
  createSelector(selectShipments, (shipments) => shipments.filter(({ source: s }) => s === source))

export default shipmentsSlice.reducer
