import React, { FC, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from 'styled-components'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import usePagination from 'services/api/hooks/use_pagination'

import ErrorNotification from 'views/errors/error_notification'
import useUsers from 'views/users/hooks/use_users'
import useUserCan from 'views/iam/hooks/use_user_can'
import { fetchUsers, fetchUserSettings, DEFAULT_USERS_PER_PAGE } from 'views/users/slice'
import UserSkeleton from 'views/users/components/skeleton'
import S from 'views/users/style'
import Sidebar from 'views/users/components/sidebar'
import { addNotification } from 'views/notifications/slice'

import Page from 'components/page'
import PS, { PageActions } from 'components/page/style'
import Icon from 'components/icon'
import Button from 'components/button'
import SwitchInput from 'components/switch_input'
import HoverTooltip from 'components/hover_tooltip'
import NotifMessage from 'components/notif_message'
import {
  getTestIdForUserActivated,
  getTestIdForUserCompany,
  getTestIdForUserEmail,
  getTestIdForUserFullname,
  getTestIdForUserOrganization,
  getTestIdForUserRoles,
  getTestIdForUserSettingsButton,
  TEST_ID_USER_INDEX_LEGEND_ACTIONS,
  TEST_ID_USER_INDEX_LEGEND_ACTIVATED,
  TEST_ID_USER_INDEX_LEGEND_COMPANY,
  TEST_ID_USER_INDEX_LEGEND_EMAIL,
  TEST_ID_USER_INDEX_LEGEND_FULLNAME,
  TEST_ID_USER_INDEX_LEGEND_ORGANIZATION,
  TEST_ID_USER_INDEX_LEGEND_ROLES,
  TEST_ID_USERS_LIST,
  TEST_ID_USERS_NEW_USER_BUTTON,
} from 'tests/e2e/test_ids'
import {
  ROLE_READ,
  USER_ACTIVATE,
  USER_COMPANY_READ,
  USER_READ,
  USER_CREATE,
  USER_IMPORT,
} from 'constants/permissions'
import useBreakpoints from 'services/hooks/use_breakpoints'
import ModalUserActivation from 'components/modal_user_activation'
import { User } from 'views/users/types'
import { isPresent } from 'services/helpers/values'
import ModalNewUser from 'components/modal_new_user'
import ModalSettings from 'components/modal_settings'
import useModal from 'components/modal/hooks/use_modal'

const Users: FC = () => {
  const dispatch = useAppDispatch()
  const { isLowerThanDesktop } = useBreakpoints()
  const { users, status, totalCount } = useUsers()
  const userCan = useUserCan()
  const { t } = useTranslation()
  const { setOpen: setOpenImportUsers } = useModal('importUsers')
  const { setOpen: setOpenNewUser } = useModal('newUser')
  const { setOpen: setOpenUserActivation } = useModal('userActivation')
  const { setOpen: setOpenUserIndexSettings } = useModal('userIndexSettings')
  const { page, next, reset } = usePagination()
  const [search, setSearch] = useState<string>('')
  const [organizationIdFilter, setOrganizationIdFilter] = useState<number | undefined>()
  const [userSelected, setUserSelected] = useState<User>()
  const theme = useTheme()

  useEffect(() => {
    reset()
  }, [reset, search, organizationIdFilter])

  useEffect(() => {
    dispatch(fetchUsers({ page, filtered: search, organizationId: organizationIdFilter }))
  }, [dispatch, page, search, organizationIdFilter])

  return (
    <PS.ScrollbarPage
      sidebar={<Sidebar setSearch={setSearch} setOrganizationIdFilter={setOrganizationIdFilter} />}
      as={Page}
    >
      {isPresent(userSelected) && (
        <>
          <ModalUserActivation
            id={userSelected.id}
            email={userSelected.email}
            hasSso={userSelected.hasSso}
            active={userSelected.active}
          />
          {isPresent(userSelected.settings) && (
            <ModalSettings
              modalName='userIndexSettings'
              key={`user-settings-${userSelected.id}`}
              user={userSelected}
              settings={userSelected.settings}
              onClose={() => setUserSelected(undefined)}
              onSave={() => {
                dispatch(
                  fetchUsers({ page, filtered: search, organizationId: organizationIdFilter })
                )
              }}
            />
          )}
        </>
      )}
      <ModalNewUser
        onClose={() =>
          dispatch(fetchUsers({ page, filtered: search, organizationId: organizationIdFilter }))
        }
      />

      <S.Content>
        <S.Header>
          <PS.PageHeader>
            <PS.PageTitle>
              {t('users.title')}{' '}
              {status.fulfilled && (
                <PS.PageSubtitle>
                  {t('users.count', {
                    count:
                      totalCount < page * DEFAULT_USERS_PER_PAGE
                        ? totalCount
                        : page * DEFAULT_USERS_PER_PAGE,
                    total: totalCount,
                  })}
                </PS.PageSubtitle>
              )}
            </PS.PageTitle>
            <PageActions>
              {userCan(USER_IMPORT) && (
                <Button
                  icon='upload'
                  rounded={isLowerThanDesktop}
                  variant='default'
                  text={isLowerThanDesktop ? null : t('users.actions.importUsers')}
                  onClick={() => {
                    setOpenImportUsers(true)
                  }}
                />
              )}
              {userCan(USER_CREATE) && (
                <Button
                  icon='plus_outline'
                  rounded={isLowerThanDesktop}
                  variant='highlight'
                  text={isLowerThanDesktop ? null : t('users.actions.newUser')}
                  onClick={() => setOpenNewUser(true)}
                  testId={TEST_ID_USERS_NEW_USER_BUTTON}
                />
              )}
            </PageActions>
          </PS.PageHeader>
          <S.Legend>
            <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_FULLNAME}>
              {t('users.legend.name')}
            </S.LegendItem>
            <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_EMAIL}>
              {t('users.legend.email')}
            </S.LegendItem>
            {userCan(USER_READ) && (
              <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_ORGANIZATION}>
                {t('users.legend.organization')}
              </S.LegendItem>
            )}
            {userCan(USER_COMPANY_READ) && (
              <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_COMPANY}>
                {t('users.legend.company')}
              </S.LegendItem>
            )}
            {userCan(ROLE_READ) && (
              <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_ROLES}>
                {t('users.legend.roles')}
              </S.LegendItem>
            )}
            <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_ACTIVATED} $centered>
              {t('users.legend.activated')}
            </S.LegendItem>
            <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_ACTIONS} $small $centered>
              {t('users.legend.actions')}
            </S.LegendItem>
          </S.Legend>
          {status.fulfilled && totalCount === 0 ? (
            <NotifMessage type='info' text={t('users.noUsers')} />
          ) : null}
        </S.Header>
        <S.Users data-testid={TEST_ID_USERS_LIST}>
          {users.map((user, index) => {
            const userRoles = user.roles?.map((r) => r[0]).join(', ')
            return (
              <S.User key={`user-${user.id}`}>
                <S.Column key={`user-name-${user.id}`}>
                  <HoverTooltip
                    delayed
                    ellipsis
                    content={`${t('users.legend.name')} - ${user.firstName} ${user.lastName}${
                      userCan(USER_READ) ? ` (${user.id})` : ''
                    }
                    `}
                    testId={getTestIdForUserFullname(index)}
                  >
                    {`${user.firstName} ${user.lastName}${
                      userCan(USER_READ) ? ` (${user.id})` : ''
                    }`}
                  </HoverTooltip>
                </S.Column>
                <S.Column key={`user-email-${user.id}`}>
                  <HoverTooltip
                    delayed
                    ellipsis
                    content={`${t('users.legend.email')} - ${user.email}`}
                    testId={getTestIdForUserEmail(index)}
                  >
                    {user.email}
                  </HoverTooltip>
                </S.Column>
                {userCan(USER_READ) && (
                  <S.Column key={`user-organization-${user.id}`}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <HoverTooltip
                        delayed
                        ellipsis
                        content={`${t('users.legend.organization')} - ${user.organizationName} (${
                          user.organizationId
                        })`}
                        testId={getTestIdForUserOrganization(index)}
                      >
                        {user.organizationName} ({user.organizationId})
                      </HoverTooltip>
                      <HoverTooltip
                        // TODO: Refactor using IconTooltip component
                        delayed
                        content={user.hasSso ? t('users.sso.enabled') : t('users.sso.disabled')}
                      >
                        <Icon
                          name='key'
                          fill={user.hasSso ? theme.primary : 'transparent'}
                          stroke={user.hasSso ? theme.primary : theme.mediumGray}
                          width={15}
                          height={15}
                        />
                      </HoverTooltip>
                    </div>
                  </S.Column>
                )}
                {userCan(USER_COMPANY_READ) && (
                  <S.Column key={`user-company-${user.id}`}>
                    {user.companyName && (
                      <HoverTooltip
                        delayed
                        ellipsis
                        content={`${t('users.legend.company')} - ${user.companyName} (${
                          user.companyId
                        })`}
                        testId={getTestIdForUserCompany(index)}
                      >
                        {user.companyName} ({user.companyId})
                      </HoverTooltip>
                    )}
                  </S.Column>
                )}
                {userCan(ROLE_READ) && (
                  <S.Column key={`user-roles-${user.id}`}>
                    <HoverTooltip
                      delayed
                      ellipsis
                      content={`${t('users.legend.roles')} - ${userRoles}`}
                      testId={getTestIdForUserRoles(index)}
                    >
                      {userRoles}
                    </HoverTooltip>
                  </S.Column>
                )}
                <S.Column key={`user-activation-${user.id}`} $centered>
                  <SwitchInput
                    disabled={!userCan(USER_ACTIVATE)}
                    leftLabel='OFF'
                    rightLabel='ON'
                    name='activation'
                    rightOptionChecked={user.active}
                    onClick={() => {
                      setUserSelected(user)
                      setOpenUserActivation(true)
                    }}
                    variant='outline'
                    testId={getTestIdForUserActivated(index)}
                  />
                </S.Column>
                <S.Column key={`user-actions-${user.id}`} $centered $small>
                  <Button
                    variant='smallIcon'
                    icon='cog'
                    testId={getTestIdForUserSettingsButton(index)}
                    onClick={() => {
                      dispatch(fetchUserSettings({ id: user.id }))
                        .unwrap()
                        .then(({ settings }) => {
                          setUserSelected({ ...user, settings })
                          setOpenUserIndexSettings(true)
                        })
                        .catch(() => {
                          dispatch(
                            addNotification({
                              type: 'alert',
                              title: t('errors.notification.title'),
                              text: t('errors.notification.content'),
                            })
                          )
                        })
                    }}
                  />
                </S.Column>
              </S.User>
            )
          })}
          {status.pending && (
            <li>
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
            </li>
          )}
          {status.rejected && <ErrorNotification />}
          {status.fulfilled &&
          totalCount &&
          users &&
          users.length > 0 &&
          users.length < totalCount ? (
            <li>
              <Button text={t('actions.loadMore')} onClick={() => next()} />
            </li>
          ) : null}
        </S.Users>
      </S.Content>
    </PS.ScrollbarPage>
  )
}

export default memo(Users)
