import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

import Icon from 'components/icon'
import Select, { OnChangeFnProps } from 'components/select'

import { DIRECTION_ASCENDING, DIRECTION_DESCENDING } from 'views/shipments/slice'
import S from 'views/trusted_routes/components/header/style'
import {
  RATE_VALUES,
  saveDirection,
  saveDisplayRateType,
  saveSortBy,
  selectActiveQueryParams,
  selectDirection,
  selectDisplayRateType,
  selectSortBy,
  SORT_BY_RATE,
  SORT_BY_VALUES,
} from 'views/trusted_routes/slice'
import FiltersContext from 'views/trusted_routes/contexts/filters'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import Button from 'components/button'
import useBreakpoints from 'services/hooks/use_breakpoints'

interface HeaderProps {
  totalCount: number
}

const Header: React.FC<HeaderProps> = ({ totalCount }) => {
  const { t } = useTranslation()
  const direction = useSelector(selectDirection)
  const sortBy = useSelector(selectSortBy)
  const displayRateType = useSelector(selectDisplayRateType)
  const activeQueryParams = useSelector(selectActiveQueryParams)
  const dispatch = useAppDispatch()
  const RATE_OPTIONS = RATE_VALUES.map((rateValue) => ({
    value: rateValue,
    label: t(`trustedRoutes.rates.${rateValue}`),
  }))

  const SORT_BY_OPTIONS = SORT_BY_VALUES.filter(
    (sortByValue) => activeQueryParams.withRates || sortByValue !== SORT_BY_RATE
  ).map((sortByValue) => ({
    value: sortByValue,
    label: t(`trustedRoutes.sort.${sortByValue}`),
  }))

  const { visible, setVisible } = useContext(FiltersContext)
  const toggleSidebar = useCallback(() => {
    setVisible(!visible)
  }, [visible, setVisible])

  const { isDesktopLarge } = useBreakpoints()

  return (
    <S.Container>
      <S.ContainerLeftBlock>
        {!isDesktopLarge && <Button onClick={toggleSidebar} icon='settings' rounded />}
        <S.Text>{t('trustedRoutes.routeCounter', { count: totalCount })}</S.Text>
      </S.ContainerLeftBlock>
      <S.Order>
        {activeQueryParams.withRates ? (
          <S.Select
            as={Select}
            label={`${t('trustedRoutes.rates.rate')} :`}
            variant='text-control'
            options={RATE_OPTIONS}
            onChange={({ value }: OnChangeFnProps) => {
              dispatch(saveDisplayRateType(value.value))
            }}
            value={RATE_OPTIONS.find((s) => s.value === displayRateType)}
            isClearable={false}
            isSearchable={false}
          />
        ) : (
          <></>
        )}

        <S.Select
          as={Select}
          label={`${t('actions.sortBy')} :`}
          variant='text-control'
          options={SORT_BY_OPTIONS}
          onChange={({ value }: OnChangeFnProps) => {
            dispatch(saveSortBy(value.value))
          }}
          value={SORT_BY_OPTIONS.find((s) => s.value === sortBy)}
          isClearable={false}
          isSearchable={false}
        />
        <S.Direction
          type='button'
          onClick={() => {
            dispatch(
              saveDirection(
                direction === DIRECTION_ASCENDING ? DIRECTION_DESCENDING : DIRECTION_ASCENDING
              )
            )
          }}
        >
          <Icon name={direction === DIRECTION_ASCENDING ? 'sort_amount_down' : 'sort_amount_up'} />
        </S.Direction>
      </S.Order>
    </S.Container>
  )
}

export default Header
