import { HubType, PortType, AirportType, CityType, RailStationType } from 'views/atlas/types/hub'
import { readonlyArrayIncludes } from 'services/helpers/values'

export const allowLocodeFields = (hubType?: HubType | string | null): boolean =>
  readonlyArrayIncludes([PortType, AirportType, RailStationType, CityType], hubType)

export const expectLocodeFields = (hubType?: HubType | string | null): boolean =>
  readonlyArrayIncludes([PortType, AirportType, RailStationType], hubType)

export const allowAirportFields = (hubType?: HubType | string | null): boolean =>
  hubType === AirportType

export const allowPostalCodeField = (hubType?: HubType | string | null): boolean =>
  hubType === CityType
