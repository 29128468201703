import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'

import { STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED } from 'constants/api'
import { InternalClient } from 'services/api/clients'

import onError from 'services/api/error'
import useFormData from 'services/api/hooks/use_form_data'
import useUploadProgress from 'services/api/hooks/use_upload_progress'
import { toSnakeCase } from 'services/helpers/values'

import type { RootState } from 'services/store/store'

export const fetchBookingDocuments = createAsyncThunk(
  'booking/fetchBookingDocuments',
  async (token, thunkAPI) =>
    InternalClient.get(`/bookings/${token}/documents`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const deleteDocument = createAsyncThunk(
  'booking/deleteDocument',
  async ({ token, id }: { token: string; id: string }, thunkAPI) =>
    InternalClient.delete(`/bookings/${token}/documents/${id}`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const uploadDocument = createAsyncThunk(
  'booking/uploadDocument',
  async (
    {
      token,
      documentType,
      document,
      documentId,
      withEmailNotification,
    }: {
      token: string
      documentType: string
      document: File
      documentId: string
      withEmailNotification: boolean
    },
    thunkAPI
  ) => {
    const formData = useFormData({
      documentType: toSnakeCase(documentType),
      file: document,
      withEmailNotification,
    })
    const { getProgress } = useUploadProgress()
    const { dispatch } = thunkAPI

    return InternalClient.post(`/bookings/${token}/documents`, formData, {
      onUploadProgress: (e) => {
        dispatch(addDocumentUploadProgress({ id: documentId, progress: getProgress(e) }))
      },
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

const documentsAdapter = createEntityAdapter()

interface DocumentsState {
  status: string
  documentUploadProgresses: Record<string, boolean>
}

const initialState = documentsAdapter.getInitialState({
  status: STATUS_PENDING,
  documentUploadProgresses: {},
} as DocumentsState)

const bookingDocumentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    addDocumentUploadProgress: (state, action) => {
      state.documentUploadProgresses[action.payload.id] = action.payload.progress
    },
    resetDocumentUploadProgresses: (state) => {
      state.documentUploadProgresses = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBookingDocuments.fulfilled, (state, action) => {
      documentsAdapter.setAll(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchBookingDocuments.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchBookingDocuments.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(deleteDocument.fulfilled, (state, action) => {
      documentsAdapter.removeOne(state, action.meta.arg.id)
    })
    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      documentsAdapter.addOne(state, action.payload)
    })
  },
})

export const { addDocumentUploadProgress, resetDocumentUploadProgresses } =
  bookingDocumentsSlice.actions

export const { selectAll: selectDocuments } = documentsAdapter.getSelectors(
  (state: RootState) => state.booking.documents
)

export const selectBookingDocumentsStatus = (state: RootState) => state.booking.documents.status

export const selectDocumentUploadProgresses = (state: RootState) =>
  state.booking.documents.documentUploadProgresses

export default bookingDocumentsSlice.reducer
