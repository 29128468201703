export const ORDER_DOCUMENT_READ = 'order.document.read'
export const ORDER_USERS_READ = 'order.users.read'
export const ORDER_ADMIN = 'order.admin'
export const DOCUMENT_DELETE = 'document.delete'
export const DOCUMENT_CREATE = 'document.create'
export const ORDER_ORGANIZATION_READ = 'order.organization.read'
export const SHIPMENT_PARTIES_READ = 'shipment.parties.read'
export const ORDER_EXPORT = 'order.export'
export const NETWORK_COMPANIES_READ = 'network.companies.read'
export const ANALYTICS_ACCESS = 'analytics.read'
export const TRUSTED_ROUTES_ACCESS = 'trusted_routes.access'
export const ORDER_UPDATE = 'order.update'
export const ORDER_UPDATE_BOOKING_NUMBER = 'order.update.booking_number'
export const ORDER_UPDATE_BOOKING_REFERENCES = 'order.update.booking_references'
export const ORDER_UPDATE_REF_FORWARDER = 'order.update.ref_forwarder'
export const ORDER_UPDATE_CLIENT_REFERENCE = 'order.update.client_reference'
export const ORDER_UPDATE_SHIPMENT_REFERENCE = 'order.update.shipment_reference'
export const SEGMENT_TIMELINE_UPDATE = 'segment.timeline.update'
export const SEGMENT_TIMELINE_MILESTONES_UPDATE = 'segment.timeline.milestones.update'
export const ORDER_LINKS_READ = 'order.links.read'
export const ORGANIZATION_READ = 'organization.read'
export const EVENT_UPDATE = 'event.update'
export const USER_ORGANIZATION_READ = 'user.organization.read'
export const FLAG_REASON_READ = 'flag_reason.read'
export const SEA_TRACKER_READ = 'sea_tracker.read'
export const ORDER_READ = 'order.read'
export const USER_READ = 'user.read'
export const USER_ACTIVATE = 'user.activate'
export const ROLE_READ = 'role.read'
export const USER_COMPANY_READ = 'user.company.read'
export const COMPANY_CREATE = 'company.create'
export const COMPANY_CLIENT_CREATE = 'company.client.create'
export const COMPANY_ORGANIZATION_CREATE = 'company.organization.create'
export const COMPANY_CLIENT_UPDATE = 'company.client.update'
export const COMPANY_UPDATE = 'company.update'
export const COMPANY_ORGANIZATION_UPDATE = 'company.organization.update'
export const USER_CREATE = 'user.create'
export const USER_IMPORT = 'user.import'
export const USER_UPDATE = 'user.update'
export const AUDIT_TRAIL_READ = 'audit_trail.read'
export const CONNECTIVITY_TRACKING_MANAGE = 'connectivity.tracking.manage'
export const ORDER_DELETE = 'order.delete'
export const ORDER_FLAG = 'order.flag'
export const ORDER_PATH_EDIT = 'order.path.edit'
export const ORDER_UPDATE_PRODUCT_INFORMATION = 'order.update.product_information'

export const PERMISSIONS = [
  ORDER_DOCUMENT_READ,
  ORDER_USERS_READ,
  ORDER_ADMIN,
  DOCUMENT_DELETE,
  DOCUMENT_CREATE,
  ORDER_ORGANIZATION_READ,
  SHIPMENT_PARTIES_READ,
  ORDER_EXPORT,
  NETWORK_COMPANIES_READ,
  ANALYTICS_ACCESS,
  ORDER_UPDATE,
  SEGMENT_TIMELINE_UPDATE,
  SEGMENT_TIMELINE_MILESTONES_UPDATE,
  ORDER_LINKS_READ,
  ORGANIZATION_READ,
  EVENT_UPDATE,
  USER_ORGANIZATION_READ,
  FLAG_REASON_READ,
  ORDER_UPDATE_BOOKING_NUMBER,
  SEA_TRACKER_READ,
  ORDER_READ,
  USER_ACTIVATE,
  ROLE_READ,
  USER_COMPANY_READ,
  USER_READ,
  USER_CREATE,
  USER_IMPORT,
  USER_UPDATE,
  AUDIT_TRAIL_READ,
  CONNECTIVITY_TRACKING_MANAGE,
  COMPANY_CREATE,
  COMPANY_CLIENT_CREATE,
  COMPANY_ORGANIZATION_CREATE,
  COMPANY_CLIENT_UPDATE,
  COMPANY_UPDATE,
  COMPANY_ORGANIZATION_UPDATE,
  ORDER_DELETE,
  ORDER_FLAG,
  ORDER_PATH_EDIT,
  ORDER_UPDATE_REF_FORWARDER,
  ORDER_UPDATE_PRODUCT_INFORMATION,
  ORDER_UPDATE_BOOKING_REFERENCES,
  ORDER_UPDATE_CLIENT_REFERENCE,
  ORDER_UPDATE_SHIPMENT_REFERENCE,
] as const
