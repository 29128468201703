import React from 'react'
import PropTypes from 'prop-types'

import Tag from 'components/tag'
import useDiffTime from 'services/hooks/use_diff_time'
import { isPresent } from 'services/helpers/values'

const TagDiffTime = ({ time, timeTo, highlighted, className, testId }) => {
  const { diff, isNegative } = useDiffTime(time, timeTo)

  const tagColor = isNegative ? 'success' : 'warn'

  return isPresent(diff) ? (
    <Tag
      testId={testId}
      text={diff}
      color={tagColor}
      size='large'
      highlighted={highlighted}
      className={className}
    />
  ) : null
}

TagDiffTime.propTypes = {
  /** Dates */
  time: PropTypes.string.isRequired,
  /** Diff date */
  timeTo: PropTypes.string.isRequired,
  /** Highlighted */
  highlighted: PropTypes.bool,
  /** ClassName */
  className: PropTypes.string,
  testId: PropTypes.string,
}

TagDiffTime.defaultProps = {
  className: undefined,
  highlighted: false,
  testId: undefined,
}

export default TagDiffTime
