import useCurrentOrganization from 'views/iam/hooks/use_current_organization'

const useOrganizationCan = () => {
  const organization = useCurrentOrganization()
  const display = (name) =>
    organization.displaySettings ? organization.displaySettings[name] : false
  const features = (name) => (organization.features ? organization.features[name] : false)
  return {
    display,
    features,
  }
}

export default useOrganizationCan
