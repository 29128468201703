import styled from 'styled-components'

import { easeQuartOut } from 'styles/utils/ease'
import StyledIcon from 'components/icon/style'
import { TrustedRoutesSidebarWidth } from 'views/trusted_routes/components/filters/style'
import { TrustedRoutesGap } from 'views/trusted_routes/style'
import { text16Regular } from 'styles/utils/texts'
import { desktopLarge } from 'styles/utils/breakpoints'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(${TrustedRoutesSidebarWidth}px + ${TrustedRoutesGap}px);
  @media ${desktopLarge.down} {
    margin-left: 0;
  }
`

const ContainerLeftBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const Text = styled.p`
  ${text16Regular};
`

const Order = styled.div`
  display: flex;
  align-items: center;
`

const Select = styled.div`
  display: flex;
  align-items: center;
`

const Direction = styled.button`
  margin-right: 12px;
  ${StyledIcon} {
    height: 23px;
    width: 25px;
    fill: ${({ theme }) => theme.mediumGray};
    transition: fill 0.5s ${easeQuartOut};
  }

  &:hover {
    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }
`

const S = {
  Select,
  Direction,
  Container,
  ContainerLeftBlock,
  Order,
  Text,
}

export default S
