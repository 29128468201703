import React, { FC, useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { v4 as uuid } from 'uuid'

import { useDispatch } from 'react-redux'

import { addNotification } from 'views/notifications/slice'

import {
  StyledBlockItemContentWrapper,
  StyledGridReferences,
  StyledInput,
} from 'views/booking/components/form/style'
import {
  fetchAirlinesOptions,
  fetchShippingLinesOptions,
  fetchVesselsOptions,
} from 'views/select_options/slice'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

import { TRANSPORT_TYPE_AIR, TRANSPORT_TYPE_SEA } from 'constants/shipments'

import Grid from 'components/grid'
import Form from 'components/form'
import Input from 'components/input'
import InputTags from 'components/input_tags'
import Select from 'components/select'
import SearchSelect from 'components/search_select'
import SearchMultiSelect from 'components/search_multi_select'
import BlockContainer, { BlockContext } from 'components/block_container'

import {
  getTestIdForShipment,
  TEST_ID_BOOKING_FORM_ADD_SHIPMENT,
  TEST_ID_BOOKING_FORM_RATE_BOOKING_NUMBER,
  TEST_ID_BOOKING_FORM_SHIPMENTS_BLOCK,
} from 'tests/e2e/test_ids'
import { BOOKING_FIELD_VALIDATIONS, trackingRefFormatExampleMap } from 'constants/bookings'

import { ShipmentAttribute } from 'views/booking/slices/types'

import useBookingRole from 'views/booking/hooks/use_booking_role'

import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const TransportDetails: FC<BookingFormProps> = ({ filters, isCreate }) => {
  const {
    carrierFilter,
    bookingNumberFilter,
    transportTypeFilter,
    masterBlFilter,
    vesselsFilter,
    voyageNumbersFilter,
    flightNumbersFilter,
    shipmentAttributesFilter,
  } = filters

  const dispatch = useDispatch()
  const { isShipper } = useBookingRole()
  const { isForwarder } = useBookingRole()
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const numberLabelMap = {
    [TRANSPORT_TYPE_SEA]: 'Container number',
    [TRANSPORT_TYPE_AIR]: 'AWB number (M)',
  }
  const numberFieldMap = {
    [TRANSPORT_TYPE_SEA]: 'containerNumber',
    [TRANSPORT_TYPE_AIR]: 'masterAwb',
  }
  const isCurrentTransportTypeSea = useMemo(
    () => transportTypeFilter.value === TRANSPORT_TYPE_SEA,
    [transportTypeFilter.value]
  )

  const canHaveShipmentsAttributes = (
    transportType: string | null
  ): transportType is typeof TRANSPORT_TYPE_SEA | typeof TRANSPORT_TYPE_AIR =>
    transportType === TRANSPORT_TYPE_SEA || transportType === TRANSPORT_TYPE_AIR

  useEffect(() => {
    shipmentAttributesFilter.onChange((state: ShipmentAttribute[]) => {
      state.forEach((_, index) => {
        if (transportTypeFilter.value === TRANSPORT_TYPE_AIR) {
          delete state[index].containerNumber
        } else if (transportTypeFilter.value === TRANSPORT_TYPE_SEA) {
          delete state[index].masterAwb
        }
      })
    })
  }, [shipmentAttributesFilter, transportTypeFilter.value])

  const carrierInput = (
    <StyledInput
      as={SearchSelect}
      label={t('bookings.transportDetails.carrier')}
      action={
        transportTypeFilter.value === TRANSPORT_TYPE_SEA
          ? fetchShippingLinesOptions
          : fetchAirlinesOptions
      }
      creatable
      name={carrierFilter.name}
      formatCreateLabel={(inputValue: string) => {
        const label =
          transportTypeFilter.value === TRANSPORT_TYPE_SEA
            ? t('bookings.select.options.createUnknownScac')
            : t('bookings.select.options.createUnknownAwbPrefix')
        return `${label} "${inputValue}"`
      }}
      onCreateOption={(newOption: string) =>
        carrierFilter.onChange({
          name: carrierFilter.name,
          value: {
            value: -1,
            label: `${t('bookings.select.options.unknownCarrier')} (${newOption})`,
            createValue: newOption,
          },
        })
      }
      onChange={carrierFilter.onChange}
      value={carrierFilter.value}
      isDisabled={carrierFilter.isDisabled}
    />
  )

  const bookingNumberInput = (
    <StyledInput
      as={Input}
      label={t('bookings.transportDetails.bookingNumber')}
      placeholder={t('bookings.reference')}
      name={bookingNumberFilter.name}
      value={bookingNumberFilter.value}
      onChange={bookingNumberFilter.onChange}
      disabled={bookingNumberFilter.isDisabled}
      testId={TEST_ID_BOOKING_FORM_RATE_BOOKING_NUMBER}
    />
  )

  const flightNumbersInput = (
    <StyledInput
      as={InputTags}
      label={t('bookings.transportDetails.flightNumbers')}
      name={flightNumbersFilter.name}
      value={flightNumbersFilter.value}
      onChange={flightNumbersFilter.onChange}
      disabled={flightNumbersFilter.isDisabled}
    />
  )

  const masterBlInput = (
    <StyledInput
      as={Input}
      label={t('bookings.transportDetails.blNumber')}
      placeholder={t('bookings.reference')}
      name={masterBlFilter.name}
      value={masterBlFilter.value}
      onChange={masterBlFilter.onChange}
      disabled={masterBlFilter.isDisabled}
    />
  )

  const vesselsInput = (
    <StyledInput
      as={SearchMultiSelect}
      label={t('bookings.transportDetails.vessels')}
      name={vesselsFilter.name}
      value={vesselsFilter.value}
      formatCreateLabel={(inputValue: string) =>
        `${t('bookings.select.options.createUnknownImoNumber')} "${inputValue}"`
      }
      onCreateOption={(newOption: string) => {
        if (!Number.isInteger(parseInt(newOption, 10))) {
          dispatch(
            addNotification({
              type: 'alert',
              title: t('bookings.alerts.invalidImoNumber.title'),
              text: t('bookings.alerts.invalidImoNumber.message'),
            })
          )
          return
        }

        vesselsFilter.onChange({
          name: vesselsFilter.name,
          value: (vesselsFilter.value ?? []).concat({
            value: uuid(),
            createValue: newOption,
            label: `${t('bookings.select.options.unknownVessel')} (${newOption})`,
          }),
        })
      }}
      onChange={vesselsFilter.onChange}
      action={fetchVesselsOptions}
      isMulti
      creatable
      isDisabled={vesselsFilter.isDisabled}
    />
  )

  const voyageNumbersInput = (
    <StyledInput
      as={InputTags}
      label={t('bookings.transportDetails.voyageNumbers')}
      name={voyageNumbersFilter.name}
      value={voyageNumbersFilter.value}
      onChange={voyageNumbersFilter.onChange}
      disabled={voyageNumbersFilter.isDisabled}
    />
  )

  return (
    <Form.Group title={t('bookings.transportDetails.title')}>
      {isShipper && isCreate ? (
        <StyledGridReferences as={Grid} columns={3}>
          <Grid.Row>
            <Grid.Column>{carrierInput}</Grid.Column>
            {transportTypeFilter.value === TRANSPORT_TYPE_SEA && (
              <Grid.Column>{vesselsInput}</Grid.Column>
            )}
            {transportTypeFilter.value === TRANSPORT_TYPE_SEA && (
              <Grid.Column>{voyageNumbersInput}</Grid.Column>
            )}
          </Grid.Row>
        </StyledGridReferences>
      ) : (
        <StyledGridReferences as={Grid} columns={3}>
          <Grid.Row>
            <Grid.Column>{carrierInput}</Grid.Column>
            {transportTypeFilter.value === TRANSPORT_TYPE_SEA && (
              <Grid.Column>{bookingNumberInput}</Grid.Column>
            )}
            {transportTypeFilter.value === TRANSPORT_TYPE_AIR && (
              <Grid.Column>{flightNumbersInput}</Grid.Column>
            )}
          </Grid.Row>
          {transportTypeFilter.value === TRANSPORT_TYPE_SEA && (
            <Grid.Row>
              <Grid.Column>{masterBlInput}</Grid.Column>
              <Grid.Column>{vesselsInput}</Grid.Column>
              <Grid.Column>{voyageNumbersInput}</Grid.Column>
            </Grid.Row>
          )}
          {isForwarder && (
            <BlockContainer
              title={t('bookings.transportDetails.shipment')}
              columns={2}
              size={1}
              btnSize={1}
              initialBlocksCount={shipmentAttributesFilter.value.length || 0}
              onRemove={(index) => {
                shipmentAttributesFilter.onChange((state: ShipmentAttribute[]) => {
                  state.splice(index, 1)
                })
              }}
              onAdd={(index) => {
                shipmentAttributesFilter.onChange((state: any) => {
                  state[index] = {}
                })
              }}
              disabled={shipmentAttributesFilter.isDisabled}
              testId={TEST_ID_BOOKING_FORM_SHIPMENTS_BLOCK}
              addBlockTestId={TEST_ID_BOOKING_FORM_ADD_SHIPMENT}
            >
              <BlockContext.Consumer>
                {({ index }) => (
                  <StyledBlockItemContentWrapper data-testid={getTestIdForShipment(index)}>
                    {canHaveShipmentsAttributes(transportTypeFilter.value) && (
                      <Grid columns={2} columnsTablet={2}>
                        <Grid.Row>
                          <Grid.Column>
                            <Input
                              label={numberLabelMap[transportTypeFilter.value]}
                              name={`shipment-${index}-trackingRef`}
                              placeholder={trackingRefFormatExampleMap[transportTypeFilter.value]}
                              error={shipmentAttributesFilter.error[index]?.trackingRef}
                              value={
                                shipmentAttributesFilter.value[index]?.[
                                  numberFieldMap[transportTypeFilter.value]
                                ]
                              }
                              onChange={({ target: { value } }) => {
                                shipmentAttributesFilter.onChange((state: any) => {
                                  if (canHaveShipmentsAttributes(transportTypeFilter.value)) {
                                    if (!state[index]) state[index] = {}
                                    state[index][numberFieldMap[transportTypeFilter.value]] = value
                                  }
                                })
                              }}
                              disabled={shipmentAttributesFilter.isDisabled}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Input
                              label='Shipment reference'
                              name={`shipment-${index}-reference`}
                              placeholder='Reference'
                              value={shipmentAttributesFilter.value[index]?.shipmentReference}
                              onChange={({ target: { value } }) => {
                                shipmentAttributesFilter.onChange((state: any) => {
                                  if (!state[index]) state[index] = {}
                                  state[index].shipmentReference = value
                                })
                              }}
                              disabled={shipmentAttributesFilter.isDisabled}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Input
                              label='Weight'
                              name={`shipment-${index}-weight`}
                              placeholder='kg'
                              error={shipmentAttributesFilter.error[index]?.weight}
                              value={shipmentAttributesFilter.value[index]?.weight?.value}
                              onChange={({ target: { value } }) => {
                                shipmentAttributesFilter.onChange((state: any) => {
                                  state[index].weight = { value, unit: 'kg' }
                                })
                              }}
                              step={0.01}
                              type='number'
                              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.SHIPMENT_WEIGHT}
                              max={BOOKING_FIELD_VALIDATIONS.MAXIMAL_VALUES.SHIPMENT_WEIGHT}
                              disabled={shipmentAttributesFilter.isDisabled}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            {isCurrentTransportTypeSea && (
                              <Select
                                label='Container type'
                                name={`shipment-${index}-containerType`}
                                options={Object.entries(s('containerTypes')).map(
                                  ([key, value]) => ({
                                    value: key,
                                    label: value,
                                  })
                                )}
                                value={shipmentAttributesFilter.value[index]?.containerType}
                                onChange={({ value }) => {
                                  shipmentAttributesFilter.onChange((state: any) => {
                                    state[index].containerType = value
                                  })
                                }}
                                isDisabled={shipmentAttributesFilter.isDisabled}
                              />
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )}
                  </StyledBlockItemContentWrapper>
                )}
              </BlockContext.Consumer>
            </BlockContainer>
          )}
        </StyledGridReferences>
      )}
    </Form.Group>
  )
}

export default TransportDetails
