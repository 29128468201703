import React from 'react'
import PropTypes from 'prop-types'

import { anyChildren } from 'services/helpers/prop_types'

import Modal from 'components/modal'
import StyledModalHorizontal from 'components/modal_horizontal/style'
import { TEST_ID_MODAL_TITLE } from 'tests/e2e/test_ids'

const ModalHorizontal = ({ title, children, size, ...props }) => (
  // ModalHorizontal and Modal component share the same props
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledModalHorizontal as={Modal} $size={size} ariaHideApp={false} {...props}>
    <Modal.Header testId={TEST_ID_MODAL_TITLE}>{title}</Modal.Header>
    <Modal.Content>{children}</Modal.Content>
  </StyledModalHorizontal>
)

ModalHorizontal.propTypes = {
  size: PropTypes.oneOf(['medium', 'large']),
  title: PropTypes.string,
  children: anyChildren,
}

ModalHorizontal.defaultProps = {
  size: 'medium',
  title: undefined,
  children: undefined,
}
export default ModalHorizontal
