import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'

import {
  StyledModalShipments,
  StyledModalShipmentsPrevNext,
  ModalShipmentsHeadingIcon,
  ModalShipmentsHeadingTitle,
  ModalShipmentsNavPrev,
  ModalShipmentsNavNext,
  ModalShipmentsNavArrow,
} from 'components/modal_shipments/style'
import Modal from 'components/modal'
import Icon from 'components/icon'
import ShipmentListItem from 'components/shipment_list_item'
import ShipmentTransit from 'components/shipment_transit'
import Placeholder from 'components/placeholder'
import ModalShipmentSkeleton from 'components/modal_shipments/skeleton'

import { isPresent } from 'services/helpers/values'
import useShallowSelector from 'services/hooks/use_shallow_selector'
import useTracker from 'services/analytics/hooks/use_tracker'

import {
  STEP_TYPE_DELIVERY,
  STEP_TYPE_PICKUP,
  STEP_TYPE_POD,
  STEP_TYPE_POL,
  SHIPMENT_VIEW_MODAL,
} from 'constants/shipments'
import { selectDashboardMapShipmentsByVehicleId } from 'views/dashboard/slice'

const ModalShipments = ({ position, vehicleId }) => {
  const shipments = useShallowSelector(selectDashboardMapShipmentsByVehicleId({ id: vehicleId }))

  const { t } = useTranslation()
  const { track } = useTracker()
  const onPrev = () => {
    let newIndex = index - 1
    if (newIndex < 0) newIndex = shipments.length - 1
    setIndex(newIndex)
    track('Dashboard / Map', { detail: 'previous' })
  }
  const onNext = () => {
    let newIndex = index + 1
    if (newIndex === shipments.length) newIndex = 0
    setIndex(newIndex)
    track('Dashboard / Map', { detail: 'next' })
  }
  const numShipments = shipments.length
  const isMultipleShipments = numShipments > 1
  const [index, setIndex] = useState(0)
  const currentShipment = useMemo(() => shipments[index], [shipments, index])

  useEffect(() => {
    setIndex(0)
  }, [shipments])

  const { transportType, stepType } = currentShipment ? currentShipment.summary.current : {}
  const nextStepType = useMemo(
    () =>
      currentShipment
        ? {
            [STEP_TYPE_PICKUP]: STEP_TYPE_POL,
            [STEP_TYPE_POL]: STEP_TYPE_POD,
            [STEP_TYPE_POD]: STEP_TYPE_DELIVERY,
          }[stepType]
        : null,
    [stepType, currentShipment]
  )
  const destination = currentShipment?.summary[nextStepType]?.location?.name

  return (
    <StyledModalShipments as={Modal} modalName='dashboardMap' position={position}>
      {isMultipleShipments && <StyledModalShipmentsPrevNext />}
      <Modal.Header>
        <ModalShipmentsHeadingIcon as={Icon} name='shipment' />
        <ModalShipmentsHeadingTitle>
          {isMultipleShipments ? `${index + 1}/${numShipments}` : numShipments}{' '}
          {t('shipments.shipment', { count: numShipments })}
        </ModalShipmentsHeadingTitle>
      </Modal.Header>
      <Modal.Content>
        <Placeholder
          ready={isPresent(currentShipment)}
          customPlaceholder={<ModalShipmentSkeleton />}
        >
          <ShipmentViewContext.Provider value={{ view: SHIPMENT_VIEW_MODAL }}>
            <ShipmentListItem shipment={currentShipment} />
            <ShipmentTransit transportType={transportType} destination={destination} />
          </ShipmentViewContext.Provider>
        </Placeholder>
      </Modal.Content>
      {isMultipleShipments && (
        <Modal.Footer>
          <ModalShipmentsNavPrev onClick={onPrev}>
            <ModalShipmentsNavArrow as={Icon} name='arrow_top' />
            {t('actions.prev')}
          </ModalShipmentsNavPrev>
          <ModalShipmentsNavNext onClick={onNext}>
            {t('actions.next')}
            <ModalShipmentsNavArrow as={Icon} name='arrow_bottom' />
          </ModalShipmentsNavNext>
        </Modal.Footer>
      )}
    </StyledModalShipments>
  )
}

ModalShipments.propTypes = {
  /** Modal x y position on screen */
  position: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
  /** vehicleId of shipments to display inside modal content */
  vehicleId: PropTypes.string,
}

ModalShipments.defaultProps = {
  position: { x: 0, y: 0 },
  vehicleId: undefined,
}

export default ModalShipments
