import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import { isAnyArray, isEmptyArray } from 'services/helpers/values'

import Button from 'components/button'
import Modal from 'components/modal'
import UploadDropzone from 'components/upload_dropzone'
import useUploadFiles from 'components/modal_upload_document/hooks/use_upload_files'
import {
  resetImportStatus,
  selectUsersImportStatus,
  importUsers,
  downloadImportUsersTemplate,
} from 'views/users/slice'
import Icon from 'components/icon'
import {
  StyledErrorMessage,
  StyledImportFilesButtons,
  StyledNotifMessageWrapper,
  StyledImportFileValidate,
  StyledImportFileWarning,
  StyledImportFileWrapper,
  StyledImportFile,
  StyledImportFileIcon,
  StyledImportFileName,
  StyledModalContent,
} from 'views/users/components/import_users_modal/style'
import NotifMessage from 'components/notif_message'
import useStatus from 'services/api/hooks/use_status'
import IconTooltip from 'components/icon_tooltip'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useShallowSelector from 'services/hooks/use_shallow_selector'
import { addNotification } from 'views/notifications/slice'

const ImportUsersModal = () => {
  const dispatch = useAppDispatch()
  const { onDropFiles, files, removeAllFiles } = useUploadFiles()
  const { t } = useTranslation()

  const importStatus = useStatus(useShallowSelector(selectUsersImportStatus))

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [errors, setErrors] = useState([])

  const clear = () => {
    removeAllFiles()
    dispatch(resetImportStatus())
  }

  const uploadFile = () => {
    const file = files[0]
    dispatch(importUsers({ file: file.nativeFile }))
      .unwrap()
      .catch(({ message }) => {
        const errorMessageArray = isAnyArray(message) ? message : [t('errors.notification.content')]
        setErrors(errorMessageArray)
      })
  }

  useEffect(() => {
    const noErrorFiles = files.filter((file) => isEmptyArray(file.errors))
    const isUploadDisabled = isEmptyArray(noErrorFiles)
    setButtonDisabled(isUploadDisabled)
  }, [files])

  return (
    <Modal height='large' modalName='importUsers' onClose={clear} position='center'>
      <Modal.Header>{t('users.actions.importUsers')}</Modal.Header>
      <Modal.Content>
        {isEmptyArray(files) && (
          <StyledModalContent>
            <UploadDropzone
              onDrop={onDropFiles}
              variant='importUsers'
              severalFiles={false}
              accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
            />
            <Button
              text={t('users.actions.downloadTemplate')}
              variant='clear'
              icon='download'
              onClick={() =>
                dispatch(downloadImportUsersTemplate())
                  .unwrap()
                  .catch(() => {
                    dispatch(
                      addNotification({
                        type: 'alert',
                        title: t('errors.notification.title'),
                        text: t('errors.notification.content'),
                      })
                    )
                  })
              }
            />
          </StyledModalContent>
        )}

        {isAnyArray(files) && (
          <aside>
            <StyledImportFileWrapper>
              <StyledImportFile>
                <StyledImportFileIcon as={Icon} name='file' />
                <StyledImportFileName>{files[0].nativeFile.path}</StyledImportFileName>
                {isAnyArray(files[0].errors) ? (
                  <StyledImportFileWarning>
                    <IconTooltip
                      placement='right'
                      size='large'
                      content={files[0].errors.map(({ message }) => message).join(',')}
                      variant='warning'
                    />
                  </StyledImportFileWarning>
                ) : (
                  <StyledImportFileValidate as={Icon} name='check_plain' />
                )}
              </StyledImportFile>
            </StyledImportFileWrapper>

            {importStatus.unstarted && (
              <StyledImportFilesButtons>
                <Button text={t('users.actions.cancel')} variant='clear' onClick={clear} />
                <Button
                  text={t('users.actions.import')}
                  variant='highlight'
                  onClick={() => uploadFile()}
                  disabled={buttonDisabled}
                />
              </StyledImportFilesButtons>
            )}
            {importStatus.fulfilled && (
              <StyledNotifMessageWrapper
                as={NotifMessage}
                type='success'
                title={t('users.notifications.import.success')}
              />
            )}
            {importStatus.pending && (
              <StyledNotifMessageWrapper
                as={NotifMessage}
                type='loading'
                title={t('users.notifications.import.inProgress')}
              />
            )}

            {importStatus.rejected && (
              <StyledNotifMessageWrapper
                as={NotifMessage}
                type='alert'
                title={t('users.notifications.import.failure')}
                text={
                  <ul>
                    {errors.map((error) => (
                      <StyledErrorMessage key={uuid()}>{error}</StyledErrorMessage>
                    ))}
                  </ul>
                }
              />
            )}

            {importStatus.rejected && (
              <StyledImportFilesButtons>
                <Button text={t('users.actions.cancel')} variant='clear' onClick={clear} />
              </StyledImportFilesButtons>
            )}
          </aside>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default ImportUsersModal
