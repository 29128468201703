import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'

import {
  selectShipmentsFromSource,
  selectShipmentsStatus,
  selectShipmentsTotalCount,
} from 'views/shipments/slice'

const useShipments = (source) => {
  const shipments = useSelector(selectShipmentsFromSource(source))
  const status = useStatus(useSelector((state) => selectShipmentsStatus(state)))
  const totalCount = useSelector((state) => selectShipmentsTotalCount(state))
  return [shipments, status, totalCount]
}

export default useShipments
