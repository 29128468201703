import React, { memo, useMemo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { SHIPMENT_VIEW_CONDENSED, TRANSPORT_TYPE_ICONS } from 'constants/shipments'

import {
  StyledShipments,
  StyledDashboardMap,
  StyledShipmentsListContainer,
  StyledShipmentsListHeader,
  StyledShipmentsListTitle,
  StyledPageShipmentListSelect,
  StyledPin,
  StyledLoadMoreButton,
  StyledShipmentsListWrapper,
} from 'views/dashboard/style'
import {
  fetchModalShipmentsByVehicleId,
  fetchOngoingShipments,
  selectOngoingShipments,
  selectOngoingShipmentsStatus,
} from 'views/dashboard/slice'
import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'
import ShipmentList from 'views/shipments/components/list'
import {
  fetchShipments,
  SORT_BY_CREATED_AT,
  SORT_BY_LAST_ETA_CHANGE,
  FILTER_BY_FAVORITE,
} from 'views/shipments/slice'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import CustomKpis from 'views/custom_kpis'
import useShipments from 'views/shipments/hooks/use_shipments'
import { addNotification } from 'views/notifications/slice'

import useOnce from 'services/hooks/use_once'
import useStatus from 'services/api/hooks/use_status'
import useTracker from 'services/analytics/hooks/use_tracker'
import useShallowSelector from 'services/hooks/use_shallow_selector'
import { isPresent } from 'services/helpers/values'
import usePagination from 'services/api/hooks/use_pagination'

import useMap from 'components/map/hooks/use_map'
import { VehicleModel } from 'components/map/models'
import ModalShipments from 'components/modal_shipments'
import Page from 'components/page'
import AboveModal from 'components/above_modal'
import Marker from 'components/marker'
import Select from 'components/select'
import useModal from 'components/modal/hooks/use_modal'
import Button from 'components/button'
import useShipmentColor from 'views/shipments/hooks/use_shipment_color'

const PER = 30
const SOURCE = 'dashboard'

const Dashboard = memo(() => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { page, next } = usePagination()
  const [view, setView] = useState(SHIPMENT_VIEW_CONDENSED)
  const ongoingShipments = useShallowSelector(selectOngoingShipments)
  const status = useStatus(useSelector(selectOngoingShipmentsStatus))
  const { userCoordinate } = useTracker()
  const { setOpen } = useModal('dashboardMap')
  const [modalShipmentsVehicleId, setModalShipmentsVehicleId] = useState()
  const vehicles = useMemo(
    () =>
      ongoingShipments.map(
        ({ id, orders, shipmentsCount }) =>
          new VehicleModel({
            id,
            type: TRANSPORT_TYPE_ICONS[orders[0].transportType],
            lng: orders[0].lng,
            lat: orders[0].lat,
            shipmentsCount,
            color: orders[0].color,
          })
      ),
    [ongoingShipments]
  )

  const [modalCoords, setModalCoords] = useState({})
  const [markerType, setMarkerType] = useState()
  const [markerLabel, setMarkerLabel] = useState()
  const [shipmentColor, setShipmentColor] = useState(0)

  const { track } = useTracker()

  const { getColorFromShipmentColor } = useShipmentColor()

  const onVehicleClick = useCallback(
    ({ x, y, label, type, color, id }) => {
      dispatch(
        fetchModalShipmentsByVehicleId({
          vehicleId: id,
        })
      )
        .unwrap()
        .then(() => {
          setModalCoords({ x, y })
          setMarkerType(type)
          setMarkerLabel(label)
          setShipmentColor(color)
          setModalShipmentsVehicleId(id)
          setOpen(true)
          track('Dashboard / Map', { detail: 'pin' })
        })
    },
    [setOpen, track, dispatch]
  )

  const onClusterClick = useCallback(() => {
    track('Dashboard / Map', { detail: 'cluster' })
  }, [track])

  const onToggleFullscreen = (isFullscreen) => {
    if (isFullscreen) track('Dashboard / Map', { detail: 'enlarge' })
  }

  const { Map, mapProps, loaded, clusterizeVehicles, isFullscreen, setCenter } = useMap({
    bounds: vehicles.map((v) => v.coordinate),
    onVehicleClick,
    onClusterClick,
    onToggleFullscreen,
  })

  const { opened } = useModal('dashboardMap')

  const [dashboardShipments, dashbordShipmentsStatus, dashbordShipmentsTotalCount] =
    useShipments(SOURCE)

  useOnce(() => dispatch(fetchOngoingShipments()))

  useEffect(() => {
    if (loaded && status.fulfilled) {
      clusterizeVehicles(vehicles)
    }
  }, [loaded, status, vehicles, clusterizeVehicles])

  useEffect(() => {
    if (loaded && status.fulfilled && userCoordinate) {
      setCenter(userCoordinate)
    }
  }, [loaded, status, userCoordinate, setCenter])

  const sortByOptions = useMemo(
    () => [
      {
        sort: SORT_BY_CREATED_AT,
        label: t('shipments.show.lastCreated'),
      },
      {
        sort: SORT_BY_LAST_ETA_CHANGE,
        label: t('shipments.show.lastEtaChange'),
      },
      {
        sort: SORT_BY_CREATED_AT,
        filter: FILTER_BY_FAVORITE,
        label: t('shipments.show.favorite'),
      },
    ],

    // param t from useTranslation is causing unecessary triggers of this hook
    // so we removed it from dependency array
    // eslint-disable-next-line
    []
  )

  const [sortByIndex, setSortByIndex] = useState(0)
  const currentSortBy = sortByOptions[sortByIndex]

  const user = useCurrentUser()

  useEffect(() => {
    const params = {
      sortBy: currentSortBy.sort,
      per: PER,
      source: SOURCE,
      page,
      direction: 'desc',
    }
    if (isPresent(currentSortBy.filter) && currentSortBy.filter === FILTER_BY_FAVORITE) {
      params.favoriteUserId = user.id
    }
    dispatch(fetchShipments(params))
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })

    // param t from useTranslation is causing unecessary triggers of this hook
    // so we removed it from dependency array
    // eslint-disable-next-line
  }, [dispatch, currentSortBy, user.id, page])

  const contextValue = useMemo(() => ({ view, setView }), [view, setView])

  const markerColor = getColorFromShipmentColor(shipmentColor)

  return (
    <Page plain>
      <StyledDashboardMap $fullscreen={isFullscreen}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Map {...mapProps} />
      </StyledDashboardMap>
      <AboveModal>
        {opened && (
          <StyledPin $position={modalCoords}>
            <Marker icon={markerType} label={markerLabel} color={markerColor} />
          </StyledPin>
        )}
      </AboveModal>
      {opened && <ModalShipments position={modalCoords} vehicleId={modalShipmentsVehicleId} />}
      <StyledShipments>
        <StyledShipmentsListContainer>
          <ShipmentViewContext.Provider value={contextValue}>
            <StyledShipmentsListHeader>
              <StyledShipmentsListTitle>{t('dashboard.shipments.title')}</StyledShipmentsListTitle>
              <StyledPageShipmentListSelect
                as={Select}
                noPadding
                label={`${t('actions.show')} :`}
                variant='text-control'
                options={sortByOptions}
                onChange={({ value }) => {
                  const index = sortByOptions.findIndex(
                    (s) => s.sort === value.sort && s.filter === value.filter
                  )
                  setSortByIndex(index)
                  const option = sortByOptions[index]
                  track('Dashboard / List', { detail: 'show', name: option.filter || option.sort })
                }}
                onMenuOpen={() => {
                  track('Dashboard / List', { detail: 'show' })
                }}
                isClearable={false}
                isSearchable={false}
                value={currentSortBy}
              />
            </StyledShipmentsListHeader>
            <StyledShipmentsListWrapper>
              <ShipmentList source={SOURCE} />
              {dashbordShipmentsStatus.fulfilled &&
                dashboardShipments.length > 0 &&
                dashboardShipments.length < dashbordShipmentsTotalCount && (
                  <StyledLoadMoreButton
                    as={Button}
                    text={t('actions.loadMore')}
                    onClick={() => {
                      next()
                      track('Dashboard / List', { detail: 'load' })
                    }}
                  />
                )}
            </StyledShipmentsListWrapper>
          </ShipmentViewContext.Provider>
        </StyledShipmentsListContainer>
        <CustomKpis />
      </StyledShipments>
    </Page>
  )
})

export default Dashboard
