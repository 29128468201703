import { useTranslation } from 'react-i18next'
import React, { useContext, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Button from 'components/button'
import Timeline, { TimelineMilestone, TimelineStep } from 'components/timeline'
import Placeholder from 'components/placeholder'
import ShipmentTimelineSkeleton from 'components/shipment_timeline/skeleton'
import TimelineBookingConfirmationMilestone from 'components/timeline/timeline_booking_confirmation_milestone'
import TimelineBookingConfirmationStep from 'components/timeline/timeline_booking_confirmation_step'
import { StyledUpdateTimeButton } from 'components/timeline/style'
import {
  StyledShipmentTimelineHeader,
  StyledShipmentTimelineInfo,
} from 'components/shipment_timeline/style'

import ShipmentContext from 'views/shipment/context'
import useBookingConfirmation from 'views/shipment/hooks/use_booking_confirmation'
import useTimeline from 'views/shipment/hooks/use_timeline'
import {
  fetchBookingConfirmation,
  fetchTimeline,
  fetchAvailableMilestones,
  fetchSegmentTimelines,
} from 'views/shipment/slice'
import ErrorNotification from 'views/errors/error_notification'

import { isAnyObject, compactArray, uniqArray, isPresent } from 'services/helpers/values'

import {
  STEP_STATUS_CURRENT,
  STEP_STATUS_FUTURE,
  STEP_STATUS_PAST,
  TRANSPORT_TYPE_PARCEL,
} from 'constants/shipments'
import {
  SEGMENT_TIMELINE_MILESTONES_UPDATE,
  EVENT_UPDATE,
  SEGMENT_TIMELINE_UPDATE,
} from 'constants/permissions'

import ShipmentReportedInfos from 'views/shipment/components/reported_info'
import useShipment from 'views/shipment/hooks/use_shipment'
import useModal from 'components/modal/hooks/use_modal'
import DateDeclarationModal from 'views/shipment/components/date_declaration_modal'
import NewMilestoneModal from 'views/shipment/components/new_milestone_modal'
import useUserCan from 'views/iam/hooks/use_user_can'

import { StyledEditTimelineButtonWrapper, StyledMilestonesEditButton } from 'views/shipment/style'
import AppContext from 'app/contexts/app_context'

import ModalTimeline from 'components/modal_timeline'

const ShipmentTimeline = React.memo(() => {
  const dispatch = useDispatch()
  const { id } = useContext(ShipmentContext)
  const { isShared } = useContext(AppContext)
  const { t } = useTranslation()
  const [shipment, , isFlagged] = useShipment({ id })
  const [timeline, status] = useTimeline({ id })
  const [bookingConfirmation, isBookingReady] = useBookingConfirmation({ id })
  const userCan = useUserCan()
  const canEditMilestone = userCan(SEGMENT_TIMELINE_MILESTONES_UPDATE)
  const canAddMilestone = userCan(EVENT_UPDATE)

  // WARNING - Composite Steps will not have an id, hence the selection needs to be reworked (step_type + name ?)
  const currentStepIndex = timeline?.steps?.findIndex(
    (s) => timeline.currentStep.location.id === s.location.id
  )
  const [editMode, setEditMode] = useState(false)

  const [milestoneObject, setMilestoneObject] = useState({})

  const { opened: openedEditMilestone, setOpen: setOpenEditMilestone } = useModal(
    'timelineDateDeclarationModal'
  )
  const { opened: openedTimelineModal, setOpen: setOpenEditTimeline } = useModal('timelineModal')
  const { opened: openedNewMilestone, setOpen: setOpenNewMilestone } = useModal('newMilestoneModal')

  const openEditMilestoneModal = ({
    estimatedTime,
    addressId,
    actualTime,
    milestoneType,
    segmentInfoId,
    sourceMetadata,
    nextDates,
  }) => {
    setMilestoneObject({
      estimatedTime,
      addressId,
      actualTime,
      milestoneType,
      segmentInfoId,
      sourceMetadata,
      nextDates,
    })
    setOpenEditMilestone(true)
  }

  const openNewMilestoneModal = ({ locationId, locationName, locationTypes }) => {
    setMilestoneObject({
      locationId,
      locationName,
      locationTypes,
    })
    setOpenNewMilestone(true)
  }

  useEffect(() => {
    if (isShared) return
    dispatch(fetchTimeline({ id }))
    dispatch(fetchBookingConfirmation({ id }))
    if (canAddMilestone) dispatch(fetchAvailableMilestones({ id }))
  }, [id, canAddMilestone, dispatch, isShared])

  useEffect(() => {
    if (openedTimelineModal) {
      dispatch(fetchSegmentTimelines({ id }))
    }
  }, [id, dispatch, openedTimelineModal])

  const { mainTransportMode } = timeline.summary
  const isParcel = mainTransportMode === TRANSPORT_TYPE_PARCEL

  return (
    <>
      <StyledShipmentTimelineHeader>
        <StyledShipmentTimelineInfo>
          {status.ready &&
            (isParcel ? t('shipments.datesLocalFormatInfo') : t('shipments.datesFormatInfo'))}
        </StyledShipmentTimelineInfo>
        {canEditMilestone && (
          <StyledMilestonesEditButton onClick={() => setEditMode(!editMode)}>
            {!editMode && <span>{t('actions.edit')}</span>}
            {editMode && <span>{t('actions.close')}</span>}
          </StyledMilestonesEditButton>
        )}
      </StyledShipmentTimelineHeader>
      {isFlagged && <ShipmentReportedInfos reportedReasons={shipment.orderFlags} />}
      <Placeholder ready={status.ready} customPlaceholder={<ShipmentTimelineSkeleton />}>
        {status.rejected && <ErrorNotification />}
        <Timeline>
          {openedEditMilestone && (
            <DateDeclarationModal
              addressId={milestoneObject.addressId}
              actualTime={milestoneObject.actualTime}
              actualTimeSource={milestoneObject.sourceMetadata.actualTime}
              nextActualTime={milestoneObject.nextDates.actualTime}
              estimatedTime={milestoneObject.estimatedTime}
              estimatedTimeSource={milestoneObject.sourceMetadata.estimatedTime}
              nextEstimatedTime={milestoneObject.nextDates.estimatedTime}
              type={milestoneObject.milestoneType}
              segmentInfoId={milestoneObject.segmentInfoId}
            />
          )}
          {openedTimelineModal && <ModalTimeline />}
          {openedNewMilestone && (
            <NewMilestoneModal
              locationId={milestoneObject.locationId}
              locationName={milestoneObject.locationName}
              locationTypes={milestoneObject.locationTypes}
            />
          )}
          {isBookingReady && isAnyObject(bookingConfirmation) && (
            <TimelineBookingConfirmationStep status={bookingConfirmation.timelineStatus}>
              <TimelineBookingConfirmationMilestone
                type={t('timeline.bookingConfirmation.transportOrderSent')}
                isCheck={bookingConfirmation.isTransportOrderSent}
                estimatedTime={bookingConfirmation.tet}
                actualTime={bookingConfirmation.tat}
                key='timeline-milestone-transport-order-sent'
              />
              <TimelineBookingConfirmationMilestone
                type={t('timeline.bookingConfirmation.bookingConfirmation')}
                isCheck={bookingConfirmation.isBookingConfirmed}
                estimatedTime={bookingConfirmation.bet}
                actualTime={bookingConfirmation.bat}
                key='timeline-milestone-booking-confirmation'
              />
            </TimelineBookingConfirmationStep>
          )}
          {timeline?.steps.map(({ type, facilityType, milestones, location }, i) => {
            const isCurrent = i === currentStepIndex
            let stepStatus = STEP_STATUS_PAST
            const inTransit =
              isCurrent &&
              timeline.currentStep.inTransit &&
              isPresent(timeline.currentStep.vehicleType)
            if (i > currentStepIndex) {
              stepStatus = STEP_STATUS_FUTURE
            }
            if (isCurrent && !inTransit) {
              stepStatus = STEP_STATUS_CURRENT
            }
            return (
              <TimelineStep
                type={facilityType}
                location={location}
                key={`timeline-step-${stepStatus}-${facilityType}-${location.name}`}
                vehicleType={timeline.currentStep.vehicleType}
                status={stepStatus}
                inTransit={inTransit}
              >
                {milestones.map(
                  ({
                    type: mType,
                    plannedTime,
                    actualTime,
                    estimatedTime,
                    metadata,
                    segmentInfoId,
                    sourceMetadata,
                    nextDates,
                  }) => (
                    <TimelineMilestone
                      type={mType}
                      plannedTime={plannedTime}
                      estimatedTime={estimatedTime}
                      actualTime={actualTime}
                      metadata={metadata}
                      sourceMetadata={sourceMetadata}
                      key={`timeline-milestone-${mType}-${actualTime}-${estimatedTime}-${plannedTime}`}
                      addressId={location.id}
                      segmentInfoId={segmentInfoId}
                      editMode={editMode}
                      openModal={() =>
                        openEditMilestoneModal({
                          estimatedTime,
                          addressId: location.id,
                          actualTime,
                          milestoneType: mType,
                          segmentInfoId,
                          sourceMetadata,
                          nextDates,
                        })
                      }
                      withDiffTime
                    />
                  )
                )}
                {editMode && canAddMilestone && (
                  <StyledUpdateTimeButton
                    as={Button}
                    variant='outline'
                    icon='plus_outline'
                    text={t(`shipments.newMilestoneModal.openButtonText`)}
                    onClick={() =>
                      openNewMilestoneModal({
                        locationId: location.id,
                        locationName: location.name,
                        locationTypes: uniqArray(compactArray([type, timeline.steps[i - 1]?.type])),
                      })
                    }
                  />
                )}
              </TimelineStep>
            )
          })}
        </Timeline>
        {userCan(SEGMENT_TIMELINE_UPDATE) && (
          <StyledEditTimelineButtonWrapper>
            <Button
              icon='pencil'
              text={t(`shipments.actions.editTimeline`)}
              variant='outline'
              onClick={() => setOpenEditTimeline(true)}
            />
          </StyledEditTimelineButtonWrapper>
        )}
      </Placeholder>
    </>
  )
})

ShipmentTimeline.propTypes = {}

ShipmentTimeline.defaultProps = {}

export default ShipmentTimeline
