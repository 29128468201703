import React, { FC, ReactNode, memo, useCallback, useMemo, useRef, useState } from 'react'

import ModalContext from 'components/modal/context'
import { StyledReactSelect } from 'components/select/style'
import { APP_MODAL_NAMES, ModalName } from 'components/modal/types/names'
import ModalProfile from 'components/modal_profile'
import ModalNetwork from 'components/modal_network'
import ImportUsersModal from 'views/users/components/import_users_modal/index'

const ModalContextProviderComponent: FC<{
  initialValue?: Partial<Record<ModalName, boolean>>
  children: ReactNode
}> = ({ initialValue, children }) => {
  const modalProviderRef = useRef<HTMLDivElement | null>(null)

  const [modals, setOpen] = useState<Record<ModalName, boolean>>({
    ...APP_MODAL_NAMES.reduce(
      (acc, name) => ({ ...acc, [name]: false }),
      {} as Record<ModalName, boolean>
    ),
    ...initialValue,
  })

  const customSetOpen = useCallback((popupName, popupIsOpen) => {
    setOpen((prevModals: Record<ModalName, boolean>) => {
      const override = {} as Record<ModalName, boolean>

      Object.keys(prevModals).forEach((key) => {
        const modalName = key as ModalName
        override[modalName] = modalName === popupName ? popupIsOpen : prevModals[modalName]
      })

      return {
        ...prevModals,
        ...override,
      }
    })
  }, [])

  const closeAll = useCallback(
    () =>
      setOpen((prevModals: Record<ModalName, boolean>) =>
        Object.keys(prevModals).reduce((acc, key) => {
          acc[key as ModalName] = false
          return acc
        }, {} as Record<ModalName, boolean>)
      ),
    []
  )
  const anyOpen = useMemo(() => Object.values(modals).some((opened) => opened), [modals])
  const contextValue = useMemo(
    () => ({
      modals,
      setOpen: customSetOpen,
      closeAll,
      anyOpen,
      ref: modalProviderRef,
    }),
    [modals, closeAll, anyOpen, customSetOpen, modalProviderRef]
  )

  return (
    <ModalContext.Provider value={contextValue}>
      {/* HACK
      // In order to style Select component unfolded menu out of the modal,
      // react-select creates a portal to this element
      // styled-component classes are not passed down, as only the menu is rendered in the portal
      // it is in another element than the select element, so there is no inherited style
      // here we add back StyledReactSelect to apply Select menu style
      */}
      <StyledReactSelect ref={modalProviderRef} />
      {children}
      {modals.profile && <ModalProfile />}
      {modals.network && <ModalNetwork />}
      {modals.importUsers && <ImportUsersModal />}
    </ModalContext.Provider>
  )
}

const ModalContextProvider = memo(ModalContextProviderComponent)

export default ModalContextProvider
