import { useMemo } from 'react'

import { STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED, STATUS_UNSTARTED } from 'constants/api'

import type { Status } from 'constants/api'

export interface UseStatus {
  fulfilled: boolean
  pending: boolean
  rejected: boolean
  ready: boolean
}
const useStatus = (status: Status): UseStatus => {
  const statuses = useMemo(
    () => ({
      unstarted: status === STATUS_UNSTARTED,
      fulfilled: status === STATUS_FULFILLED,
      pending: status === STATUS_PENDING,
      rejected: status === STATUS_REJECTED,
      ready: status === STATUS_FULFILLED || status === STATUS_REJECTED,
    }),
    [status]
  )
  return statuses
}

export default useStatus
