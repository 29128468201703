import React, {
  useState,
  Children,
  FC,
  isValidElement,
  ReactElement,
  PropsWithChildren,
} from 'react'

import { StyledPanelsWrapper } from 'components/tabs/style'
import { isPresent } from 'services/helpers/values'
import TabNavigation from 'components/tabs/tab_navigation'
import TabNavigationItem from 'components/tabs/tab_navigation_item'
import TabPanel from 'components/tabs/tab_panel'

interface TabProps {
  id: string
  label: string
  disabled?: boolean
  testId?: string
}

type TabsChildType = ReactElement<PropsWithChildren<TabProps>> | undefined | boolean | null

interface TabsProps {
  onTabChange?: ({ id }: { id: string }) => void
  disabled?: boolean
  defaultTabId?: string
  children: TabsChildType[] | TabsChildType
}

const Tabs: FC<TabsProps> = ({ children, onTabChange, disabled = false, defaultTabId }) => {
  const tabs =
    Children.map(children, (child) =>
      isValidElement(child) ? (child as ReactElement<PropsWithChildren<TabProps>>) : null
    ) ?? []

  const [currentTabId, setCurrentTabId] = useState<string | null>(defaultTabId ?? tabs[0].props.id)
  const currentTab = tabs.find((tab) => tab.props.id === currentTabId)

  return (
    <>
      <TabNavigation>
        {tabs
          .filter((tab) => isPresent(tab))
          .map((tab) => (
            <TabNavigationItem
              key={`tab-nav-item-${tab.props.id}`}
              active={tab.props.id === currentTabId}
              onClick={() => {
                if (disabled || tab.props.disabled) return

                setCurrentTabId(tab.props.id)

                if (onTabChange) onTabChange({ id: tab.props.id })
              }}
              text={tab.props.label}
              disabled={tab.props.disabled}
              testId={tab.props.testId}
            />
          ))}
      </TabNavigation>
      <StyledPanelsWrapper>
        <TabPanel key={`tab-nav-item-${currentTabId}`} active>
          {currentTab?.props.children}
        </TabPanel>
      </StyledPanelsWrapper>
    </>
  )
}

export const Tab: FC<TabProps> = () => <></>

export default Tabs
