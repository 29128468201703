const ALERT_TYPE_ETD_CHANGE = 202
const ALERT_TYPE_ETA_CHANGE = 203
const ALERT_TYPE_DEPARTURE_LOCATION_CHANGED = 205
const ALERT_TYPE_ARRIVAL_LOCATION_CHANGED = 206

export const ALERT_TYPE_ET_CHANGES = [ALERT_TYPE_ETD_CHANGE, ALERT_TYPE_ETA_CHANGE]
export const ALERT_TYPE_LOCATION_CHANGES = [
  ALERT_TYPE_DEPARTURE_LOCATION_CHANGED,
  ALERT_TYPE_ARRIVAL_LOCATION_CHANGED,
]
