import React from 'react'
import PropTypes from 'prop-types'

import { StyledItem, StyledItemLink } from 'components/navigation_item/style'

const NavigationItem = ({
  active = false,
  to,
  text,
  variant = 'dark',
}: {
  active: boolean
  to: string
  text?: string
  variant?: 'dark' | 'light'
}) => (
  <StyledItem $active={active} $variant={variant}>
    <StyledItemLink to={to}>{text}</StyledItemLink>
  </StyledItem>
)

NavigationItem.propTypes = {
  /** Active */
  active: PropTypes.bool,
  /** To */
  to: PropTypes.string,
  /** Text */
  text: PropTypes.string,
  /* Variant */
  variant: PropTypes.oneOf(['light', 'dark']),
}

NavigationItem.defaultProps = {
  active: false,
  to: undefined,
  text: undefined,
  variant: 'dark',
}

export default NavigationItem
