import React, { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import FavoriteComponent from 'components/favorite'
import ShipmentContext from 'components/shipment_list_item/context'

import useTracker from 'services/analytics/hooks/use_tracker'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import { setFavorite, unsetFavorite } from 'views/shipments/slice'
import { addNotification } from 'views/notifications/slice'

const Favorite: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const { id } = shipment
  const isActive = shipment.favorite
  const dispatch = useAppDispatch()
  const { track } = useTracker()
  const { t } = useTranslation()
  const toggleFavorite = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(isActive ? unsetFavorite({ id }) : setFavorite({ id }))
        .unwrap()
        .then(() => {
          track(`Shipment list / ${isActive ? 'unset' : 'set'} favourite`, { status: 'success' })
        })
        .catch(() => {
          track(`Shipment list / ${isActive ? 'unset' : 'set'} favourite`, { status: 'error' })
          dispatch(
            addNotification({
              type: 'alert',
              title: t('errors.notification.title'),
              text: t('errors.notification.content'),
            })
          )
        })
    },
    [dispatch, t, track, isActive, id]
  )
  return <FavoriteComponent active={isActive} onClick={toggleFavorite} />
}

export default Favorite
