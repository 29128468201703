import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

import type { RootState } from 'services/store/store'
import type { Notification } from 'views/notifications/types'

const notificationsAdapter = createEntityAdapter<Notification>()
const initialState = notificationsAdapter.getInitialState()

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action) => {
      notificationsAdapter.addOne(state, { ...action.payload, id: uuidv4() })
    },
    removeNotification: notificationsAdapter.removeOne,
  },
})
export const { addNotification, removeNotification } = notificationsSlice.actions
export const { selectAll: selectAllNotifications } = notificationsAdapter.getSelectors(
  (state: RootState) => state.notifications
)
export default notificationsSlice.reducer
