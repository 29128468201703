import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import PartiesBlock from 'views/shipment/components/references/blocks/parties'
import ProductInformationBlock from 'views/shipment/components/references/blocks/product_information'
import ReferenceBlock from 'views/shipment/components/references/blocks/references'

import {
  SHIPMENT_PARTIES_READ,
  ORDER_ORGANIZATION_READ,
  ORDER_UPDATE_PRODUCT_INFORMATION,
  ORDER_UPDATE_BOOKING_NUMBER,
  ORDER_UPDATE_REF_FORWARDER,
  ORDER_UPDATE_BOOKING_REFERENCES,
} from 'constants/permissions'

import useUserCan from 'views/iam/hooks/use_user_can'

import useTracker from 'services/analytics/hooks/use_tracker'

import {
  StyledArrowWrapper,
  StyledBlockHeader,
  StyledBlocksContainer,
  StyledMenuControl,
} from 'views/shipment/components/references/style'
import { TEST_ID_SHIPMENT_REFERENCES_EDIT_BUTTON } from 'tests/e2e/test_ids'

import Menu, { MenuItem } from 'components/menu'
import Icon from 'components/icon'
import Button from 'components/button'

const BLOCKS = { product: 'product', parties: 'parties', references: 'references' }

const ShipmentReferences = () => {
  const userCan = useUserCan()
  const theme = useTheme()
  const { t } = useTranslation()
  const { track } = useTracker()
  const [currentTab, setCurrentTab] = useState(BLOCKS.product)
  const [isEditing, setIsEditing] = useState(false)

  const userCanEditClientBookingNumber = userCan(ORDER_UPDATE_BOOKING_NUMBER)
  const userCanEditRefForwarder = userCan(ORDER_UPDATE_REF_FORWARDER)
  const userCanEditBookingReferences = userCan(ORDER_UPDATE_BOOKING_REFERENCES)

  const canEdit = () => {
    switch (currentTab) {
      case BLOCKS.product:
        return userCan(ORDER_UPDATE_PRODUCT_INFORMATION)
      case BLOCKS.references:
        return (
          userCanEditClientBookingNumber || userCanEditRefForwarder || userCanEditBookingReferences
        )
      default:
        return false
    }
  }

  return (
    <StyledBlocksContainer>
      <StyledBlockHeader>
        <Menu
          control={
            <StyledMenuControl>
              {t(`shipments.${currentTab}.title`)}
              <StyledArrowWrapper>
                <Icon name='arrow_bottom' height={24} width={24} fill={theme.softGray} />
              </StyledArrowWrapper>
            </StyledMenuControl>
          }
        >
          <MenuItem
            text={t('shipments.product.title')}
            active={BLOCKS.product === currentTab}
            onClick={() => {
              setCurrentTab(BLOCKS.product)
              track('Shipment / product')
              setIsEditing(false)
            }}
          />
          <MenuItem
            text={t('shipments.references.title')}
            active={BLOCKS.references === currentTab}
            onClick={() => {
              setCurrentTab(BLOCKS.references)
              track('Shipment / references')
              setIsEditing(false)
            }}
          />
          <MenuItem
            text={t('shipments.parties.title')}
            active={BLOCKS.parties === currentTab}
            onClick={() => {
              setCurrentTab(BLOCKS.parties)
              track('Shipment / parties')
              setIsEditing(false)
            }}
          />
        </Menu>
        {canEdit() && (
          <Button
            type='button'
            icon='pencil'
            variant='smallIcon'
            onClick={() => setIsEditing(!isEditing)}
            iconHighlighted={isEditing}
            ariaLabel={t('actions.edit')}
            testId={TEST_ID_SHIPMENT_REFERENCES_EDIT_BUTTON}
          />
        )}
      </StyledBlockHeader>
      {currentTab === BLOCKS.product && (
        <ProductInformationBlock isEditing={isEditing} setIsEditing={setIsEditing} />
      )}
      {currentTab === BLOCKS.parties &&
        (userCan(ORDER_ORGANIZATION_READ) || userCan(SHIPMENT_PARTIES_READ)) && <PartiesBlock />}

      {currentTab === BLOCKS.references && (
        <ReferenceBlock isEditing={isEditing} setIsEditing={setIsEditing} />
      )}
    </StyledBlocksContainer>
  )
}

export default ShipmentReferences
