import { InferType, mixed, object, string } from 'yup'

import { LOCALE_EN, LOCALE_FR } from 'constants/locales'

export const languageSchema = object({
  language: object({
    value: mixed<typeof LOCALE_FR | typeof LOCALE_EN>().oneOf([LOCALE_FR, LOCALE_EN]).required(),
    label: string().required(),
    slot: mixed(),
  }),
}).required()
export type LanguageData = InferType<typeof languageSchema>
