import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Capacity } from 'types/companies'

import { SEARCH_TYPE_LOCATION_AIRPORT, SEARCH_TYPE_LOCATION_PORT } from 'constants/search_selects'

import {
  MERCHANDISE_FCL,
  MERCHANDISE_LCL,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_TOTAL,
  TRANSPORT_MODES,
} from 'constants/bookings'
import {
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_INLAND,
  LOCATION_TYPE_PORT,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_SEA,
} from 'constants/shipments'
import DateHelper from 'services/helpers/date_helper'
import iconsMapping from 'services/helpers/icons_mapping'
import {
  getTestIdForTransshipment,
  getTestIdForTransshipmentPta,
  getTestIdForTransshipmentPtd,
  TEST_ID_BOOKING_FORM_ROUTING_ADD_TRANSSHIPMENT,
  TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_LOCATION_SELECT,
  TEST_ID_BOOKING_FORM_ROUTING_DEPARTURE_SWITCH,
  TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_LOCATION_SELECT,
  TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTA,
  TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTD,
  TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_SWITCH,
  TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTA,
  TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTD,
  TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_AIR,
  TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_SEA,
  TEST_ID_BOOKING_FORM_ROUTING_POL,
  TEST_ID_BOOKING_FORM_ROUTING_ADD_POL,
  TEST_ID_BOOKING_FORM_ROUTING_ADD_POD,
  TEST_ID_BOOKING_FORM_ROUTING_POD,
  TEST_ID_BOOKING_FORM_ROUTING_POL_PTD,
  TEST_ID_BOOKING_FORM_ROUTING_POL_PTA,
  TEST_ID_BOOKING_FORM_ROUTING_POD_PTA,
  TEST_ID_BOOKING_FORM_ROUTING_POD_PTD,
} from 'tests/e2e/test_ids'

import { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'
import {
  fetchCompanyAddressOptions,
  fetchLocationOptions,
  ISearch,
} from 'views/select_options/slice'
import {
  StyledCheckboxContainer,
  StyledLabelTransitStepType,
  StyledRoutingColumnHeader,
  StyledRoutingSwitchInputContainer,
  StyledRoutingTransitStepTypeWrapper,
  StyledSwitchInput,
} from 'views/booking/components/form/style'

import Form from 'components/form'
import Grid from 'components/grid'
import InputDatepicker from 'components/input_datepicker'
import CheckboxPicto from 'components/checkbox_picto'
import SearchSelect from 'components/search_select'

import { isNull, isPresent } from 'services/helpers/values'

import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_FRONT_BOOKING_ROUTING_DETAILS } from 'constants/organization_features'
import SwitchInput from 'components/switch_input'
import { Transshipment } from 'views/booking/slices/types'
import BlockContainerAdd from 'components/block_container/block_container_add'
import Button from 'components/button'
import Marker from 'components/marker'
import { SelectValue } from 'components/select'

const Routing: FC<BookingFormProps> = ({ filters }) => {
  const {
    transportTypeFilter,
    preCarriageFilter,
    polFilter,
    polPtdFilter,
    polPtaFilter,
    podFilter,
    podPtdFilter,
    podPtaFilter,
    onCarriageFilter,
    ptdFilter,
    ptaFilter,
    carrierFilter,
    transshipmentsFilter,
    transportPlanFilter,
    merchandiseFilter,
    vesselsFilter,
    voyageNumbersFilter,
  } = filters

  const { t } = useTranslation()

  const { features } = useOrganizationCan()

  const filterLocations = (locations: { value: number; label: string }[]) =>
    locations.filter(
      (location) =>
        ![
          preCarriageFilter.value?.value,
          polFilter.value?.value,
          podFilter.value?.value,
          onCarriageFilter.value?.value,
          ...transshipmentsFilter.value?.map(
            ({ address }: { address: SelectValue; pta: string; ptd: string }) => address?.value
          ),
        ].includes(location.value)
    )

  const fetchLocationOptionsWithTooltip = (params: ISearch) =>
    fetchLocationOptions({ withTooltip: true, ...params })

  const fetchCompanyAddressOptionsWithTooltip = (params: ISearch, capacities: Capacity[]) =>
    fetchCompanyAddressOptions({ capacities, params: { ...params, withTooltip: true } })

  const transitAddressType =
    transportTypeFilter.value === TRANSPORT_TYPE_SEA
      ? SEARCH_TYPE_LOCATION_PORT
      : SEARCH_TYPE_LOCATION_AIRPORT

  const routingHasPreCarriage = transportPlanFilter.value.withPreCarriage
  const routingHasOnCarriage = transportPlanFilter.value.withOnCarriage

  // The filters of the first step of the routing
  const originFilter = routingHasPreCarriage ? preCarriageFilter : polFilter
  const originPtdFilter = routingHasPreCarriage ? ptdFilter : polPtdFilter
  // The filters of the last step of the routing
  const destinationFilter = routingHasOnCarriage ? onCarriageFilter : podFilter
  const destinationPtaFilter = routingHasOnCarriage ? ptaFilter : podPtaFilter

  const transportTypeChangeFilterReset = (e: any) => {
    transportTypeFilter.onChange(e)
    polFilter.reset()
    podFilter.reset()
    carrierFilter.reset()
    transshipmentsFilter.reset()
    merchandiseFilter.onChange((state: any) => {
      state.shippingMode = MERCHANDISE_LCL
      state.merchandiseType = MERCHANDISE_TYPE_TOTAL
    })
  }
  const originTypeChange = () => {
    transportPlanFilter.onChange((state: any) => {
      state.withPreCarriage = !state.withPreCarriage
      if (!state.withPreCarriage) {
        state.withPol = true
      }
      if (state.withPol && state.withPreCarriage && isNull(polFilter.value)) {
        state.withPol = false
      }
    })
  }
  const destinationTypeChange = () => {
    transportPlanFilter.onChange((state: any) => {
      state.withOnCarriage = !state.withOnCarriage
      if (!state.withOnCarriage) {
        state.withPod = true
      }
      if (state.withPod && state.withOnCarriage && isNull(podFilter.value)) {
        state.withPod = false
      }
    })
  }

  return (
    <>
      <Form.Group title={t('bookings.routing.title')}>
        <StyledCheckboxContainer>
          <CheckboxPicto
            icon={iconsMapping(TRANSPORT_MODES.SEA, 'transportation')}
            type='radio'
            text={t('bookings.transportMode.sea')}
            condensed
            name={transportTypeFilter.name}
            onChange={(e) => {
              transportTypeChangeFilterReset(e)
              merchandiseFilter.onChange((state: any) => {
                state.shippingMode = MERCHANDISE_FCL
                state.merchandiseType = MERCHANDISE_TYPE_TOTAL
              })
            }}
            checked={transportTypeFilter.value === TRANSPORT_TYPE_SEA}
            value={TRANSPORT_TYPE_SEA}
            disabled={transportTypeFilter.isDisabled}
            testId={TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_SEA}
          />
          <CheckboxPicto
            icon={iconsMapping(TRANSPORT_MODES.AIR, 'transportation')}
            type='radio'
            text={t('bookings.transportMode.air')}
            condensed
            name={transportTypeFilter.name}
            onChange={(e) => {
              transportTypeChangeFilterReset(e)
              merchandiseFilter.onChange((state: any) => {
                state.shippingMode = MERCHANDISE_LCL
                state.merchandiseType = MERCHANDISE_TYPE_PACKAGE
              })
              vesselsFilter.reset()
              voyageNumbersFilter.reset()
            }}
            checked={transportTypeFilter.value === TRANSPORT_TYPE_AIR}
            value={TRANSPORT_TYPE_AIR}
            disabled={transportTypeFilter.isDisabled}
            testId={TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_AIR}
          />
        </StyledCheckboxContainer>
        <Grid columns={17} condensed>
          <Grid.Row>
            <Grid.Column size={4} centered>
              <StyledRoutingColumnHeader>
                {t('bookings.routing.columnHeaders.type')}
              </StyledRoutingColumnHeader>
            </Grid.Column>
            <Grid.Column size={4} centered>
              <StyledRoutingColumnHeader>
                {t('bookings.routing.columnHeaders.location')}
              </StyledRoutingColumnHeader>
            </Grid.Column>
            <Grid.Column size={4} centered>
              <StyledRoutingColumnHeader>
                {t('bookings.routing.columnHeaders.pta')}
              </StyledRoutingColumnHeader>
            </Grid.Column>
            <Grid.Column size={4} centered>
              <StyledRoutingColumnHeader>
                {t('bookings.routing.columnHeaders.ptd')}
              </StyledRoutingColumnHeader>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column centered size={4}>
              <StyledSwitchInput
                as={SwitchInput}
                leftLabel={
                  <StyledRoutingSwitchInputContainer>
                    <Marker icon={LOCATION_TYPE_INLAND} />
                    <span>{t('bookings.routing.facility')}</span>
                  </StyledRoutingSwitchInputContainer>
                }
                rightLabel={
                  transportTypeFilter.value === TRANSPORT_TYPE_SEA ? (
                    <StyledRoutingSwitchInputContainer>
                      <span>{t('bookings.routing.port')}</span>
                      <Marker icon={LOCATION_TYPE_PORT} />
                    </StyledRoutingSwitchInputContainer>
                  ) : (
                    <StyledRoutingSwitchInputContainer>
                      <span>{t('bookings.routing.airport')}</span>
                      <Marker icon={LOCATION_TYPE_AIRPORT} />
                    </StyledRoutingSwitchInputContainer>
                  )
                }
                onClick={originTypeChange}
                rightOptionChecked={!routingHasPreCarriage}
                disabled={transportPlanFilter.isDisabled}
                testId={TEST_ID_BOOKING_FORM_ROUTING_DEPARTURE_SWITCH}
              />
            </Grid.Column>
            <Grid.Column size={4}>
              {routingHasPreCarriage ? (
                <SearchSelect
                  action={(params: ISearch) =>
                    fetchCompanyAddressOptionsWithTooltip(params, ['consignor'])
                  }
                  placeholder={t('bookings.select.actions.searchConsignor')}
                  fetchedOptionsFormat={filterLocations}
                  name={originFilter.name}
                  onChange={originFilter.onChange}
                  value={originFilter.value}
                  isDisabled={originFilter.isDisabled}
                  testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_LOCATION_SELECT}
                />
              ) : (
                <SearchSelect
                  action={fetchLocationOptionsWithTooltip}
                  fetchedOptionsFormat={filterLocations}
                  type={transitAddressType}
                  name={originFilter.name}
                  onChange={originFilter.onChange}
                  value={originFilter.value}
                  isDisabled={originFilter.isDisabled}
                  testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_LOCATION_SELECT}
                />
              )}
            </Grid.Column>
            <Grid.Column size={4}>
              <InputDatepicker disabled testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTA} />
            </Grid.Column>
            <Grid.Column size={4}>
              <InputDatepicker
                withPortal
                name={originPtdFilter.name}
                onChange={originPtdFilter.onChange}
                startDate={originPtdFilter.value}
                disabled={originPtdFilter.isDisabled}
                testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTD}
              />
            </Grid.Column>
          </Grid.Row>
          {features(WITH_FRONT_BOOKING_ROUTING_DETAILS) && (
            <>
              <Grid.Row>
                {routingHasPreCarriage && !transportPlanFilter.value.withPol && (
                  <Grid.Column size={17} centered>
                    <BlockContainerAdd
                      title={t(
                        `bookings.routing.${
                          transportTypeFilter.value === TRANSPORT_TYPE_SEA ? 'pol' : 'polAir'
                        }`
                      )}
                      addBlock={() => {
                        transportPlanFilter.onChange((state: any) => {
                          state.withPol = true
                        })
                      }}
                      btnSize={2}
                      columns={5}
                      testId={TEST_ID_BOOKING_FORM_ROUTING_ADD_POL}
                    />
                  </Grid.Column>
                )}

                {routingHasPreCarriage && transportPlanFilter.value.withPol && (
                  <>
                    <Grid.Column size={4} centered>
                      <StyledRoutingTransitStepTypeWrapper>
                        <StyledLabelTransitStepType>
                          {t(
                            `bookings.routing.${
                              transportTypeFilter.value === TRANSPORT_TYPE_SEA ? 'pol' : 'polAir'
                            }`
                          )}
                        </StyledLabelTransitStepType>
                      </StyledRoutingTransitStepTypeWrapper>
                    </Grid.Column>
                    <Grid.Column size={4}>
                      <SearchSelect
                        action={fetchLocationOptions}
                        fetchedOptionsFormat={filterLocations}
                        type={transitAddressType}
                        name={polFilter.name}
                        value={polFilter.value}
                        onChange={polFilter.onChange}
                        required
                        isDisabled={polFilter.isDisabled}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_POL}
                      />
                    </Grid.Column>
                    <Grid.Column size={4}>
                      <InputDatepicker
                        withPortal
                        startDate={polPtaFilter.value}
                        onChange={polPtaFilter.onChange}
                        disabled={polPtaFilter.isDisabled}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_POL_PTA}
                      />
                    </Grid.Column>
                    <Grid.Column size={4} padded={false}>
                      <InputDatepicker
                        withPortal
                        startDate={polPtdFilter.value}
                        onChange={polPtdFilter.onChange}
                        disabled={polPtdFilter.isDisabled}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_POL_PTD}
                      />
                    </Grid.Column>
                    <Grid.Column size={1}>
                      <Button
                        variant='smallIcon'
                        icon='trash'
                        onClick={() => {
                          transportPlanFilter.onChange((state: any) => {
                            state.withPol = false
                          })
                        }}
                      />
                    </Grid.Column>
                  </>
                )}
              </Grid.Row>

              {transshipmentsFilter.value.map((transshipment: Transshipment, index: number) => (
                <Grid.Row>
                  <Grid.Column size={4} centered>
                    <StyledRoutingTransitStepTypeWrapper>
                      <StyledLabelTransitStepType>
                        {t('bookings.routing.transshipment', { count: 1 })}
                      </StyledLabelTransitStepType>
                    </StyledRoutingTransitStepTypeWrapper>
                  </Grid.Column>
                  <Grid.Column size={4}>
                    <SearchSelect
                      action={fetchLocationOptions}
                      fetchedOptionsFormat={filterLocations}
                      type={transitAddressType}
                      name={transshipmentsFilter.name}
                      value={transshipmentsFilter.value[index]?.address}
                      onChange={({ value }) => {
                        transshipmentsFilter.onChange((state: any) => {
                          state[index].address = value
                        })
                      }}
                      required={!transshipmentsFilter.isDisabled}
                      isDisabled={transshipmentsFilter.isDisabled}
                      testId={getTestIdForTransshipment(index)}
                    />
                  </Grid.Column>
                  <Grid.Column size={4}>
                    <InputDatepicker
                      withPortal
                      startDate={transshipmentsFilter.value[index]?.pta}
                      onChange={({ value }: { value: any }) => {
                        transshipmentsFilter.onChange((state: any) => {
                          // eslint-disable-next-line prefer-destructuring
                          state[index].pta = isPresent(value[0])
                            ? new DateHelper(value[0]).toISOString()
                            : null
                        })
                      }}
                      disabled={transshipmentsFilter.isDisabled}
                      testId={getTestIdForTransshipmentPta(index)}
                    />
                  </Grid.Column>
                  <Grid.Column size={4} padded={false}>
                    <InputDatepicker
                      withPortal
                      startDate={transshipmentsFilter.value[index]?.ptd}
                      onChange={({ value }: { value: any }) => {
                        transshipmentsFilter.onChange((state: any) => {
                          // eslint-disable-next-line prefer-destructuring
                          state[index].ptd = isPresent(value[0])
                            ? new DateHelper(value[0]).toISOString()
                            : null
                        })
                      }}
                      disabled={transshipmentsFilter.isDisabled}
                      testId={getTestIdForTransshipmentPtd(index)}
                    />
                  </Grid.Column>
                  <Grid.Column size={1}>
                    <Button
                      variant='smallIcon'
                      icon='trash'
                      onClick={() => {
                        transshipmentsFilter.onChange((state: any) => {
                          state.splice(index, 1)
                        })
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              ))}
              <Grid.Row>
                <Grid.Column size={17} centered>
                  <BlockContainerAdd
                    title={t('bookings.routing.transshipment', { count: 1 })}
                    addBlock={() =>
                      transshipmentsFilter.onChange((state: any) => {
                        state[state.length] = {}
                      })
                    }
                    btnSize={2}
                    columns={5}
                    testId={TEST_ID_BOOKING_FORM_ROUTING_ADD_TRANSSHIPMENT}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                {routingHasOnCarriage && !transportPlanFilter.value.withPod && (
                  <Grid.Column size={17} centered>
                    <BlockContainerAdd
                      title={t(
                        `bookings.routing.${
                          transportTypeFilter.value === TRANSPORT_TYPE_SEA ? 'pod' : 'podAir'
                        }`
                      )}
                      addBlock={() => {
                        transportPlanFilter.onChange((state: any) => {
                          state.withPod = true
                        })
                      }}
                      btnSize={2}
                      columns={5}
                      testId={TEST_ID_BOOKING_FORM_ROUTING_ADD_POD}
                    />
                  </Grid.Column>
                )}

                {routingHasOnCarriage && transportPlanFilter.value.withPod && (
                  <>
                    <Grid.Column size={4} centered>
                      <StyledRoutingTransitStepTypeWrapper>
                        <StyledLabelTransitStepType>
                          {t(
                            `bookings.routing.${
                              transportTypeFilter.value === TRANSPORT_TYPE_SEA ? 'pod' : 'podAir'
                            }`
                          )}
                        </StyledLabelTransitStepType>
                      </StyledRoutingTransitStepTypeWrapper>
                    </Grid.Column>
                    <Grid.Column size={4}>
                      <SearchSelect
                        action={fetchLocationOptions}
                        fetchedOptionsFormat={filterLocations}
                        type={transitAddressType}
                        name={podFilter.name}
                        value={podFilter.value}
                        onChange={podFilter.onChange}
                        required
                        isDisabled={podFilter.isDisabled}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_POD}
                      />
                    </Grid.Column>
                    <Grid.Column size={4}>
                      <InputDatepicker
                        withPortal
                        startDate={podPtaFilter.value}
                        onChange={podPtaFilter.onChange}
                        disabled={podPtaFilter.isDisabled}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_POD_PTA}
                      />
                    </Grid.Column>
                    <Grid.Column size={4} padded={false}>
                      <InputDatepicker
                        withPortal
                        startDate={podPtdFilter.value}
                        onChange={podPtdFilter.onChange}
                        disabled={podPtdFilter.isDisabled}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_POD_PTD}
                      />
                    </Grid.Column>
                    <Grid.Column size={1}>
                      <Button
                        variant='smallIcon'
                        icon='trash'
                        onClick={() => {
                          transportPlanFilter.onChange((state: any) => {
                            state.withPod = false
                          })
                        }}
                      />
                    </Grid.Column>
                  </>
                )}
              </Grid.Row>
            </>
          )}
          <Grid.Row>
            <Grid.Column centered size={4}>
              <StyledSwitchInput
                as={SwitchInput}
                leftLabel={
                  <StyledRoutingSwitchInputContainer>
                    <Marker icon={LOCATION_TYPE_INLAND} />
                    <span>{t('bookings.routing.facility')}</span>
                  </StyledRoutingSwitchInputContainer>
                }
                rightLabel={
                  transportTypeFilter.value === TRANSPORT_TYPE_SEA ? (
                    <StyledRoutingSwitchInputContainer>
                      <span>{t('bookings.routing.port')}</span>
                      <Marker icon={LOCATION_TYPE_PORT} />
                    </StyledRoutingSwitchInputContainer>
                  ) : (
                    <StyledRoutingSwitchInputContainer>
                      <span>{t('bookings.routing.airport')}</span>
                      <Marker icon={LOCATION_TYPE_AIRPORT} />
                    </StyledRoutingSwitchInputContainer>
                  )
                }
                onClick={destinationTypeChange}
                rightOptionChecked={!routingHasOnCarriage}
                disabled={transportPlanFilter.isDisabled}
                testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_SWITCH}
              />
            </Grid.Column>
            <Grid.Column size={4}>
              {routingHasOnCarriage ? (
                <SearchSelect
                  action={(params: ISearch) =>
                    fetchCompanyAddressOptionsWithTooltip(params, ['consignee'])
                  }
                  placeholder={t('bookings.select.actions.searchConsignee')}
                  fetchedOptionsFormat={filterLocations}
                  name={destinationFilter.name}
                  onChange={destinationFilter.onChange}
                  value={destinationFilter.value}
                  isDisabled={destinationFilter.isDisabled}
                  testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_LOCATION_SELECT}
                />
              ) : (
                <SearchSelect
                  action={fetchLocationOptionsWithTooltip}
                  fetchedOptionsFormat={filterLocations}
                  type={transitAddressType}
                  name={destinationFilter.name}
                  onChange={destinationFilter.onChange}
                  value={destinationFilter.value}
                  isDisabled={destinationFilter.isDisabled}
                  testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_LOCATION_SELECT}
                />
              )}
            </Grid.Column>
            <Grid.Column size={4}>
              <InputDatepicker
                withPortal
                name={destinationPtaFilter.name}
                onChange={destinationPtaFilter.onChange}
                startDate={destinationPtaFilter.value}
                disabled={destinationPtaFilter.isDisabled}
                testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTA}
              />
            </Grid.Column>
            <Grid.Column size={4}>
              <InputDatepicker disabled testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTD} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Group>
    </>
  )
}

export default Routing
