import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

import { v4 as uuidv4 } from 'uuid'

import {
  StyledSavedTemplatesItem,
  StyledSavedTemplatesSection,
} from 'components/booking_templates_saved_item/style'

export interface BookingListSkeletionProps {
  count: number
}

const BookingListSkeleton: FC<BookingListSkeletionProps> = ({ count = 8 }) => (
  <>
    {Array.from(Array(count)).map(() => (
      <StyledSavedTemplatesItem key={uuidv4()}>
        <StyledSavedTemplatesSection>
          <ContentLoader viewBox='0 0 115 23' style={{ width: '100%' }}>
            <rect x='0' y='4' rx='3' ry='3' width='70' height='3' />
            <rect x='0' y='14' rx='5' ry='5' width='28' height='8' />
            <rect x='32' y='14' rx='5' ry='5' width='28' height='8' />
            <rect x='64' y='14' rx='5' ry='5' width='28' height='8' />
          </ContentLoader>
        </StyledSavedTemplatesSection>
      </StyledSavedTemplatesItem>
    ))}
  </>
)

export default BookingListSkeleton
