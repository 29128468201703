import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit'

import { MilestoneType, DatesMilestoneType } from 'types/milestones'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED } from 'constants/api'

import { InternalClient, SharedClient } from 'services/api/clients'
import onError from 'services/api/error'
import useFormData from 'services/api/hooks/use_form_data'
import useUploadProgress from 'services/api/hooks/use_upload_progress'
import { isPresent, toCamelCase, toSnakeCase } from 'services/helpers/values'
import { setFavorite, unsetFavorite } from 'views/shipments/slice'

import { EditPathEndpointParams } from 'components/modal_path/helpers'

import { TimelineFormMilestone, TimelineFormStep } from 'views/shipment/types/segment_timelines'

import formatReferences from 'views/shipment/components/references/helpers'

import type { RootState } from 'services/store/store'
import type {
  AvailableMilestone,
  Document,
  Shipment,
  NullTimeline,
} from 'views/shipment/types/shipment'

export interface MilestoneUpdatePayload {
  id: string
  actualTime?: string | null
  estimatedTime?: string | null
  addressId: number
  milestoneType: DatesMilestoneType
  segmentInfoId: number
}

export interface ListAvailableMilestonesPayload {
  id: string
  addressId: number
}

export interface AddMilestonePayload {
  id: string
  milestoneType: MilestoneType
  estimatedTime: string | null
  actualTime: string | null
  addressId: number
}

export interface UpdateProductInformationsPayload {
  token: string
  loadWeight?: number
  loadVolume?: number
  productDescription?: string
  packageNumber?: number
}

export interface UpdateReferencesPayload {
  id: string
  clientBookingNumber?: string
  refForwarder?: string
  clientReference?: string
  shipmentReference?: string
}

export interface EditTimelinePayload {
  segments: {
    id: number
    steps: TimelineFormStep[]
    milestones: TimelineFormMilestone[]
    stepsToRemove?: TimelineFormStep[]
  }[]
}

// TODO: REFACTOR:
// The document type is a snake_cased value, but our interface translates all API responses in camelCase
// In order to map properly this value, which is used as an object key y our system, we need to camelize it
// To avoid this manipulation, the response shipment should be encapsulated in an object responsible to
// expose interface and translate these kind of data
export const fetchShipment = createAsyncThunk(
  'shipment/fetchShipment',
  async ({ id }: { id: string }, thunkAPI) =>
    InternalClient.get(`/orders/${id}`)
      .then((r) => r.data)
      .then(({ token, ...order }: Shipment) => {
        const { documents } = order
        if (isPresent(documents)) {
          documents.map((document: Document) => ({
            ...document,
            documentType: toCamelCase(document.documentType),
          }))
        }
        return { ...order, id: token }
      })
      .catch(onError(thunkAPI))
)
export const fetchEmissions = createAsyncThunk(
  'shipment/fetchEmissions',
  async ({ id }: { id: string }, thunkAPI) =>
    InternalClient.get(`/orders/${id}/carbon_footprint`)
      .then((r) => r.data)
      .then((emissions) => ({
        id,
        emissions,
      }))
      .catch(onError(thunkAPI))
)
export const fetchAlerts = createAsyncThunk(
  'shipment/fetchAlerts',
  async ({ id }: { id: string }, thunkAPI) =>
    InternalClient.get(`/orders/${id}/alerts`)
      .then((r) => r.data)
      .then((alerts) => ({
        id,
        alerts,
      }))
      .catch(onError(thunkAPI))
)
export const fetchTimeline = createAsyncThunk(
  'shipment/fetchTimeline',
  async ({ id }: { id: string }, thunkAPI) =>
    InternalClient.get(`/orders/${id}/timeline`)
      .then((r) => r.data)
      .then((timeline) => ({
        id,
        timeline,
      }))
      .catch(onError(thunkAPI))
)
export const fetchSegmentTimelines = createAsyncThunk(
  'shipment/fetchSegmentTimelines',
  async ({ id }: { id: string }, thunkAPI) =>
    InternalClient.get(`/shipments/${id}/segments_timelines`)
      .then((r) => r.data)
      .then((segmentTimelines) => ({ id, segmentTimelines }))
      .catch(onError(thunkAPI))
)
export const editTimeline = createAsyncThunk(
  'shipment/editTimeline',
  async ({ id, payload: { segments } }: { id: string; payload: EditTimelinePayload }, thunkAPI) =>
    InternalClient.post(`/shipments/${id}/segments_timelines_changes`, { segments })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)
export const fetchBookingConfirmation = createAsyncThunk(
  'shipment/fetchBookingConfirmation',
  async ({ id }: { id: string }, thunkAPI) =>
    InternalClient.get(`/shipments/${id}/booking_confirmation`)
      .then((r) => r.data)
      .then((bookingConfirmation) => ({
        id,
        bookingConfirmation,
      }))
      .catch(onError(thunkAPI))
)
export const fetchSharedOrderToken = createAsyncThunk(
  'shipment/fetchSharedOrderToken',
  async ({ token }: { token: string }, thunkAPI) =>
    SharedClient.post('/orders/generate_token', { token })
      .then((r) => r.data)
      .then(({ sharedOrderToken }) => ({ sharedOrderToken }))
      .catch(onError(thunkAPI))
)

export const sharedOrderSendMail = createAsyncThunk(
  'shipment/sharedOrderSendMail',
  async (
    {
      token,
      recipients,
      subject,
      content,
    }: { token: string; recipients: string[]; subject: string; content: string },
    thunkAPI
  ) =>
    SharedClient.post('/orders/send_mail', { token, recipients, subject, content }).catch(
      onError(thunkAPI)
    )
)

export const fetchSharedShipment = createAsyncThunk(
  'shipment/fetchSharedShipment',
  async ({ token }: { token: string }, thunkAPI) =>
    SharedClient.get(`/orders/${token}`)
      .then((r) => r.data)
      .then((sharedShipment) => ({ id: token, ...sharedShipment }))
      .catch(onError(thunkAPI))
)

// TODO: REFACTOR:
// should be namespaced under / orders /:id instead of passing an `order_id`attribute
export const uploadDocument = createAsyncThunk(
  'shipment/uploadDocument',
  async (
    {
      id,
      documentType,
      document,
      documentId,
    }: { id: string; documentType: string; document: File; documentId: number },
    thunkAPI
  ) => {
    const formData = useFormData({
      orderId: id,
      documentType: toSnakeCase(documentType),
      file: document,
    })
    const { getProgress } = useUploadProgress()
    const { dispatch } = thunkAPI

    return InternalClient.post('/documents', formData, {
      onUploadProgress: (e) => {
        dispatch(addDocumentUploadProgress({ id: documentId, progress: getProgress(e) }))
      },
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const deleteShipment = createAsyncThunk(
  'shipment/deleteShipment',
  async (shipmentId: string, thunkApi) =>
    InternalClient.delete(`/shipment/${shipmentId}`)
      .then((r) => r.data)
      .catch(onError(thunkApi))
)

export const editShipmentPath = createAsyncThunk(
  'shipment/editPath',
  async (
    { shipmentId, shipmentPath }: { shipmentId: string; shipmentPath: EditPathEndpointParams },
    thunkApi
  ) =>
    InternalClient.patch(`/shipments/${shipmentId}/path`, {
      positions: shipmentPath,
    })
      .then((r) => r.data)
      .catch(onError(thunkApi))
)

// TODO: REFACTOR: should be namespaced under /orders/:id
export const deleteDocument = createAsyncThunk(
  'shipment/deleteDocument',
  async ({ documentId }: { documentId: string }, thunkAPI) =>
    InternalClient.delete(`/documents/${documentId}`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const updateProductDetails = createAsyncThunk(
  'shipment/updateProductDetails',
  async (payload: UpdateProductInformationsPayload, thunkAPI) =>
    InternalClient.patch(`/shipments/${payload.token}/product_information`, payload)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const updateReferences = createAsyncThunk(
  'shipment/updateReferences',
  async ({ ...params }: UpdateReferencesPayload, thunkAPI) =>
    InternalClient.patch(`/shipments/${params.id}/references`, {
      ...params,
      token: params.id,
    })
      .then((r) => r.data)
      .then(
        ({
          token,
          clientBookingNumber: cbnResponse,
          forwarderReference,
          shipmentReference,
          clientReference,
          bookingReferences,
        }) => ({
          id: token,
          clientBookingNumber: cbnResponse,
          refForwarder: forwarderReference,
          shipmentReference,
          clientReference,
          bookingReferences,
        })
      )
      .catch(onError(thunkAPI))
)

export const updateMilestoneDates = createAsyncThunk(
  'shipment/updateMilstoneDates',
  async (
    {
      id,
      actualTime,
      estimatedTime,
      addressId,
      milestoneType,
      segmentInfoId,
    }: MilestoneUpdatePayload,
    thunkAPI
  ) =>
    InternalClient.patch(`/shipments/${id}/milestone`, {
      segmentInfoId,
      actualTime,
      estimatedTime,
      addressId,
      milestoneType,
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const fetchAvailableMilestonesForStep = createAsyncThunk(
  'shipment/fetchAvailableMilestonesForStep',
  async ({ transportType, stepType }: { transportType: string; stepType: string }, thunkApi) =>
    InternalClient.get(
      `/shipments/steps/milestones?transport_type=${transportType}&step_type=${stepType}`
    )
      .then((r) => r.data)
      .catch(onError(thunkApi))
)

export const fetchAvailableMilestones = createAsyncThunk(
  'shipment/listAvailableMilestones',
  async ({ id }: ListAvailableMilestonesPayload, thunkAPI) =>
    InternalClient.get(`/shipments/${id}/available_milestones`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const addMilestone = createAsyncThunk(
  'shipment/addMilestone',
  async (
    { id, milestoneType, estimatedTime, actualTime, addressId }: AddMilestonePayload,
    thunkAPI
  ) =>
    InternalClient.post(`/shipments/${id}/milestone`, {
      milestoneType,
      estimatedTime,
      actualTime,
      addressId,
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const reportShipment = createAsyncThunk(
  'shipment/reportShipment',
  async ({ id, flagReasonsIds }: { id: string; flagReasonsIds: string[] }, thunkApi) =>
    InternalClient.post(`/shipment/${id}/report`, {
      flagReasonsIds,
    })
      .then((r) => r?.data)
      .catch(onError(thunkApi))
)

const shipmentAdapter = createEntityAdapter<Shipment>()

interface ShipmentState {
  statuses: Record<string, any>
  documentUploadProgresses: Record<string, number>
}

const initialState = shipmentAdapter.getInitialState({
  statuses: {},
  documentUploadProgresses: {},
} as ShipmentState)

const getStatus = (
  state: ShipmentState,
  action: PayloadAction<
    any,
    string,
    {
      arg: {
        id: string
      }
    },
    never
  >
) => {
  if (!state.statuses[action.meta.arg.id]) state.statuses[action.meta.arg.id] = {}
  return state.statuses[action.meta.arg.id]
}
const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    addDocumentUploadProgress: (state, action: PayloadAction<{ id: number; progress: number }>) => {
      state.documentUploadProgresses[action.payload.id] = action.payload.progress
    },
    resetDocumentUploadProgresses: (state) => {
      state.documentUploadProgresses = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShipment.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
      getStatus(state, action).shipment = STATUS_FULFILLED
    })
    builder.addCase(fetchShipment.pending, (state, action) => {
      getStatus(state, action).shipment = STATUS_PENDING
    })
    builder.addCase(fetchShipment.rejected, (state, action) => {
      getStatus(state, action).shipment = STATUS_REJECTED
    })
    builder.addCase(fetchSharedShipment.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
      // HACK:
      // It behaves like alerts, timeline and emissions requests are fulfilled because it receives everything in the same response from fetchSharedShipment
      state.statuses[action.meta.arg.token].shipment = STATUS_FULFILLED
      state.statuses[action.meta.arg.token].alerts = STATUS_FULFILLED
      state.statuses[action.meta.arg.token].timeline = STATUS_FULFILLED
      state.statuses[action.meta.arg.token].emissions = STATUS_FULFILLED
    })
    builder.addCase(fetchSharedShipment.pending, (state, action) => {
      state.statuses[action.meta.arg.token].shipment = STATUS_PENDING
    })
    builder.addCase(fetchSharedShipment.rejected, (state, action) => {
      state.statuses[action.meta.arg.token].shipment = STATUS_REJECTED
    })
    builder.addCase(fetchTimeline.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
      getStatus(state, action).timeline = STATUS_FULFILLED
    })
    builder.addCase(fetchTimeline.rejected, (state, action) => {
      getStatus(state, action).timeline = STATUS_REJECTED
    })
    builder.addCase(fetchTimeline.pending, (state, action) => {
      getStatus(state, action).timeline = STATUS_PENDING
    })
    builder.addCase(fetchSegmentTimelines.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
      getStatus(state, action).segmentTimelines = STATUS_FULFILLED
    })
    builder.addCase(fetchSegmentTimelines.rejected, (state, action) => {
      getStatus(state, action).segmentTimelines = STATUS_REJECTED
    })
    builder.addCase(fetchSegmentTimelines.pending, (state, action) => {
      getStatus(state, action).segmentTimelines = STATUS_PENDING
    })
    builder.addCase(fetchAlerts.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
      getStatus(state, action).alerts = STATUS_FULFILLED
    })
    builder.addCase(fetchBookingConfirmation.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(fetchAlerts.rejected, (state, action) => {
      getStatus(state, action).alerts = STATUS_REJECTED
    })
    builder.addCase(fetchAlerts.pending, (state, action) => {
      getStatus(state, action).alerts = STATUS_PENDING
    })
    builder.addCase(fetchEmissions.fulfilled, (state, action) => {
      shipmentAdapter.upsertOne(state, action.payload)
      getStatus(state, action).emissions = STATUS_FULFILLED
    })
    builder.addCase(fetchEmissions.rejected, (state, action) => {
      getStatus(state, action).emissions = STATUS_REJECTED
    })
    builder.addCase(fetchEmissions.pending, (state, action) => {
      getStatus(state, action).emissions = STATUS_PENDING
    })
    builder.addCase(updateReferences.fulfilled, (state, action) => {
      const {
        id,
        clientBookingNumber,
        refForwarder,
        clientReference: refClient,
        shipmentReference: ref,
        bookingReferences = {},
      } = action.payload
      const formattedBookingReferences = formatReferences(bookingReferences)
      shipmentAdapter.updateOne(state, {
        id,
        changes: {
          clientBookingNumber,
          refForwarder,
          refClient,
          ref,
          bookingReferences: formattedBookingReferences,
        },
      })
      getStatus(state, action).shipment = STATUS_FULFILLED
    })
    builder.addCase(fetchAvailableMilestones.fulfilled, (state, action) => {
      shipmentAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: { availableMilestones: action.payload },
      })
    })
    builder.addCase(setFavorite.fulfilled, (state, action) => {
      shipmentAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { favorite: true },
      })
    })
    builder.addCase(unsetFavorite.fulfilled, (state, action) => {
      shipmentAdapter.updateOne(state, {
        id: action.payload.id,
        changes: { favorite: false },
      })
    })
  },
})
export const { selectById: selectShipment } = shipmentAdapter.getSelectors(
  (state: RootState) => state.shipment
)

export const { addDocumentUploadProgress, resetDocumentUploadProgresses } = shipmentSlice.actions

export const selectTimeline = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) =>
      shipment?.timeline ||
      ({
        summary: {
          pickup: null,
          pol: null,
          pod: null,
          delivery: null,
        },
        steps: [],
        currentStep: {},
      } as NullTimeline)
  )

export const selectSegmentTimelines = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.segmentTimelines || []
  )
export const selectAlerts = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.alerts || []
  )
export const selectEmissions = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.emissions || { co2e: null }
  )
export const selectLegs = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.segmentInfos || []
  )
export const selectPath = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.path || []
  )
export const selectUsers = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.users || []
  )
export const selectDocuments = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.documents || []
  )
export const selectUserReference = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.reference
  )
export const selectFirstLeg = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) =>
      shipment?.segmentInfos?.[0] || { departure: { name: null }, arrival: { name: null } }
  )
export const selectLastLeg = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) =>
      shipment?.segmentInfos
        ? shipment.segmentInfos[shipment.segmentInfos.length - 1]
        : { departure: { name: null }, arrival: { name: null } }
  )
export const selectAvailableMilestonesByAddress = ({
  id,
  addressId,
}: {
  id: string
  addressId: number
}) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) =>
      shipment?.availableMilestones?.filter(
        (availableMilestone: AvailableMilestone) => availableMilestone.addressId === addressId
      )?.[0]?.milestones
  )

export const selectIsShipmentActive = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.active
  )

export const selectShipmentStatus = (state: RootState, id: string) =>
  state.shipment.statuses[id]?.shipment
export const selectTimelineStatus = (state: RootState, id: string) =>
  state.shipment.statuses[id]?.timeline
export const selectSegmentTimelinesStatus = (state: RootState, id: string) =>
  state.shipment.statuses[id]?.segmentTimelines
export const selectAlertsStatus = (state: RootState, id: string) =>
  state.shipment.statuses[id]?.alerts
export const selectEmissionsStatus = (state: RootState, id: string) =>
  state.shipment.statuses[id]?.emissions
export const selectDocumentUploadProgresses = (state: RootState) =>
  state.shipment.documentUploadProgresses

export const selectShipmentColor = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectShipment(state, id),
    (shipment) => shipment?.color
  )

export default shipmentSlice.reducer
