import styled, { css } from 'styled-components'

import { PageContentMaxWidth, PagePadding } from 'components/page/style'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { shadowMedium } from 'styles/utils/shadow'
import { text14Regular } from 'styles/utils/texts'

const Users = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  height: 100%;
  margin-bottom: 40px;
  padding-right: 20px;
`

const User = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${borderRadiusMedium}
  ${shadowMedium}
  padding: 15px 30px;
  ${text14Regular}
  color: ${({ theme }) => theme.textDark};
  background-color: ${({ theme }) => theme.white};
  max-width: ${PageContentMaxWidth};
  gap: 20px;
`

const columnSize = css<{ $small?: boolean }>`
  flex-basis: ${({ $small }) => ($small ? '10%' : '15%')};
`

const Column = styled.div<{ $small?: boolean; $centered?: boolean }>`
  ${columnSize}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  flex-grow: 1;
  ${({ $centered }) =>
    $centered &&
    css`
      display: flex;
      justify-content: center;
      align-content: center;
      padding-right: 0;
    `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px 10px;
  gap: 20px;
`

const LegendItem = styled.div<{ $small?: boolean; $centered?: boolean }>`
  ${text14Regular}
  color: ${({ theme }) => theme.textLight};
  ${columnSize}
  ${({ $centered }) => $centered && 'text-align:center;'}
  flex-grow: 1
`

const Sidebar = styled.div`
  flex-grow: 1;
`

const Header = styled.div`
  max-width: ${PageContentMaxWidth};
  margin-right: ${PagePadding};
`

const S = {
  Column,
  Header,
  Content,
  Legend,
  LegendItem,
  User,
  Users,
  Sidebar,
}

export default S
