import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { LOCALES, LOCALE_EN } from 'constants/locales'

import frLocales from 'views/locales/fr.json'
import enLocales from 'views/locales/en.json'

const resources = {
  en: { translation: enLocales },
  fr: { translation: frLocales },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: LOCALE_EN,
  interpolation: {
    escapeValue: false,
  },
  react: {
    // Triggers a rerender when a resource bundle is added
    bindI18nStore: 'added',
  },
})

export default i18n

export const loadStaticTranslations = (locales) => {
  LOCALES.forEach((locale) =>
    i18n.addResourceBundle(locale, 'translation', { static: locales[locale] }, false, true)
  )
}
