import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Page from 'components/page'
import SearchBar from 'views/trusted_routes/components/search_bar'
import {
  selectDirection,
  selectSortBy,
  selectTrustedRoutes,
  selectTrustedRoutesStatus,
  SORT_BY_DEPARTURE_AT,
  SORT_BY_ARRIVAL_AT,
  SORT_BY_TRANSIT_TIME,
  SORT_BY_RELIABILITY,
  SORT_BY_SUSTAINABILITY,
  SORT_BY_RATE,
  RATE_20_DRY,
  RATE_40_DRY,
  RATE_40_HC,
  selectDisplayRateType,
} from 'views/trusted_routes/slice'
import S from 'views/trusted_routes/style'
import Item from 'views/trusted_routes/components/item'
import Filters from 'views/trusted_routes/components/filters'
import { STATUS_UNSTARTED, STATUS_PENDING, STATUS_FULFILLED } from 'constants/api'
import Loader from 'views/trusted_routes/components/loader'
import Header from 'views/trusted_routes/components/header'
import { DIRECTION_ASCENDING } from 'views/shipments/slice'
import { sortBy as arraySortBy, reverse as arrayReverse } from 'services/helpers/values'
import { TrustedRoute as TrustedRouteType } from 'views/trusted_routes/types/trusted_route'
import useBreakpoints from 'services/hooks/use_breakpoints'
import FiltersContext from 'views/trusted_routes/contexts/filters'
import useOnce from 'services/hooks/use_once'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { trustedRouteProfile } from 'views/iam/slices/iamSlice'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import LoadingView from 'views/loading'

const TrustedRoute: React.FC = () => {
  const { t } = useTranslation()
  const status = useSelector(selectTrustedRoutesStatus)
  const allTrustedRoutes = useSelector(selectTrustedRoutes)
  const direction = useSelector(selectDirection)
  const sortBy = useSelector(selectSortBy)
  const displayRateType = useSelector(selectDisplayRateType)
  const dispatch = useAppDispatch()
  const user = useCurrentUser()

  const sort = {
    [SORT_BY_DEPARTURE_AT]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.legs[0].departure.date]),
    [SORT_BY_ARRIVAL_AT]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.legs.slice(-1)[0].departure.date]),
    [SORT_BY_TRANSIT_TIME]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.transitTime]),
    [SORT_BY_RELIABILITY]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.reliability?.notation || 'F']),
    [SORT_BY_SUSTAINABILITY]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.emission?.co2E || Infinity]),
    [SORT_BY_RATE]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [
        (route) => {
          let rate
          switch (displayRateType) {
            case RATE_20_DRY:
              rate = route.bestRate20Dry?.rate20Dry
              break
            case RATE_40_DRY:
              rate = route.bestRate40Dry?.rate40Dry
              break
            case RATE_40_HC:
              rate = route.bestRate40HC?.rate40Hc
              break
            default:
              rate = null
              break
          }
          return rate || Infinity
        },
      ]),
  }[sortBy]

  const visibleTrustedRoutes = allTrustedRoutes.filter((trustedRoute) => trustedRoute.visible)
  const trustedRoutes =
    direction === DIRECTION_ASCENDING
      ? sort(visibleTrustedRoutes)
      : arrayReverse(sort(visibleTrustedRoutes))

  const { isDesktopLarge } = useBreakpoints()
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false)

  useEffect(() => {
    setIsFiltersVisible(isDesktopLarge)
  }, [isDesktopLarge, setIsFiltersVisible])

  useOnce(() => {
    if (!user.trustedRouteSettings) {
      dispatch(trustedRouteProfile())
    }
  })

  return (
    <Page plain>
      <FiltersContext.Provider
        value={{ visible: isFiltersVisible, setVisible: setIsFiltersVisible }}
      >
        {!user.trustedRouteSettings ? (
          <LoadingView />
        ) : (
          <S.TrustedRoutePageContainer>
            <SearchBar />

            <S.Header>
              {status === STATUS_PENDING && <Loader />}
              {status === STATUS_FULFILLED && allTrustedRoutes.length === 0 && (
                <S.NoResult>{t('trustedRoutes.noResult')}</S.NoResult>
              )}
              {status !== STATUS_UNSTARTED && allTrustedRoutes.length > 0 && (
                <Header totalCount={visibleTrustedRoutes.length} />
              )}
            </S.Header>

            {status !== STATUS_UNSTARTED && allTrustedRoutes.length > 0 && (
              <S.Content>
                <Filters trustedRoutes={allTrustedRoutes} />
                <S.List>
                  {trustedRoutes.map((trustedRoute, index) => (
                    <Item key={trustedRoute.token} trustedRoute={trustedRoute} index={index} />
                  ))}
                </S.List>
              </S.Content>
            )}
          </S.TrustedRoutePageContainer>
        )}
      </FiltersContext.Provider>
    </Page>
  )
}

export default TrustedRoute
