const useArrivalForecast = () => {
  const arrivalForecastMapping = [
    {
      value: -1,
      operator: 'lt',
      state: {
        early: true,
        onTime: false,
        delayed: false,
      },
    },
    {
      value: 0,
      operator: 'eq',
      state: {
        early: false,
        onTime: true,
        delayed: false,
      },
    },
    {
      value: 1,
      operator: 'gt',
      state: {
        early: false,
        onTime: false,
        delayed: true,
      },
    },
    {
      value: 0,
      operator: 'lt',
      state: {
        early: true,
        onTime: true,
        delayed: false,
      },
    },
    {
      value: 0,
      operator: 'gt',
      state: {
        early: false,
        onTime: true,
        delayed: true,
      },
    },
    {
      value: 0,
      operator: 'neq',
      state: {
        early: true,
        onTime: false,
        delayed: true,
      },
    },
  ]

  return {
    find: (filters) => {
      const early = filters.find(({ name }) => name === 'arrivalForecastEarly')?.value
      const onTime = filters.find(({ name }) => name === 'arrivalForecastOnTime')?.value
      const delayed = filters.find(({ name }) => name === 'arrivalForecastDelayed')?.value
      return (
        arrivalForecastMapping.find(
          ({ state: s }) => s.early === early && s.onTime === onTime && s.delayed === delayed
        ) || { state: { early: false, onTime: false, delayed: false }, value: null, operator: null }
      )
    },
  }
}

export default useArrivalForecast
