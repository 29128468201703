import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useQueryParams = () => {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  return Object.fromEntries(Array.from(query))
}

export default useQueryParams
