export const LOCATION_TYPE_PORT = 'port'
export const LOCATION_TYPE_AIRPORT = 'airport'
export const LOCATION_TYPE_INLAND = 'inland'
export const LOCATION_TYPE_ADDRESS = 'address'
export const LOCATION_TYPE_COMPANY = 'company'
export const LOCATION_TYPES = [
  LOCATION_TYPE_PORT,
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_INLAND,
  LOCATION_TYPE_ADDRESS,
  LOCATION_TYPE_COMPANY,
] as const
export type LocationTypes =
  | typeof LOCATION_TYPE_PORT
  | typeof LOCATION_TYPE_AIRPORT
  | typeof LOCATION_TYPE_INLAND
  | typeof LOCATION_TYPE_ADDRESS
  | typeof LOCATION_TYPE_COMPANY
export const POL_POD_TYPES = [LOCATION_TYPE_PORT, LOCATION_TYPE_AIRPORT] as const
export const TRANSPORT_TYPE_ROAD = 'road'
export const TRANSPORT_TYPE_SEA = 'sea'
export const TRANSPORT_TYPE_AIR = 'air'
export const TRANSPORT_TYPE_RAIL = 'rail'
export const TRANSPORT_TYPE_PARCEL = 'parcel'

export const VEHICLE_TYPE_TRUCK = 'truck'
export const VEHICLE_TYPE_VESSEL = 'vessel'
export const VEHICLE_TYPE_PLANE = 'plane'
export const VEHICLE_TYPE_TRAIN = 'train'
export const VEHICLE_TYPE_BARGE = 'barge'
export const VEHICLE_TYPE_PARCEL = 'parcel'

export const TRUCK_ICON = 'truck'
export const VESSEL_ICON = 'vessel'
export const PLANE_ICON = 'plane'
export const TRAIN_ICON = 'train'
export const BARGE_ICON = 'barge'
export const PARCEL_ICON = 'parcel'

export type VehicleIcon =
  | typeof TRUCK_ICON
  | typeof VESSEL_ICON
  | typeof PLANE_ICON
  | typeof TRAIN_ICON
  | typeof BARGE_ICON
  | typeof PARCEL_ICON

export const TRANSPORT_TYPE_ICONS = {
  [TRANSPORT_TYPE_SEA]: VESSEL_ICON,
  [TRANSPORT_TYPE_RAIL]: TRAIN_ICON,
  [TRANSPORT_TYPE_AIR]: PLANE_ICON,
  [TRANSPORT_TYPE_ROAD]: TRUCK_ICON,
  [TRANSPORT_TYPE_PARCEL]: PARCEL_ICON,
} as const

// Warning: order is important here
export const VEHICLE_TYPES = [
  VEHICLE_TYPE_TRUCK,
  VEHICLE_TYPE_VESSEL,
  VEHICLE_TYPE_PLANE,
  VEHICLE_TYPE_TRAIN,
  VEHICLE_TYPE_BARGE,
  VEHICLE_TYPE_PARCEL,
] as const

export type VehicleType = typeof VEHICLE_TYPES[number]

export const FACILITY_TYPE_INLAND = 'inland' as const
export const FACILITY_TYPE_AIRPORT = 'airport'
export const FACILITY_TYPE_DEPOT = 'depot'
export const FACILITY_TYPE_TRAIN_STATION = 'train_station'
export const FACILITY_TYPE_PORT = 'port'

export const FACILITY_TYPES = [
  FACILITY_TYPE_INLAND,
  FACILITY_TYPE_AIRPORT,
  FACILITY_TYPE_DEPOT,
  FACILITY_TYPE_TRAIN_STATION,
  FACILITY_TYPE_PORT,
] as const

export type FacilityType =
  | typeof FACILITY_TYPE_INLAND
  | typeof FACILITY_TYPE_AIRPORT
  | typeof FACILITY_TYPE_DEPOT
  | typeof FACILITY_TYPE_TRAIN_STATION
  | typeof FACILITY_TYPE_PORT

// Warning: order is important here
export const TRANSPORT_TYPES = [
  TRANSPORT_TYPE_ROAD,
  TRANSPORT_TYPE_SEA,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_RAIL,
  TRANSPORT_TYPE_PARCEL,
] as const
export type TransportType = typeof TRANSPORT_TYPES[number]

export const MILESTONE_TIME_TYPE_ACTUAL = 'actual' as const
export const MILESTONE_TIME_TYPE_ESTIMATED = 'estimated' as const
export const MILESTONE_TIME_TYPE_PLANNED = 'planned' as const
export const MILESTONE_TIME_TYPES = [
  MILESTONE_TIME_TYPE_ACTUAL,
  MILESTONE_TIME_TYPE_ESTIMATED,
  MILESTONE_TIME_TYPE_PLANNED,
] as const

export type MilestoneTimeType = typeof MILESTONE_TIME_TYPES[number]

export const STEP_TYPE_PICKUP = 'pickup'
export const STEP_TYPE_POL = 'pol'
export const STEP_TYPE_POD = 'pod'
export const STEP_TYPE_DELIVERY = 'delivery'

// Warning: order is important here
export const SUMMARY_STEP_TYPES = [
  STEP_TYPE_PICKUP,
  STEP_TYPE_POL,
  STEP_TYPE_POD,
  STEP_TYPE_DELIVERY,
] as const
export type SummaryStepType = typeof SUMMARY_STEP_TYPES[number]

export const STEP_STATUS_INACTIVE = 'inactive'
export const STEP_STATUS_CURRENT = 'current'
export const STEP_STATUS_PAST = 'past'
export const STEP_STATUS_FUTURE = 'future'
export const STEP_STATUSES = [
  STEP_STATUS_INACTIVE,
  STEP_STATUS_CURRENT,
  STEP_STATUS_PAST,
  STEP_STATUS_FUTURE,
] as const

export const SHIPMENT_COLOR_BLUE = 0
export const SHIPMENT_COLOR_GREEN = 1
export const SHIPMENT_COLOR_ORANGE = 2
export const SHIPMENT_COLOR_RED = 3

export const SHIPMENT_COLORS = [
  SHIPMENT_COLOR_BLUE,
  SHIPMENT_COLOR_GREEN,
  SHIPMENT_COLOR_ORANGE,
  SHIPMENT_COLOR_RED,
] as const

export const ETA_DIFF_POD = 'pod'
export const ETA_DIFF_DELIVERY = 'delivery'

export const MARINE_TRAFFIC_URL = 'https://www.marinetraffic.com/en/ais/details/ships/imo:'
export const FLIGHT_AWARE_URL = 'https://fr.flightaware.com/live/flight/'

export const SHIPMENT_VIEW_LARGE = 'large' as const
export const SHIPMENT_VIEW_CONDENSED = 'condensed' as const
export const SHIPMENT_VIEW_MODAL = 'modal' as const
export const SHIPMENT_VIEW_OPTIONS = [
  SHIPMENT_VIEW_LARGE,
  SHIPMENT_VIEW_CONDENSED,
  SHIPMENT_VIEW_MODAL,
] as const

export type ShipmentViewTypes =
  | typeof SHIPMENT_VIEW_LARGE
  | typeof SHIPMENT_VIEW_CONDENSED
  | typeof SHIPMENT_VIEW_MODAL

export const SUBSCRIPTION_INTEGRATION_STATUSES = {
  HOLD: 'awaiting_sane_data',
  ACTIVE: 'active',
  STOPPED: 'stopped',
  DISABLED: 'disabled',
}
export const SUBSCRIPTION_STATUSES = {
  SUBSCRIBING: 'subscribing',
  SUBSCRIBED: 'subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  REFUSED: 'refused',
}
export const SLAPP_SUBSCRIPTION = 'slapp'
export const PARCEL_APP_SUBSCRIPTION = 'parcel_app'

export const DATA_SOURCE_FRONT = 'front' as const
export const DATA_SOURCE_ADMIN = 'admin' as const

export const ADMIN_SOURCES = [DATA_SOURCE_FRONT, DATA_SOURCE_ADMIN] as const
export const ALL_SOURCES = [DATA_SOURCE_FRONT, DATA_SOURCE_ADMIN] as const
export type MilestoneSource = typeof ALL_SOURCES[number]
export type StepSource = typeof ALL_SOURCES[number]
