import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import {
  AirportType,
  CityType,
  DepotType,
  Hub,
  HubType,
  PortType,
  RailStationType,
} from 'views/atlas/types/hub'
import { Status, STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED } from 'constants/api'
import onError from 'services/api/error'
import { selectCurrentUser } from 'views/iam/slices/iamSlice'
import useUrlParams from 'services/api/hooks/use_url_params'
import { AtlasClient } from 'services/api/clients'
import { Tvalue } from 'components/select'

import type { RootState } from 'services/store/store'

export type HubTypeFilter = 'port' | 'airport' | 'railStation' | 'city' | 'depot'
export interface HubActiveFilters {
  countries: string[]
  states: string[]
  search: string
  hubTypeFilters: Record<HubTypeFilter, boolean>
}

interface FetchHubsParams {
  countries: string[]
  states: string[]
  hubTypeFilters: Record<HubTypeFilter, boolean>
  search: string
  page: number
}

const FETCH_HUBS_PER = 50

interface HubIndex {
  totalCount: number
  hubs: Hub[]
}

const hubTypeMappings: Record<HubTypeFilter, HubType> = {
  port: PortType,
  airport: AirportType,
  railStation: RailStationType,
  city: CityType,
  depot: DepotType,
}

const buildHubType = (hubTypeFilters: Record<HubTypeFilter, boolean>): HubType[] => {
  const results = Object.entries(hubTypeFilters).reduce<HubType[]>((acc, [k, v]) => {
    if (v) acc.push(hubTypeMappings[k as HubTypeFilter])
    return acc
  }, [])
  if (results.length === 0) return Object.values(hubTypeMappings)
  return results
}

export const fetchHubs = createAsyncThunk<HubIndex, FetchHubsParams, { state: RootState }>(
  'atlas/hubs/fetchHubs',
  async ({ search, states, hubTypeFilters, countries, page }: FetchHubsParams, thunkAPI) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    const url = useUrlParams('/hubs', {
      q: search,
      states,
      countries,
      hubTypes: buildHubType(hubTypeFilters),
      page,
      per: FETCH_HUBS_PER,
    })
    return AtlasClient.get<HubIndex>(url, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    }).then((r) => r.data)
  }
)

interface HubSummary {
  name: string
  token: string
}

export const fetchHubsSummary = createAsyncThunk<
  HubSummary[],
  { value: Tvalue; hubTypes?: HubType[] | null },
  { state: RootState }
>('atlas/hubs/fetchHubsSummary', async ({ value, hubTypes }, thunkAPI) => {
  const { getState } = thunkAPI
  const user = selectCurrentUser(getState())
  const url = useUrlParams('/hubs', {
    q: value,
    hubTypes: hubTypes || Object.values(hubTypeMappings),
  })
  return AtlasClient.get(url, {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  }).then((r) =>
    r.data.hubs.map((hub: Hub) => ({
      token: hub.token,
      name: `${hub.name} (${hub.type})`,
    }))
  )
})

interface BaseCommandHubParams {
  name: string
  type: HubType
  position: { longitude: number; latitude: number }
  iataCode?: string
  icaoCode?: string
  postalCode?: string | null
  locode?: string | null
  locodeAliases?: string[]
  clusterId: string
}

export type CreateHubParams = BaseCommandHubParams

export interface UpdateHubParams extends BaseCommandHubParams {
  token: string
}

export const createHub = createAsyncThunk<Hub, CreateHubParams, { state: RootState }>(
  'atlas/hubs/createHub',
  async (data, thunkAPI) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    return AtlasClient.post('/hubs', data, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const updateHub = createAsyncThunk<Hub, UpdateHubParams, { state: RootState }>(
  'atlas/hubs/updateHub',
  async ({ token, ...data }, thunkAPI) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    return AtlasClient.put(`/hubs/${token}`, data, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

interface HubInitialState {
  status: Status
  totalCount: number
  activeFilters: HubActiveFilters
}

const hubAdapter = createEntityAdapter<Hub>({
  selectId: ({ token }: Hub) => token,
})

const hubInitialState = hubAdapter.getInitialState<HubInitialState>({
  status: STATUS_PENDING,
  totalCount: 0,
  activeFilters: {
    countries: [],
    states: [],
    search: '',
    hubTypeFilters: {
      port: false,
      airport: false,
      railStation: false,
      city: false,
      depot: false,
    },
  },
})

const hubSlice = createSlice({
  name: 'hubs',
  initialState: hubInitialState,
  reducers: {
    removeAll: hubAdapter.removeAll,
    saveActiveFilters: (state, action) => {
      state.activeFilters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHubs.fulfilled, (state, action) => {
      const { hubs, totalCount } = action.payload
      hubAdapter.addMany(state, hubs)
      state.totalCount = totalCount
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchHubs.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchHubs.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
  },
})

export const { removeAll: removeAllHubs, saveActiveFilters: saveHubsActiveFilters } =
  hubSlice.actions

export const { selectAll: selectHubs } = hubAdapter.getSelectors((state: RootState) => state.hubs)

export const selectHubsStatus = (state: RootState) => state.hubs.status
export const selectHubsTotalCount = (state: RootState) => state.hubs.totalCount
export const selectHubsActiveFilters = (state: RootState) => state.hubs.activeFilters

export default hubSlice.reducer
