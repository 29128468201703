import React from 'react'
import PropTypes from 'prop-types'

import DateHelper from 'services/helpers/date_helper'

import {
  StyledItem,
  StyledItemDate,
  StyledItemText,
  StyledItemTitle,
} from 'components/alert_list_item/style'

const AlertListItem = ({
  declaredAt,
  title,
  details,
}: {
  declaredAt?: string
  title?: string
  details?: string
}) => (
  <StyledItem>
    <StyledItemDate>{new DateHelper(declaredAt).toLocale()}</StyledItemDate>
    <div>
      <StyledItemTitle>{title}</StyledItemTitle>
      <StyledItemText>{details}</StyledItemText>
    </div>
  </StyledItem>
)

AlertListItem.propTypes = {
  /** Declared at */
  declaredAt: PropTypes.string,
  /** Title */
  title: PropTypes.string,
  /** Details */
  details: PropTypes.string,
}

AlertListItem.defaultProps = {
  declaredAt: undefined,
  title: undefined,
  details: undefined,
}

export default AlertListItem
