import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { StyledInput } from 'views/booking/components/form/style'
import {
  fetchConsigneeOptions,
  fetchConsignorOptions,
  fetchForwarderOptions,
  fetchShipperOptions,
  ISearch,
} from 'views/select_options/slice'
import Grid from 'components/grid'
import Form from 'components/form'
import SearchSelect from 'components/search_select'

import { TEST_ID_BOOKING_FORM_SHIPPER_FIELD } from 'tests/e2e/test_ids'

import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const Parties: FC<BookingFormProps> = ({ filters }) => {
  const { shipperFilter, forwarderFilter, consignorFilter, consigneeFilter } = filters
  const { t } = useTranslation()

  const fetchShipperOptionsWithTooltip = (params: ISearch) =>
    fetchShipperOptions({ withTooltip: true, ...params })
  const fetchConsignorOptionsWithTooltip = (params: ISearch) =>
    fetchConsignorOptions({ withTooltip: true, ...params })
  const fetchConsigneeOptionsWithTooltip = (params: ISearch) =>
    fetchConsigneeOptions({ withTooltip: true, ...params })

  return (
    <Form.Group title={t('bookings.parties.title')}>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <StyledInput
              as={SearchSelect}
              label={t('bookings.parties.shipper')}
              action={fetchShipperOptionsWithTooltip}
              name={shipperFilter.name}
              onChange={shipperFilter.onChange}
              value={shipperFilter.value}
              isClearable={shipperFilter.isPresent}
              required={!shipperFilter.isDisabled}
              isDisabled={shipperFilter.isDisabled}
              testId={TEST_ID_BOOKING_FORM_SHIPPER_FIELD}
            />
          </Grid.Column>
          <Grid.Column>
            <StyledInput
              as={SearchSelect}
              label={t('bookings.parties.forwarder')}
              action={fetchForwarderOptions}
              name={forwarderFilter.name}
              onChange={forwarderFilter.onChange}
              value={forwarderFilter.value}
              required={!forwarderFilter.isDisabled}
              isDisabled={forwarderFilter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <StyledInput
              as={SearchSelect}
              label={t('bookings.parties.consignor')}
              action={fetchConsignorOptionsWithTooltip}
              name={consignorFilter.name}
              onChange={consignorFilter.onChange}
              value={consignorFilter.value}
              required={!consignorFilter.isDisabled}
              isDisabled={consignorFilter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <StyledInput
              as={SearchSelect}
              label={t('bookings.parties.consignee')}
              action={fetchConsigneeOptionsWithTooltip}
              name={consigneeFilter.name}
              onChange={consigneeFilter.onChange}
              value={consigneeFilter.value}
              required={!consigneeFilter.isDisabled}
              isDisabled={consigneeFilter.isDisabled}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  )
}

export default Parties
