import React from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'
import useStatus from 'services/api/hooks/use_status'

import { selectNetworkCompaniesStatus } from 'views/network/companies/slice'

import NetworkListItem from 'components/network_list_item'
import StyledNetworkList from 'components/network_list/style'
import NetworkListSkeleton from 'components/network_list/skeleton'
import Placeholder from 'components/placeholder'
import ErrorNotification from 'views/errors/error_notification'

const NetworkList = ({
  children,
  testId,
}: {
  children?: typeof NetworkListItem
  testId: string
}) => {
  const status = useStatus(useSelector(selectNetworkCompaniesStatus))
  return (
    <>
      <StyledNetworkList data-testid={testId}>{children}</StyledNetworkList>
      <Placeholder ready={status.ready} customPlaceholder={<NetworkListSkeleton />}>
        {status.rejected && <ErrorNotification />}
      </Placeholder>
    </>
  )
}

NetworkList.propTypes = {
  /** children */
  children: validateChildrenOfTypes([NetworkListItem]),
  /** testId */
  testId: PropTypes.string,
}

NetworkList.defaultProps = {
  children: undefined,
  testId: undefined,
}
export default NetworkList
