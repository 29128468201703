import React, { useEffect, useRef } from 'react'

import { Controller, useForm } from 'react-hook-form'

import FiltersSidebar from 'components/filters_sidebar'
import Input from 'components/input'
import MultiSelect from 'components/multiselect'

import { saveClustersActiveFilters } from 'views/atlas/slices/cluster'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTimeout from 'services/hooks/use_timeout'
import fetchStates from 'views/atlas/slices/state'
import fetchCountries from 'views/atlas/slices/country'

export interface ClustersForm {
  countries: { value: string; label: string }[]
  states: { value: string; label: string }[]
  search: string
}

const ClusterFilters: React.FC = () => {
  const dispatch = useAppDispatch()
  const { onTimeout } = useTimeout({ timeout: 500 })
  const { handleSubmit, register, control, reset, watch } = useForm<ClustersForm>({
    defaultValues: { countries: [], states: [], search: '' },
  })

  const onSubmit = (data: ClustersForm): void => {
    dispatch(
      saveClustersActiveFilters({
        search: data.search,
        countries: data.countries.map((country) => country.value),
        states: data.states.map((state) => state.value),
      })
    )
  }

  const values = watch()
  const firstUpdate = useRef<boolean>(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    onTimeout(handleSubmit(onSubmit))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.search, JSON.stringify(values.states), JSON.stringify(values.countries)])

  const apply = () => {
    handleSubmit(onSubmit)()
  }

  const clear = () => {
    reset()
    apply()
  }

  return (
    <FiltersSidebar onClear={clear} onApply={apply}>
      <FiltersSidebar.Section title='Search' defaultOpened>
        <Input type='text' placeholder='name or county' {...register('search')} />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section title='Country' defaultOpened>
        <Controller
          name='countries'
          control={control}
          render={({ field }) => (
            <MultiSelect
              name={field.name}
              isSearchable
              async
              value={field.value}
              onChange={({ value }) => field.onChange(value)}
              fetch={({ value }) => fetchCountries({ value })}
              fetchOnFocus={() => fetchCountries({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map(({ name, token }: { name: string; token: string }) => ({
                  value: token,
                  label: name,
                }))
              }
            />
          )}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section title='State' defaultOpened>
        <Controller
          name='states'
          control={control}
          render={({ field }) => (
            <MultiSelect
              name={field.name}
              isSearchable
              async
              value={field.value}
              onChange={({ value }) => field.onChange(value)}
              fetch={({ value }) => fetchStates({ value })}
              fetchOnFocus={() => fetchStates({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map(({ name, token }: { name: string; token: string }) => ({
                  value: token,
                  label: name,
                }))
              }
            />
          )}
        />
      </FiltersSidebar.Section>
    </FiltersSidebar>
  )
}

export default ClusterFilters
