import React, { useContext } from 'react'

import { StyledMenuItem, StyledMenuItemLink } from 'components/menu/menu_item.style'
import MenuContext from 'components/menu/context'
import Link from 'components/link'

import { isPresent } from 'services/helpers/values'

const MenuItem = ({
  text,
  onClick,
  to,
  testId,
  active = false,
}: {
  text?: string
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void
  to?: string
  testId?: string
  active?: boolean
}) => {
  const { closeMenu } = useContext(MenuContext)
  return (
    <StyledMenuItem>
      {isPresent(to) && (
        <StyledMenuItemLink
          $active={active}
          testId={testId}
          as={Link}
          to={to}
          onClick={() => onClick?.()}
        >
          {text}
        </StyledMenuItemLink>
      )}
      {!isPresent(to) && (
        <StyledMenuItemLink
          $active={active}
          data-testid={testId}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()
            closeMenu()
            onClick?.()
          }}
        >
          {text}
        </StyledMenuItemLink>
      )}
    </StyledMenuItem>
  )
}

export default MenuItem
