import React, { useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import useBreakpoints from 'services/hooks/use_breakpoints'

import OrganizationLogo from 'components/organization_logo'
import Navigation from 'components/navigation'
import NavigationItem from 'components/navigation_item'
import Icon from 'components/icon'
import Avatar from 'components/avatar'
import Menu, { MenuItem } from 'components/menu'

// Styles come from header component. SharedHeader is a minimalist version of Header.
import {
  StyledHeader,
  StyledWrapper,
  StyledRightBlock,
  StyledButtonProfile,
  StyledLeftBlock,
  StyledNavigation,
  StyledButtonBurger,
  StyledButtonBurgerOpen,
  StyledButtonBurgerClose,
} from 'components/header/style'
import { routeSignIn } from 'services/helpers/routes'

const SharedHeader = React.memo(({ variant }) => {
  const { isMobile } = useBreakpoints()

  const leftBlockRef = useRef(null)
  const rightBlockRef = useRef(null)
  const wrapperRef = useRef(null)
  const [navOpen, setNavOpen] = useState(false)

  const variantReverse = useMemo(() => (variant === 'light' ? 'dark' : 'light'), [variant])

  const { t } = useTranslation()
  const history = useHistory()

  return (
    <StyledHeader $variant={variant}>
      <StyledWrapper ref={wrapperRef}>
        <StyledLeftBlock as={Link} to='/' ref={leftBlockRef}>
          <OrganizationLogo variant={variantReverse} condensed={isMobile} />
          <StyledButtonBurger $navOpen={navOpen} onClick={() => setNavOpen(!navOpen)}>
            <StyledButtonBurgerOpen as={Icon} name='menu' />
            <StyledButtonBurgerClose as={Icon} name='close' />
          </StyledButtonBurger>
        </StyledLeftBlock>
        <StyledNavigation as={Navigation} isOpenMobile={navOpen}>
          <NavigationItem
            active
            text={t('shipments.title')}
            to={routeSignIn()}
            variant={variantReverse}
          />
        </StyledNavigation>
        <StyledRightBlock ref={rightBlockRef}>
          <StyledButtonProfile>
            <Menu control={<Avatar firstName='w' lastName='a' />}>
              <MenuItem
                text={t('iam.signIn.title')}
                onClick={() => {
                  history.push(routeSignIn())
                }}
              />
            </Menu>
          </StyledButtonProfile>
        </StyledRightBlock>
      </StyledWrapper>
    </StyledHeader>
  )
})

SharedHeader.propTypes = {
  /* variant */
  variant: PropTypes.oneOf(['light', 'dark']),
}

SharedHeader.defaultProps = {
  variant: 'light',
}

export default SharedHeader
