import React from 'react'
import PropTypes from 'prop-types'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import Icon from 'components/icon'
import { StyledShipmentFiltersTag } from 'components/shipment_filters/style'

import {
  StyledSavedFiltersItem,
  StyledSavedFiltersSection,
  StyledSavedFiltersTitle,
  StyledSavedFiltersArrow,
  StyledSavedFiltersTrash,
} from 'components/shipment_filters_saved_item/style'

const ShipmentFiltersSavedItem = ({ label, editMode, children, onClickItem, onClickDelete }) => (
  <StyledSavedFiltersItem as={editMode ? 'div' : 'button'} onClick={onClickItem}>
    <StyledSavedFiltersSection>
      <StyledSavedFiltersTitle>{label}</StyledSavedFiltersTitle>
      <div>{children}</div>
      {!editMode && (
        <StyledSavedFiltersArrow>
          <Icon as={Icon} name='arrow_bottom_outline' />
        </StyledSavedFiltersArrow>
      )}
      {editMode && (
        <StyledSavedFiltersTrash onClick={onClickDelete}>
          <Icon name='trash' />
        </StyledSavedFiltersTrash>
      )}
    </StyledSavedFiltersSection>
  </StyledSavedFiltersItem>
)

ShipmentFiltersSavedItem.propTypes = {
  /** Children */
  children: validateChildrenOfTypes([StyledShipmentFiltersTag]),
  /** Label */
  label: PropTypes.string,
  /** Edit mode */
  editMode: PropTypes.bool,
  /** On click delete */
  onClickDelete: PropTypes.func,
  /** On click delete */
  onClickItem: PropTypes.func,
}

ShipmentFiltersSavedItem.defaultProps = {
  children: undefined,
  label: undefined,
  editMode: false,
  onClickDelete: undefined,
  onClickItem: undefined,
}

export default ShipmentFiltersSavedItem
