import { useSelector } from 'react-redux'

import useCurrentUser from 'views/iam/hooks/use_current_user'
import { selectFirstLeg, selectLastLeg, selectUserReference } from 'views/shipment/slice'

const useSharedOrderMailInfo = ({ id }) => {
  const firstLeg = useSelector(selectFirstLeg({ id }))
  const lastLeg = useSelector(selectLastLeg({ id }))
  const userReference = useSelector(selectUserReference({ id }))
  const {
    profile: { firstName, lastName },
  } = useCurrentUser()

  return {
    fullName: `${firstName} ${lastName}`,
    ref: userReference,
    origin: `${firstLeg.departure.name}, ${firstLeg.departure.countryCode}`,
    destination: `${lastLeg.arrival.name}, ${lastLeg.arrival.countryCode}`,
    eta: lastLeg.apiAta || lastLeg.apiEta || 'n/a',
  }
}

export default useSharedOrderMailInfo
