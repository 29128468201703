import styled from 'styled-components'

import { text14Regular, text16Regular, textSmallDescription } from 'styles/utils/texts'
import { tablet } from 'styles/utils/breakpoints'

export const StyledModaleTimelineSegmentLocation = styled.div`
  display: flex;
  align-items: center;
  ${text16Regular}
`

export const StyledModalTimelineSegment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const StyledModalTimelineStepWrapper = styled.div`
  min-height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0;
  gap: 2px;
`

const StyledModalTimelineStepHeader = styled.span`
  color: ${({ theme }) => theme.textLight};
  ${textSmallDescription};
  min-width: 130px;
  align-items: center;
  justify-content: center;
  @media ${tablet.down} {
    margin-bottom: 15px;
  }
`

export const StyledModalTimelineStepHeaderType = styled(StyledModalTimelineStepHeader)`
  flex-basis: 40%;
`

export const StyledModalTimelineStepHeaderLocation = styled(StyledModalTimelineStepHeader)`
  flex-basis: 60%;
`

export const StyledModalTimelineStepTypeWrapper = styled.div`
  flex-basis: 40%;
  display: flex;
  height: 100%;
  min-width: 130px;
  align-items: center;
  justify-content: flex-start;
  @media ${tablet.down} {
    margin-bottom: 15px;
  }
`

export const StyledModalTimelineStepLocationWrapper = styled.div`
  flex-basis: 40%;
`

export const StyledModalTimelineStepType = styled.span`
  color: ${({ theme }) => theme.textDark};
  ${text14Regular};
`

export const StyledEditedByAdminTextInfo = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.primary};
`

export const StyledModalTimelineStepEditActionsWrapper = styled.div`
  flex-basis: 20%;
  height: 100%;
  min-width: 62px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledModalTimelineStepEditActionButtonWrapper = styled.div`
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledModalTimelineMilestoneEditActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
`

export const StyledModalTimelineCreateMilestoneWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyleModalTimelineLegsWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`

export const StyledAddStopoverWrapper = styled.div`
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledModalFooterContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const StyledModalFooterButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`
