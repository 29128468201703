import React from 'react'
import PropTypes, { number, shape, string } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { StyledInfoIcon, StyledShipmentReportedInfo, StyledText } from 'views/shipment/style'
import IconTooltip from 'components/icon_tooltip'
import useUserLocale from 'views/iam/hooks/use_user_locale'

const localeMap = {
  en: 'messageEn',
  fr: 'messageFr',
}

const ShipmentReportedInfo = ({ reportedReasons }) => {
  const { t } = useTranslation()

  const userLocale = useUserLocale()

  const messageField = localeMap[userLocale]

  const reasons = reportedReasons.map(({ flagReason }) => flagReason[messageField])

  return (
    <StyledShipmentReportedInfo>
      <StyledText>{t('shipments.reportedShipment')}</StyledText>
      <StyledInfoIcon>
        <IconTooltip
          placement='bottom'
          size='large'
          width={25}
          height={25}
          content={reasons.map((reason) => (
            <div>{reason}</div>
          ))}
        />
      </StyledInfoIcon>
    </StyledShipmentReportedInfo>
  )
}

ShipmentReportedInfo.propTypes = {
  /** reportedReasons array */
  reportedReasons: PropTypes.arrayOf(
    shape({
      flagReason: shape({ id: number, messageEn: string, messageFr: string }),
      flagReasonId: number,
      id: number,
    })
  ),
}

ShipmentReportedInfo.defaultProps = {
  reportedReasons: [],
}

export default ShipmentReportedInfo
