import React from 'react'

import { useTranslation } from 'react-i18next'

import Icon from 'components/icon'
import {
  StyledWidgetsCarbonfootprint,
  StyledWidgetTotal,
  StyledWidgetTotalLabel,
  StyledWidgetDetails,
  StyledWidgetDetail,
  StyledWidgetIcon,
  StyledWidgetsIteDetailText,
  StyledWidgetTotalIcon,
} from 'components/widget_carbonfootprint/style'

interface WidgetCarbonFootprintProps {
  total: string | number
  date: string
}

const WidgetCarbonFootprint: React.FC<WidgetCarbonFootprintProps> = ({ total, date }) => {
  const { t } = useTranslation()

  return (
    <StyledWidgetsCarbonfootprint>
      <StyledWidgetDetails>
        <StyledWidgetTotalLabel>{t('customKpis.carbonTons')}</StyledWidgetTotalLabel>
        <StyledWidgetTotal>
          <StyledWidgetTotalIcon as={Icon} name='ecology' />
          {total}
        </StyledWidgetTotal>
        <StyledWidgetDetail>
          <StyledWidgetIcon as={Icon} name='calendar' />
          <StyledWidgetsIteDetailText>{date}</StyledWidgetsIteDetailText>
        </StyledWidgetDetail>
      </StyledWidgetDetails>
    </StyledWidgetsCarbonfootprint>
  )
}

export default WidgetCarbonFootprint
