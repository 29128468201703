import React, { FC, memo, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { updateSettings } from 'views/iam/slices/iamSlice'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import useUserCan from 'views/iam/hooks/use_user_can'
import { addNotification } from 'views/notifications/slice'
import useCurrentUser from 'views/iam/hooks/use_current_user'

import Select, { OnChangeFn } from 'components/select'
import Tabs, { Tab } from 'components/tabs'
import InputCheckbox from 'components/input_checkbox'
import ModalHorizontal from 'components/modal_horizontal'
import Grid from 'components/grid'
import Form from 'components/form'
import { StyledModalSettingsForm, StyledP } from 'components/modal_settings/style'
import useNavigation from 'components/modal_settings/hooks/use_navigation'
import IconTooltip from 'components/icon_tooltip'
import SwitchInput from 'components/switch_input'

import useTracker from 'services/analytics/hooks/use_tracker'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import { WAKEO_REFERENCE } from 'constants/organization_display_settings'
import { ETA_DIFF_DELIVERY, ETA_DIFF_POD } from 'constants/shipments'
import { ORDER_ADMIN, USER_UPDATE } from 'constants/permissions'
import { WITH_PARCEL_TRACKING } from 'constants/organization_features'

import { isNull } from 'services/helpers/values'

import {
  TEST_ID_USERS_SAVE_USER_BUTTON,
  TEST_ID_USERS_SETTINGS_INFORMATION_TAB,
} from 'tests/e2e/test_ids'

import Button from 'components/button'

import UserForm from 'views/users/components/form'
import useUserForm from 'views/users/components/form/hooks/use_user_form'

import { updateUser, toggleNotifications } from 'views/users/slice'

import { StyledModalFooter } from 'components/modal/style'

import useModal from 'components/modal/hooks/use_modal'

import { User } from 'views/users/types'

import type { Settings } from 'views/iam/types'

interface ModalSettingsProps {
  modalName: 'userIndexSettings' | 'settings'
  settings?: Settings
  user?: User
  onClose?: () => void
  onSave?: () => void
}

// TODO: REFACTOR:
// File is too big
// - split into subcomponents
// - SRP ?
// - centralize value references and attributes ?
const ModalSettings: FC<ModalSettingsProps> = ({
  modalName,
  settings: userSettings,
  user,
  onClose,
  onSave,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isAlerts } = useNavigation()
  const currentUser = useCurrentUser()
  const [userInfo, setUserInfo] = useState(`${user?.firstName} ${user?.lastName} (${user?.id})`)
  const [settings, setSettings] = useState(userSettings)
  const userCan = useUserCan()

  const isCurrentUser = isNull(user) || isNull(user?.id)
  const userValues = isCurrentUser
    ? {
        id: currentUser.id,
        settings: currentUser.profile.settings,
        notifiable: true,
      }
    : { id: user.id!, settings: settings!, notifiable: user.notifiable! }

  const [notifiable, setNotifiable] = useState(userValues.notifiable)

  let defaultActiveTab = isAlerts ? 'alerts' : 'information'

  if (isCurrentUser || !userCan(USER_UPDATE)) {
    defaultActiveTab = 'content'
  }

  const {
    referenceDisplay,
    secondaryReferenceDisplay,
    shipmentReference,
    refUser,
    refForwarder,
    flowType,
    carrier,
    forwarder,
    finalClient,
    consignor,
    clientBookingNumber,
    weight,
    volume,
    products,
    recap,
    pickUp,
    delivery,
    bookingDelayed,
    bookingConfirmed,
    flagged,
    seaEtdChange,
    seaEtaChange,
    seaGateIn,
    seaLoaded,
    seaDeparture,
    seaArrival,
    seaUnloaded,
    seaGateOut,
    roadEtdChange,
    roadEtaChange,
    roadTruckDeparture,
    roadTruckArrival,
    airEtdChange,
    airEtaChange,
    airBooked,
    airReceivedFromShipper,
    airManifestedOnFlight,
    airDeparture,
    airArrival,
    airReceivedFromFlight,
    airDelivered,
    parcelPickup,
    parcelDelivered,
    shipmentIndexDisplayConsignee,
    shipmentIndexDisplayConsignor,
    shipmentIndexDisplayShipper,
    shipmentIndexDisplayForwarder,
    shipmentIndexDisplayMainReference,
    shipmentIndexDisplayOrganization,
    shipmentIndexDisplaySecondaryReference,
    etaDifference,
  } = userValues.settings
  const { features, display } = useOrganizationCan()

  const update = ({ name, value }: { name: keyof Settings; value: Settings[keyof Settings] }) => {
    const newSettings = { ...userValues.settings, [name]: value }
    dispatch(updateSettings({ id: userValues.id, settings: newSettings }))
      .unwrap()
      .then(() => {
        setSettings(newSettings)
        dispatch(
          addNotification({
            type: 'success',
            title: t('settings.request.title'),
            text: t('settings.request.successMessage'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('settings.request.title'),
            text: t('settings.request.errorMessage'),
          })
        )
      })
  }
  const onSelect: OnChangeFn = (option) => {
    const name = option.name as keyof Settings
    const value = option.value.value as Settings[keyof Settings]
    update({ name, value })
  }
  const onCheck = (e: SyntheticEvent) => {
    const { checked: value } = e.target as HTMLInputElement
    const name = (e.target as HTMLInputElement).name as keyof Settings
    update({ name, value })
  }
  const onToggle = ({ name, value }: { name: keyof Settings; value: Settings[keyof Settings] }) =>
    update({ name, value })

  const seaEtChangeOptions = [
    {
      value: 0,
      label: t('settings.alerts.eventTriggers.timeOptions.never'),
    },
    {
      value: 1,
      label: t('settings.alerts.eventTriggers.timeOptions.oneDay'),
    },
    {
      value: 2,
      label: t('settings.alerts.eventTriggers.timeOptions.twoDays'),
    },
    {
      value: 3,
      label: t('settings.alerts.eventTriggers.timeOptions.threeDays'),
    },
    {
      value: 5,
      label: t('settings.alerts.eventTriggers.timeOptions.fiveDays'),
    },
    {
      value: 7,
      label: t('settings.alerts.eventTriggers.timeOptions.sevenDays'),
    },
    {
      value: 100,
      label: t('settings.alerts.eventTriggers.timeOptions.always'),
    },
  ]

  const roadEtchangeOptions = [
    {
      value: 0,
      label: t('settings.alerts.eventTriggers.timeOptions.never'),
    },
    {
      value: 1,
      label: t('settings.alerts.eventTriggers.timeOptions.oneHour'),
    },
    {
      value: 2,
      label: t('settings.alerts.eventTriggers.timeOptions.twoHours'),
    },
    {
      value: 3,
      label: t('settings.alerts.eventTriggers.timeOptions.threeHours'),
    },
    {
      value: 4,
      label: t('settings.alerts.eventTriggers.timeOptions.fourHours'),
    },
    {
      value: 8,
      label: t('settings.alerts.eventTriggers.timeOptions.eightHours'),
    },
    {
      value: 12,
      label: t('settings.alerts.eventTriggers.timeOptions.twelveHours'),
    },
    {
      value: 24,
      label: t('settings.alerts.eventTriggers.timeOptions.oneDay'),
    },
    {
      value: 100,
      label: t('settings.alerts.eventTriggers.timeOptions.always'),
    },
  ]
  const airEtchangeOptions = [
    {
      value: 0,
      label: t('settings.alerts.eventTriggers.timeOptions.never'),
    },
    {
      value: 1,
      label: t('settings.alerts.eventTriggers.timeOptions.oneHour'),
    },
    {
      value: 2,
      label: t('settings.alerts.eventTriggers.timeOptions.twoHours'),
    },
    {
      value: 3,
      label: t('settings.alerts.eventTriggers.timeOptions.threeHours'),
    },
    {
      value: 4,
      label: t('settings.alerts.eventTriggers.timeOptions.fourHours'),
    },
    {
      value: 8,
      label: t('settings.alerts.eventTriggers.timeOptions.eightHours'),
    },
    {
      value: 12,
      label: t('settings.alerts.eventTriggers.timeOptions.twelveHours'),
    },
    {
      value: 24,
      label: t('settings.alerts.eventTriggers.timeOptions.oneDay'),
    },
    {
      value: 100,
      label: t('settings.alerts.eventTriggers.timeOptions.always'),
    },
  ]

  const references = [
    {
      value: 0,
      label: t('settings.content.references.clientReference'),
      requiredFeatures: [],
    },
    {
      value: 1,
      label: t('settings.content.references.forwarderReference'),
      requiredFeatures: [],
    },
    {
      value: 2,
      label: t('settings.content.references.shipmentReference'),
      requiredFeatures: [],
    },
    {
      value: 3,
      label: t('settings.content.references.wakeoReference'),
      requiredFeatures: [display(WAKEO_REFERENCE)],
    },
    {
      value: 4,
      label: t('settings.content.references.clientBookingNumber'),
      requiredFeatures: [],
    },
  ]

  const referenceDisplayOptions = references
    .filter(({ requiredFeatures }) => requiredFeatures.every((f) => f))
    .map(({ value, label }) => ({ value, label }))

  const { track } = useTracker()
  const { isValid, filters, userPayload } = useUserForm(user)
  const { setOpen } = useModal(modalName)

  const saveUser = () => {
    if (!user) {
      return
    }

    const payload = {
      user: {
        id: user.id,
        ...userPayload,
      },
    }

    dispatch(updateUser(payload))
      .unwrap()
      .then(() => {
        setUserInfo(`${payload.user.firstName} ${payload.user.lastName} (${payload.user.id})`)
        dispatch(
          addNotification({
            type: 'success',
            title: t('users.alerts.save.title'),
            text: t('users.alerts.save.successMessage'),
          })
        )
        return onSave && onSave()
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  return (
    <ModalHorizontal
      title={
        isCurrentUser
          ? t('settings.title')
          : t('settings.settingsFor', {
              userInfo,
            })
      }
      subtitle={user?.firstName}
      size='large'
      modalName={modalName}
      onClose={onClose}
    >
      <StyledModalSettingsForm>
        <Tabs
          defaultTabId={defaultActiveTab}
          onTabChange={({ id: tabId }) => {
            track('User / Settings', { detail: tabId })
          }}
        >
          {!isCurrentUser && userCan(USER_UPDATE) && (
            <Tab
              testId={TEST_ID_USERS_SETTINGS_INFORMATION_TAB}
              id='information'
              label={t('settings.information.title')}
              key='settings-information-tab'
            >
              <UserForm filters={filters} />
              <StyledModalFooter>
                <Button onClick={() => setOpen(false)} variant='clear' text={t('actions.cancel')} />
                <Button
                  testId={TEST_ID_USERS_SAVE_USER_BUTTON}
                  disabled={!isValid}
                  variant='highlight'
                  text={t('actions.save')}
                  onClick={saveUser}
                />
              </StyledModalFooter>
            </Tab>
          )}
          <Tab id='content' label={t('settings.content.title')} key='settings-content-tab'>
            <Form>
              <Form.Insert title={t('settings.content.reference')}>
                <StyledP>{t('settings.content.details')}</StyledP>
                <Form.Group>
                  <Grid.Row>
                    <Grid.Column>
                      <Select
                        name='referenceDisplay'
                        label={t('settings.content.mainReference')}
                        options={referenceDisplayOptions}
                        onChange={onSelect}
                        isClearable={false}
                        isSearchable={false}
                        value={referenceDisplayOptions.find(
                          ({ value }) => value === referenceDisplay
                        )}
                        labelInfo={t('settings.content.mainReferenceDetails')}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Select
                        name='secondaryReferenceDisplay'
                        label={t('settings.content.secondaryReference')}
                        options={referenceDisplayOptions}
                        onChange={onSelect}
                        isClearable={false}
                        isSearchable={false}
                        value={referenceDisplayOptions.find(
                          ({ value }) => value === secondaryReferenceDisplay
                        )}
                        labelInfo={t('settings.content.secondaryReferenceDetails')}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Form.Group>
              </Form.Insert>
              <br />
              <Form.Insert title={t('settings.content.display')}>
                <StyledP>{t('settings.content.displayDetails')}</StyledP>
                <Form.Group>
                  <Grid.Row>
                    <Grid.Column>
                      <InputCheckbox
                        id='shipmentIndexDisplayMainReference'
                        name='shipmentIndexDisplayMainReference'
                        text={t('shipments.references.mainReference')}
                        onChange={onCheck}
                        checked={shipmentIndexDisplayMainReference}
                      />
                      <InputCheckbox
                        id='shipmentIndexDisplayShipper'
                        name='shipmentIndexDisplayShipper'
                        text={t('shipments.parties.shipper')}
                        onChange={onCheck}
                        checked={shipmentIndexDisplayShipper}
                      />
                      <InputCheckbox
                        id='shipmentIndexDisplayConsignor'
                        name='shipmentIndexDisplayConsignor'
                        text={t('shipments.parties.consignor')}
                        onChange={onCheck}
                        checked={shipmentIndexDisplayConsignor}
                      />
                      {userCan(ORDER_ADMIN) ? (
                        <InputCheckbox
                          id='shipmentIndexDisplayOrganization'
                          name='shipmentIndexDisplayOrganization'
                          text={t('shipments.organization')}
                          onChange={onCheck}
                          checked={shipmentIndexDisplayOrganization}
                        />
                      ) : null}
                    </Grid.Column>
                    <Grid.Column>
                      <InputCheckbox
                        id='shipmentIndexDisplaySecondaryReference'
                        name='shipmentIndexDisplaySecondaryReference'
                        text={t('shipments.references.secondaryReference')}
                        onChange={onCheck}
                        checked={shipmentIndexDisplaySecondaryReference}
                      />
                      <InputCheckbox
                        id='shipmentIndexDisplayForwarder'
                        name='shipmentIndexDisplayForwarder'
                        text={t('shipments.parties.forwarder')}
                        onChange={onCheck}
                        checked={shipmentIndexDisplayForwarder}
                      />
                      <InputCheckbox
                        id='shipmentIndexDisplayConsignee'
                        name='shipmentIndexDisplayConsignee'
                        text={t('shipments.parties.consignee')}
                        onChange={onCheck}
                        checked={shipmentIndexDisplayConsignee}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <StyledP>
                        {t('settings.content.etaDifferenceDetails')}
                        <IconTooltip
                          content={t('settings.content.etaDifferenceTips')}
                          size='large'
                          inline
                        />
                      </StyledP>
                      <SwitchInput
                        leftLabel={t('settings.content.etaDifference.pod')}
                        name='etaDifference'
                        rightLabel={t('settings.content.etaDifference.delivery')}
                        rightOptionChecked={etaDifference === ETA_DIFF_DELIVERY}
                        onClick={(checked) => {
                          onToggle({
                            name: 'etaDifference',
                            value: checked ? ETA_DIFF_DELIVERY : ETA_DIFF_POD,
                          })
                        }}
                        variant='outline'
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Form.Group>
              </Form.Insert>
            </Form>
          </Tab>
          <Tab id='alerts' label={t('settings.alerts.title')} key='settings-alerts-tab'>
            <Form>
              {!isCurrentUser && (
                <Form.Insert title={t('settings.alerts.notifications.title')}>
                  <StyledP>{t('settings.alerts.notifications.details')}</StyledP>
                  <Form.Group>
                    <SwitchInput
                      leftLabel='OFF'
                      name='notifications'
                      rightLabel='ON'
                      rightOptionChecked={notifiable}
                      onClick={() => {
                        dispatch(toggleNotifications({ id: userValues.id }))
                          .unwrap()
                          .then(() => {
                            setNotifiable(!notifiable)
                            dispatch(
                              addNotification({
                                type: 'success',
                                title: t('settings.request.title'),
                                text: t('settings.request.successMessage'),
                              })
                            )
                          })
                          .catch(() => {
                            dispatch(
                              addNotification({
                                type: 'alert',
                                title: t('settings.request.title'),
                                text: t('settings.request.errorMessage'),
                              })
                            )
                          })
                      }}
                      variant='outline'
                    />
                  </Form.Group>
                </Form.Insert>
              )}
              <Form.Insert title={t('settings.alerts.information.title')}>
                <StyledP>{t('settings.alerts.information.details')}</StyledP>
                <Form.Group>
                  <Grid.Row>
                    <Grid.Column>
                      <InputCheckbox
                        id='shipmentReference'
                        name='shipmentReference'
                        text={t('settings.alerts.information.types.shipmentReference')}
                        onChange={onCheck}
                        checked={shipmentReference}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='refUser'
                        name='refUser'
                        text={t('settings.alerts.information.types.clientReference')}
                        onChange={onCheck}
                        checked={refUser}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='refForwarder'
                        name='refForwarder'
                        text={t('settings.alerts.information.types.forwarderReference')}
                        onChange={onCheck}
                        checked={refForwarder}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='flowType'
                        name='flowType'
                        text={t('settings.alerts.information.types.flowType')}
                        onChange={onCheck}
                        checked={flowType}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='carrier'
                        name='carrier'
                        text={t('settings.alerts.information.types.carrier')}
                        onChange={onCheck}
                        checked={carrier}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='forwarder'
                        name='forwarder'
                        text={t('settings.alerts.information.types.forwarder')}
                        onChange={onCheck}
                        checked={forwarder}
                        disabled={!notifiable}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <InputCheckbox
                        id='finalClient'
                        name='finalClient'
                        text={t('settings.alerts.information.types.consignee')}
                        onChange={onCheck}
                        checked={finalClient}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='consignor'
                        name='consignor'
                        text={t('settings.alerts.information.types.consignor')}
                        onChange={onCheck}
                        checked={consignor}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='clientBookingNumber'
                        name='clientBookingNumber'
                        text={t('settings.alerts.information.types.clientBookingNumber')}
                        onChange={onCheck}
                        checked={clientBookingNumber}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='weight'
                        name='weight'
                        text={t('settings.alerts.information.types.grossWeight')}
                        onChange={onCheck}
                        checked={weight}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='volume'
                        name='volume'
                        text={t('settings.alerts.information.types.volume')}
                        onChange={onCheck}
                        checked={volume}
                        disabled={!notifiable}
                      />
                      <InputCheckbox
                        id='products'
                        name='products'
                        text={t('settings.alerts.information.types.productDescription')}
                        onChange={onCheck}
                        checked={products}
                        disabled={!notifiable}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Form.Group>
              </Form.Insert>
              <Form.Insert title={t('settings.alerts.eventTriggers.title')}>
                <StyledP>{t('settings.alerts.eventTriggers.details')}</StyledP>
                <Form.Accordion
                  icon='world_map'
                  name={t('settings.alerts.eventTriggers.allShipments.title')}
                  key='all-shipments-notifications-tab'
                  defaultOpened
                >
                  <Form.Group>
                    <Grid.Row>
                      <Grid.Column>
                        <InputCheckbox
                          id='recap'
                          name='recap'
                          text={t(
                            'settings.alerts.eventTriggers.allShipments.options.dailySummary'
                          )}
                          onChange={onCheck}
                          checked={recap}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='pickUp'
                          name='pickUp'
                          text={t('settings.alerts.eventTriggers.allShipments.options.pickUp')}
                          onChange={onCheck}
                          checked={pickUp}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='delivery'
                          name='delivery'
                          text={t('settings.alerts.eventTriggers.allShipments.options.delivery')}
                          onChange={onCheck}
                          checked={delivery}
                          disabled={!notifiable}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <InputCheckbox
                          id='bookingDelayed'
                          name='bookingDelayed'
                          text={t(
                            'settings.alerts.eventTriggers.allShipments.options.delayedBookingConfirmation'
                          )}
                          onChange={onCheck}
                          checked={bookingDelayed}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='bookingConfirmed'
                          name='bookingConfirmed'
                          text={t(
                            'settings.alerts.eventTriggers.allShipments.options.bookingConfirmed'
                          )}
                          onChange={onCheck}
                          checked={bookingConfirmed}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='flagged'
                          name='flagged'
                          text={t(
                            'settings.alerts.eventTriggers.allShipments.options.reportedShipment'
                          )}
                          onChange={onCheck}
                          checked={flagged}
                          disabled={!notifiable}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Group>
                </Form.Accordion>
                <Form.Accordion
                  icon='vessel'
                  name={t('settings.alerts.eventTriggers.seaShipments.title')}
                  key='sea-shipments-notifications-tab'
                >
                  <Form.Group>
                    <Grid.Row>
                      <Grid.Column>
                        <Select
                          name='seaEtdChange'
                          label={t('settings.alerts.eventTriggers.seaShipments.options.etdChange')}
                          options={seaEtChangeOptions}
                          onChange={onSelect}
                          isClearable={false}
                          isSearchable={false}
                          isDisabled={!notifiable}
                          defaultValue={seaEtChangeOptions[0]}
                          value={seaEtChangeOptions.find(({ value }) => value === seaEtdChange)}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Select
                          name='seaEtaChange'
                          label={t('settings.alerts.eventTriggers.seaShipments.options.etaChange')}
                          options={seaEtChangeOptions}
                          onChange={onSelect}
                          isClearable={false}
                          isSearchable={false}
                          isDisabled={!notifiable}
                          value={seaEtChangeOptions.find(({ value }) => value === seaEtaChange)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Group>
                  <Form.Group>
                    <Grid.Row>
                      <Grid.Column>
                        <InputCheckbox
                          id='seaGateIn'
                          name='seaGateIn'
                          text={t('settings.alerts.eventTriggers.seaShipments.options.gateIn')}
                          onChange={onCheck}
                          checked={seaGateIn}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='seaLoaded'
                          name='seaLoaded'
                          text={t('settings.alerts.eventTriggers.seaShipments.options.loaded')}
                          onChange={onCheck}
                          checked={seaLoaded}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='seaDeparture'
                          name='seaDeparture'
                          text={t('settings.alerts.eventTriggers.seaShipments.options.departure')}
                          onChange={onCheck}
                          checked={seaDeparture}
                          disabled={!notifiable}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <InputCheckbox
                          id='seaArrival'
                          name='seaArrival'
                          text={t('settings.alerts.eventTriggers.seaShipments.options.arrival')}
                          onChange={onCheck}
                          checked={seaArrival}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='seaUnloaded'
                          name='seaUnloaded'
                          text={t('settings.alerts.eventTriggers.seaShipments.options.unloaded')}
                          onChange={onCheck}
                          checked={seaUnloaded}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='seaGateOut'
                          name='seaGateOut'
                          text={t('settings.alerts.eventTriggers.seaShipments.options.gateOut')}
                          onChange={onCheck}
                          checked={seaGateOut}
                          disabled={!notifiable}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Group>
                </Form.Accordion>
                <Form.Accordion
                  icon='truck'
                  name={t('settings.alerts.eventTriggers.roadShipments.title')}
                  key='road-shipments-notifications-tab'
                >
                  <Form.Group>
                    <Grid.Row>
                      <Grid.Column>
                        <Select
                          name='roadEtdChange'
                          label={t('settings.alerts.eventTriggers.roadShipments.options.etdChange')}
                          options={roadEtchangeOptions}
                          onChange={onSelect}
                          isClearable={false}
                          isSearchable={false}
                          value={roadEtchangeOptions.find(({ value }) => value === roadEtdChange)}
                          isDisabled={!notifiable}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Select
                          name='roadEtaChange'
                          label={t('settings.alerts.eventTriggers.roadShipments.options.etaChange')}
                          options={roadEtchangeOptions}
                          onChange={onSelect}
                          isClearable={false}
                          isSearchable={false}
                          value={roadEtchangeOptions.find(({ value }) => value === roadEtaChange)}
                          isDisabled={!notifiable}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Group>
                  <Form.Group>
                    <Grid.Row>
                      <Grid.Column>
                        <InputCheckbox
                          id='roadTruckDeparture'
                          name='roadTruckDeparture'
                          text={t(
                            'settings.alerts.eventTriggers.roadShipments.options.truckDeparture'
                          )}
                          onChange={onCheck}
                          checked={roadTruckDeparture}
                          disabled={!notifiable}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <InputCheckbox
                          id='roadTruckArrival'
                          name='roadTruckArrival'
                          text={t(
                            'settings.alerts.eventTriggers.roadShipments.options.truckArrival'
                          )}
                          onChange={onCheck}
                          checked={roadTruckArrival}
                          disabled={!notifiable}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Group>
                </Form.Accordion>
                <Form.Accordion
                  icon='plane'
                  name={t('settings.alerts.eventTriggers.airShipments.title')}
                  key='air-shipments-notifications-tab'
                >
                  <Form.Group>
                    <Grid.Row>
                      <Grid.Column>
                        <Select
                          name='airEtdChange'
                          label={t('settings.alerts.eventTriggers.airShipments.options.etdChange')}
                          options={airEtchangeOptions}
                          onChange={onSelect}
                          isClearable={false}
                          isSearchable={false}
                          isDisabled={!notifiable}
                          value={airEtchangeOptions.find(({ value }) => value === airEtdChange)}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Select
                          name='airEtaChange'
                          label={t('settings.alerts.eventTriggers.airShipments.options.etaChange')}
                          options={airEtchangeOptions}
                          onChange={onSelect}
                          isClearable={false}
                          isSearchable={false}
                          isDisabled={!notifiable}
                          defaultValue={airEtchangeOptions[0]}
                          value={airEtchangeOptions.find(({ value }) => value === airEtaChange)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Group>
                  <Form.Group>
                    <Grid.Row>
                      <Grid.Column>
                        <InputCheckbox
                          id='airBooked'
                          name='airBooked'
                          text={t('settings.alerts.eventTriggers.airShipments.options.booked')}
                          onChange={onCheck}
                          checked={airBooked}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='airReceivedFromShipper'
                          name='airReceivedFromShipper'
                          text={t(
                            'settings.alerts.eventTriggers.airShipments.options.receivedFromShipper'
                          )}
                          onChange={onCheck}
                          checked={airReceivedFromShipper}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='airManifestedOnFlight'
                          name='airManifestedOnFlight'
                          text={t(
                            'settings.alerts.eventTriggers.airShipments.options.manifestedOnFlight'
                          )}
                          onChange={onCheck}
                          checked={airManifestedOnFlight}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='airDeparture'
                          name='airDeparture'
                          text={t('settings.alerts.eventTriggers.airShipments.options.departure')}
                          onChange={onCheck}
                          checked={airDeparture}
                          disabled={!notifiable}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <InputCheckbox
                          id='airArrival'
                          name='airArrival'
                          text={t('settings.alerts.eventTriggers.airShipments.options.arrival')}
                          onChange={onCheck}
                          checked={airArrival}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='airReceivedFromFlight'
                          name='airReceivedFromFlight'
                          text={t(
                            'settings.alerts.eventTriggers.airShipments.options.receivedFromFlight'
                          )}
                          onChange={onCheck}
                          checked={airReceivedFromFlight}
                          disabled={!notifiable}
                        />
                        <InputCheckbox
                          id='airDelivered'
                          name='airDelivered'
                          text={t('settings.alerts.eventTriggers.airShipments.options.delivered')}
                          onChange={onCheck}
                          checked={airDelivered}
                          disabled={!notifiable}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Form.Group>
                </Form.Accordion>
                {features(WITH_PARCEL_TRACKING) && (
                  <Form.Accordion
                    icon='parcel'
                    name={t('settings.alerts.eventTriggers.parcelShipments.title')}
                    key='parcel-shipments-notifications-tab'
                  >
                    <Form.Group>
                      <Grid.Row>
                        <Grid.Column>
                          <InputCheckbox
                            id='parcelPickup'
                            name='parcelPickup'
                            text={t('settings.alerts.eventTriggers.parcelShipments.options.pickup')}
                            onChange={onCheck}
                            checked={parcelPickup}
                            disabled={!notifiable}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <InputCheckbox
                            id='parcelDelivered'
                            name='parcelDelivered'
                            text={t(
                              'settings.alerts.eventTriggers.parcelShipments.options.delivered'
                            )}
                            onChange={onCheck}
                            checked={parcelDelivered}
                            disabled={!notifiable}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Form.Group>
                  </Form.Accordion>
                )}
              </Form.Insert>
            </Form>
          </Tab>
        </Tabs>
      </StyledModalSettingsForm>
    </ModalHorizontal>
  )
}

export default memo(ModalSettings)
