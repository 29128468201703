export const TYPE_SHIPMENT = 'shipment'
export const TYPE_CARBON_FOOTPRINT = 'carbon_footprint'

export const CUSTOM_KPIS_TYPES = [TYPE_SHIPMENT, TYPE_CARBON_FOOTPRINT] as const
export type CustomKpiType = typeof CUSTOM_KPIS_TYPES[number]

export const ARRIVAL_FORECAST_EARLY = 'early'
export const ARRIVAL_FORECAST_ON_TIME = 'on_time'
export const ARRIVAL_FORECAST_DELAYED = 'delayed'

export const ARRIVAL_FORECASTS = [
  ARRIVAL_FORECAST_EARLY,
  ARRIVAL_FORECAST_ON_TIME,
  ARRIVAL_FORECAST_DELAYED,
] as const

export type ArrivalForecast = typeof ARRIVAL_FORECASTS[number]

export const TIME_RANGE_ALL_ACTIVE = 'all_active'
export const TIME_RANGE_TODAY = 'today'
export const TIME_RANGE_THIS_WEEK = 'this_week'
export const TIME_RANGE_THIS_MONTH = 'this_month'
export const TIME_RANGE_THIS_YEAR = 'this_year'
export const TIME_RANGE_LAST_WEEK = 'last_week'
export const TIME_RANGE_LAST_MONTH = 'last_month'
export const TIME_RANGE_LAST_YEAR = 'last_year'

export const SHIPMENT_TIME_RANGES = [
  TIME_RANGE_ALL_ACTIVE,
  TIME_RANGE_TODAY,
  TIME_RANGE_THIS_WEEK,
  TIME_RANGE_THIS_MONTH,
] as const

export type ShipmentTimeRange = typeof SHIPMENT_TIME_RANGES[number]

export const CARBON_FOOTPRINT_TIME_RANGES = [
  TIME_RANGE_THIS_WEEK,
  TIME_RANGE_THIS_MONTH,
  TIME_RANGE_THIS_YEAR,
  TIME_RANGE_LAST_WEEK,
  TIME_RANGE_LAST_MONTH,
  TIME_RANGE_LAST_YEAR,
] as const

export type CarbonFootprintTimeRange = typeof CARBON_FOOTPRINT_TIME_RANGES[number]

export type CustomKpiTimeRange = ShipmentTimeRange | CarbonFootprintTimeRange
