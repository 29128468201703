import styled, { css } from 'styled-components'

import { desktop, tablet } from 'styles/utils/breakpoints'
import StyledIcon from 'components/icon/style'
import {
  StyledModalHeader,
  StyledModalContent,
  StyledModalClose,
  StyledModal,
  StyledModalHead,
} from 'components/modal/style'
import { headerHeightMobile, headerHeight } from 'components/header/style'

const StyledModalHorizontal = styled(StyledModal)`
  margin-top: calc(${headerHeightMobile} / 2);

  @media ${tablet.up} {
    margin-top: calc(${headerHeight} / 2);
  }

  @media ${desktop.up} {
    max-width: 980px;
    display: flex;
    height: 80vh;

    ${(props) =>
      props.$size === 'large' &&
      css`
        max-width: 1110px;
      `};
  }

  ${StyledModalHead} {
    text-overflow: unset;
    white-space: unset;
  }

  ${StyledModalHeader} {
    @media ${desktop.up} {
      flex-shrink: 0;
      width: 310px;
      height: 100%;
      align-items: flex-start;
      padding-top: 90px;
      padding-left: 40px;
    }
  }

  ${StyledModalContent} {
    padding-left: 15px;
    padding-right: 15px;

    @media ${desktop.up} {
      width: 100%;
      max-height: 100%;
      padding-top: 70px;
      padding-left: 27px;
      padding-right: 30px;
    }
  }

  ${StyledModalClose} {
    ${StyledIcon} {
      @media ${desktop.up} {
        fill: ${({ theme }) => theme.mediumGray};
      }
    }
  }
`

export default StyledModalHorizontal
