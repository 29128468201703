import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import TextListItem from 'components/text_list/text_list_item'
import TextList from 'components/text_list'
import Placeholder from 'components/placeholder'

import { References } from 'views/shipment/models'

import TextListItemSkeleton from 'components/text_list/skeleton'
import useUserCan from 'views/iam/hooks/use_user_can'
import { ORDER_UPDATE_PRODUCT_INFORMATION } from 'constants/permissions'
import { StyledButtonsWrapper } from 'views/shipment/components/references/style'
import Button from 'components/button'
import {
  UpdateProductInformationsPayload,
  fetchShipment,
  updateProductDetails,
} from 'views/shipment/slice'
import ShipmentContext from 'views/shipment/context'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import useShipment from 'views/shipment/hooks/use_shipment'
import {
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_SAVE,
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_WEIGHT,
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_VOLUME,
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_PACKAGE_NUMBER,
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_DESCRIPTION,
} from 'tests/e2e/test_ids'

type ProductInformationState = {
  grossWeight?: number
  volume?: number
  description?: string
  packageNumber?: number
}

const ProductInformationBlock = ({
  isEditing,
  setIsEditing,
}: {
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const userCan = useUserCan()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { id } = useContext(ShipmentContext)
  const [shipment, status] = useShipment({ id: id! })
  const product = useMemo(() => new References(shipment).product, [shipment])
  const [productInformation, setProductInformation] = useState<ProductInformationState>({})

  const canEdit = userCan(ORDER_UPDATE_PRODUCT_INFORMATION)

  useEffect(() => {
    setProductInformation({
      ...product,
    })
  }, [product])

  const saveNewProductDetails = () => {
    const payload: UpdateProductInformationsPayload = {
      loadWeight: productInformation.grossWeight,
      loadVolume: productInformation.volume,
      productDescription: productInformation.description,
      packageNumber: productInformation.packageNumber,
      token: id!,
    }
    dispatch(updateProductDetails(payload))
      .unwrap()
      .then(() => {
        addNotification({
          type: 'success',
          title: t('shipments.notifications.shipmentUpdated.title'),
          text: t('shipments.notifications.shipmentUpdated.content'),
        })
      })
      .then(() => {
        dispatch(fetchShipment({ id: id! }))
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  return (
    <Placeholder ready={status.ready} customPlaceholder={<TextListItemSkeleton />}>
      <TextList>
        <TextListItem
          testId={`${TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_WEIGHT}`}
          title={t('shipments.product.grossWeight')}
          text={product.grossWeight}
          key='text-list-item-weight'
          isEditing={isEditing}
          inputType='number'
          onChange={(e) =>
            canEdit && setProductInformation({ ...productInformation, grossWeight: e.target.value })
          }
          value={productInformation.grossWeight}
        />
        <TextListItem
          testId={`${TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_VOLUME}`}
          title={t('shipments.product.volume')}
          text={product.volume}
          key='text-list-item-volume'
          isEditing={isEditing}
          inputType='number'
          onChange={(e) =>
            canEdit && setProductInformation({ ...productInformation, volume: e.target.value })
          }
          value={productInformation.volume}
        />
        <TextListItem
          testId={`${TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_PACKAGE_NUMBER}`}
          title={t('shipments.product.packageNumber')}
          text={product.packageNumber}
          key='text-list-item-package-number'
          inputType='number'
          isEditing={isEditing}
          onChange={(e) =>
            canEdit &&
            setProductInformation({ ...productInformation, packageNumber: e.target.value })
          }
          value={productInformation.packageNumber}
        />
        <TextListItem
          testId={`${TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_DESCRIPTION}`}
          title={t('shipments.product.description')}
          text={product.description}
          variant='description'
          key='text-list-item-description'
          isEditing={isEditing}
          isTextArea
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            canEdit && setProductInformation({ ...productInformation, description: e.target.value })
          }
          value={productInformation.description}
        />
      </TextList>
      {isEditing && (
        <StyledButtonsWrapper>
          <Button type='button' text={t('actions.cancel')} onClick={() => setIsEditing(false)} />
          <Button
            testId={TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_SAVE}
            type='button'
            text={t('actions.save')}
            onClick={() => {
              saveNewProductDetails()
              setIsEditing(false)
            }}
            variant='highlight'
          />
        </StyledButtonsWrapper>
      )}
    </Placeholder>
  )
}

export default ProductInformationBlock
