import React, { useContext, useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { addNotification } from 'views/notifications/slice'
import ShipmentContext from 'views/shipment/context'
import { fetchSharedOrderToken, sharedOrderSendMail } from 'views/shipment/slice'

import Modal from 'components/modal'
import {
  StyledShareForm,
  StyledShareFormInput,
  StyledShareFormInputTags,
  StyledShareFormTextarea,
} from 'components/modal_share/style'
import useSharedOrderMailInfo from 'components/modal_share/hooks/use_shared_order_mail_info'

import { validateEmail } from 'services/helpers/form_validation'
import { isAnyArray, isEmptyArray, isInputEmpty, isPresent } from 'services/helpers/values'
import useTracker from 'services/analytics/hooks/use_tracker'
import { routeSharedShipment } from 'services/helpers/routes'
import Button from 'components/button'
import useModal from 'components/modal/hooks/use_modal'
import useCurrentOrganization from 'views/iam/hooks/use_current_organization'

const ModalShare = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useContext(ShipmentContext)
  const { setOpen } = useModal('share')
  const { track } = useTracker()
  const organization = useCurrentOrganization()

  const copyToClipboard = (content) => {
    const basePath = organization.customDomain ?? process.env.REACT_APP_APPLICATION_ENDPOINT

    navigator.clipboard.writeText(
      `${basePath}${routeSharedShipment(content, {
        isLegacy: true,
      })}`
    )
  }

  const getShareableLink = () => {
    dispatch(fetchSharedOrderToken({ token: id }))
      .unwrap()
      .then(({ sharedOrderToken }) => {
        copyToClipboard(sharedOrderToken)
        track('Shipment / Share', { action: 'share', detail: sharedOrderToken })
      })
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.shareableLink.title'),
            text: t('shipments.shareableLink.modal.successMessageLinkCopied'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  const { fullName, ref, origin, destination, eta } = useSharedOrderMailInfo({ id })

  const defaultEmailAttributes = useMemo(
    () => ({
      emails: [],
      subject: t('shipments.shareableLink.modal.subject', { name: fullName, ref }),
      message: `${t('shipments.shareableLink.modal.message', { name: fullName })}
      
    - ${t('shipments.shareableLink.modal.ref', { ref })}
    - ${t('shipments.shareableLink.modal.origin', { origin })}
    - ${t('shipments.shareableLink.modal.destination', { destination })}
    - ${t('shipments.shareableLink.modal.eta', { eta })}`,
    }),
    [t, fullName, ref, origin, destination, eta]
  )
  const defaultMessage = t('shipments.shareableLink.modal.defaultMessage', { name: fullName })
  const [data, setData] = useState(defaultEmailAttributes)
  useEffect(() => {
    setData(defaultEmailAttributes)
  }, [defaultEmailAttributes])
  const [errors, setErrors] = useState({})

  const onChange = ({ name, value }) => setData((prevData) => ({ ...prevData, [name]: value }))

  const isValid = useMemo(() => {
    if (isEmptyArray(data.emails)) {
      return false
    }
    const invalidEmails = data.emails.filter((email) => !validateEmail(email))
    if (isAnyArray(invalidEmails)) {
      return false
    }

    if (isInputEmpty(data.subject) || isInputEmpty(data.message)) {
      return false
    }

    return true
  }, [data.emails, data.subject, data.message])

  const submit = () => {
    track('Shipment / Share', { type: 'share', detail: data.emails })
    dispatch(
      sharedOrderSendMail({
        token: id,
        recipients: data.emails,
        subject: data.subject,
        content: isPresent(data.message.trim()) ? data.message : defaultMessage,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.shareableLink.title'),
            text: t('shipments.shareableLink.modal.successMessageEmailSent'),
          })
        )
        setOpen(false)
        setData(defaultEmailAttributes)
      })
      .catch((error) => {
        if (error?.message?.details) {
          const newErrors = Object.fromEntries(
            error.message.details.map(({ name, message }) => [name, message])
          )
          setErrors(newErrors)
        } else {
          dispatch(
            addNotification({
              type: 'alert',
              title: t('errors.notification.title'),
              text: error.message || t('errors.notification.content'),
            })
          )
        }
      })
  }

  return (
    <Modal modalName='share'>
      <Modal.Header>{t('shipments.shareableLink.modal.title')}</Modal.Header>
      <Modal.Content>
        <StyledShareForm>
          <StyledShareFormInputTags
            label={t('shipments.shareableLink.modal.emailAddresses')}
            name='emails'
            placeholder={t('shipments.shareableLink.modal.emailAddressesPlaceholder')}
            error={errors.emails}
            defaultValue={data.emails}
            onChange={({ tags }) => onChange({ name: 'emails', value: tags })}
            pattern='email'
          />
          <StyledShareFormInput
            label={t('shipments.shareableLink.modal.emailSubject')}
            name='subject'
            type='text'
            id='subject'
            value={data.subject || defaultEmailAttributes.subject}
            error={errors.subject}
            onChange={({ target: { value } }) => onChange({ name: 'subject', value })}
          />
          <StyledShareFormTextarea
            label={t('shipments.shareableLink.modal.emailContent')}
            name='message'
            type='textarea'
            id='message'
            value={data.message || defaultEmailAttributes.message}
            error={errors.message}
            onChange={({ target: { value } }) => onChange({ name: 'message', value })}
          />
        </StyledShareForm>
      </Modal.Content>
      <Modal.Footer>
        <Button
          icon='hyperlink'
          text={t('shipments.shareableLink.modal.cta')}
          onClick={getShareableLink}
        />
        <Button
          text={t('shipments.shareableLink.modal.send')}
          variant='highlight'
          type='submit'
          disabled={!isValid}
          onClick={submit}
        />
      </Modal.Footer>
    </Modal>
  )
}

ModalShare.propTypes = {}

ModalShare.defaultProps = {}

export default ModalShare
