import styled from 'styled-components'

import { h2, text14Regular } from 'styles/utils/texts'
import { desktopLarge, desktop, tablet } from 'styles/utils/breakpoints'
import { borderRadiusMedium } from 'styles/utils/border_radius'
import { StyledSelectLabel } from 'components/select/style'
import { gradientDarkOpacityReverse } from 'styles/utils/gradients'
import {
  StyledShipmentListItem,
  StyledShipmentListItemHead,
} from 'components/shipment_list_item/style'
import { StyledWidgets } from 'components/widgets/style'
import { StyledMarker } from 'components/marker/style'
import StyledShipmentList from 'components/shipment_list/style'

export const StyledDashboardMap = styled.div`
  height: ${({ $fullscreen }) => ($fullscreen ? `100%` : '50%')};
  @media ${desktop.down} {
    height: 380px;
  }
  @media ${tablet.down} {
    height: 125px;
  }
`

export const StyledShipmentsListContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 0;
  padding: 40px 20px;
  @media ${tablet.down} {
    padding: 30px 20px;
  }
  background-color: ${({ theme }) => theme.white};
  ${borderRadiusMedium}

  ${StyledShipmentListItem} {
    @media ${desktopLarge.up} {
      flex-wrap: nowrap;
    }
  }
  ${StyledShipmentListItemHead} {
    @media ${desktop.up} {
      width: 60%;
    }
  }
  ${StyledShipmentList} {
    margin-bottom: 0;
  }
`

export const StyledShipmentsListWrapper = styled.div`
  overflow: auto;
`

export const StyledShipments = styled.div`
  position: relative;
  display: flex;
  height: calc(50% + 100px);
  padding: 0 90px 30px 90px;
  margin-top: -100px;
  @media ${desktop.down} {
    margin-top: -205px;
    flex-direction: column;
    padding: 0 47px 30px 47px;
  }
  @media ${tablet.down} {
    margin-top: -30px;
    padding: 0 16px 30px 16px;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @media ${desktop.down} {
      top: 205px;
    }
    @media ${tablet.down} {
      top: 30px;
    }
    ${gradientDarkOpacityReverse};
  }
  pointer-events: none;
  ${StyledWidgets} {
    pointer-events: all;
  }
  ${StyledShipmentsListContainer} {
    pointer-events: all;
  }
`

export const StyledShipmentsListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px;
  @media ${tablet.down} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
`
export const StyledShipmentsListTitle = styled.span`
  ${h2}
  font-size:24px;
  color: ${({ theme }) => theme.textDark};
`
export const StyledPageShipmentListSelect = styled.div`
  display: flex;
  align-items: center;
  z-index: 20;
  position: relative;
  white-space: nowrap;

  ${StyledSelectLabel} {
    ${text14Regular}
    margin: 0;
  }

  @media ${tablet.down} {
    align-self: flex-end;
    margin-top: 10px;
  }
`

export const StyledPin = styled.div`
  position: absolute;
  left: ${({ $position }) => $position.x}px;
  top: ${({ $position }) => $position.y}px;
  transform: translate(-50%, -50%);
  font-size: 12px;

  ${StyledMarker} {
    position: relative;
    :after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3.5em;
      height: 3.5em;
      background-color: ${({ theme }) => theme.primary};
      opacity: 0.3;
      border-radius: 50%;
      z-index: -1;
    }
  }
`
export const StyledLoadMoreButton = styled.div`
  width: fit-content;
`
