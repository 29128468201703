import styled from 'styled-components'

import Input from 'components/input'
import Textarea from 'components/textarea'
import InputTags from 'components/input_tags'

import { textSmallDescription, h1, listItem13 } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'
import { desktop } from 'styles/utils/breakpoints'

export const StyledShareForm = styled.form``
export const StyledShareFormFormTitle = styled.h1`
  ${h1}
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 35px;
  }
`

export const StyledShareFormInputTags = styled(InputTags)`
  width: 100%;
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 25px;
  }
`

export const StyledShareFormInput = styled(Input)`
  width: 100%;
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 25px;
  }
`

export const StyledShareFormTextarea = styled(Textarea)`
  width: 100%;
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 25px;
  }
`

export const StyledShareFormText = styled.p`
  ${textSmallDescription}
  color: ${({ theme }) => theme.textLight};
  position: relative;

  @media ${desktop.down} {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const StyledShareFormTextLink = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  transition: color 0.5s ${easeQuartOut};

  &:hover {
    color: ${({ theme }) => theme.primary50};
  }
`

export const StyledShareFormTextWrapper = styled.div`
  @media ${desktop.down} {
    text-align: center;
    margin-top: 20px;
  }
  @media ${desktop.up} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
  }
`

export const StyledShareFormTextDot = styled.div`
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: ${({ theme }) => theme.softGrayLight};
  margin-top: -3px;

  @media ${desktop.down} {
    display: none;
  }
`

export const StyledShareFormError = styled.p`
  color: ${({ theme }) => theme.error};
  ${listItem13}
  margin-top: 40px;

  @media ${desktop.down} {
    text-align: center;
  }
`
