import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import NavigationItem from 'components/navigation_item'
import { StyledNavigation, StyledList } from 'components/navigation/style'
import { TEST_ID_NAV_BAR } from 'tests/e2e/test_ids'

const Navigation = ({
  isOpenMobile,
  className,
  children,
  testId,
}: {
  isOpenMobile: boolean
  className?: string
  children: ReactNode[]
  testId: string
}) => (
  <StyledNavigation className={className} $open={isOpenMobile} data-testid={testId}>
    <StyledList testId={TEST_ID_NAV_BAR}>{children}</StyledList>
  </StyledNavigation>
)

Navigation.propTypes = {
  /** NavigationItem */
  children: validateChildrenOfTypes([NavigationItem]),
  /* Is open on mobile */
  isOpenMobile: PropTypes.bool,
  /** ClassName */
  className: PropTypes.string,
  /** Id for testing purposes */
  testId: PropTypes.string,
}

Navigation.defaultProps = {
  children: [],
  className: undefined,
  isOpenMobile: false,
  testId: TEST_ID_NAV_BAR,
}

export default Navigation
