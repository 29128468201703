// TODO: REFACTOR:
/* eslint-disable @typescript-eslint/no-explicit-any, react/prop-types */

import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import ReactSelect, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

import { addNotification } from 'views/notifications/slice'

import { isPresent } from 'services/helpers/values'
import { anyChildren } from 'services/helpers/prop_types'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import * as IconList from 'assets/icons'

import OptionComponent from 'components/select/option'
import Icon from 'components/icon'
import ModalContext from 'components/modal/context'
import { StyledInputError } from 'components/input/style'
import IconTooltip from 'components/icon_tooltip'

import {
  StyledSelect,
  StyledReactSelect,
  StyledReactSelectOptionSlotBefore,
  StyledSelectLabel,
  StyledPicto,
  StyledReactSelectSingleValue,
  StyledSelectLabelRequiredIcon,
} from 'components/select/style'

import { TooltipPlacementType } from 'components/tooltip/types'

import type { AsyncThunkAction } from '@reduxjs/toolkit'

export interface OptionProps extends SelectValue {
  name?: string
  slot?: {
    beforeLabel: ReactElement
  }
  isDisabled?: boolean
  hasArrow?: boolean
}

const SELECT_TYPES = [CreatableSelect, ReactSelect, AsyncSelect, AsyncCreatableSelect] as const

type SelectType = typeof SELECT_TYPES[number]

const { SingleValue, Menu } = components
const SingleValueComponent = ({ children, selectProps, data, ...props }: any) => {
  const item = selectProps.options.find(
    (option: OptionProps) => option.value === selectProps.getOptionValue(data)
  )

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SingleValue {...props}>
      <StyledReactSelectSingleValue>
        {item?.slot && item?.slot.beforeLabel && (
          <StyledReactSelectOptionSlotBefore>
            {item.slot.beforeLabel}
          </StyledReactSelectOptionSlotBefore>
        )}
        {children}
      </StyledReactSelectSingleValue>
    </SingleValue>
  )
}

SingleValueComponent.propTypes = {
  children: anyChildren,
  selectProps: PropTypes.shape({}),
  data: PropTypes.shape({}),
}

SingleValueComponent.defaultProps = {
  children: undefined,
  selectProps: undefined,
  data: undefined,
}

const MenuComponent = ({ children, ...props }: any) => (
  <div
    style={{
      position: 'relative',
      zIndex: 2,
    }}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Menu {...props}>{children}</Menu>
  </div>
)

export type SelectRef = HTMLSelectElement
export type SelectValue = {
  value: Tvalue
  label: string
  type?: any
  createValue?: string
}
export type Tvalue = string | number | undefined | null

type OptionalMultiType<IsMulti, T> = IsMulti extends true ? T[] : T
type OptionalMultiSelectValue<IsMulti> = OptionalMultiType<IsMulti, SelectValue>
export interface OnChangeFnProps<IsMulti = false> {
  name?: string
  value: OptionalMultiSelectValue<IsMulti>
}
export type OnChangeFn<IsMulti = false> = (e: OnChangeFnProps<IsMulti>) => void

type FetchFunction = ({
  name,
  value,
}: {
  name: string | undefined
  value: Tvalue
}) => AsyncThunkAction<any, any, any>

interface SelectTemplateProps<IsMulti> {
  options?: any
  hideControls?: boolean
  openMenuOnFocus?: boolean
  onChange?: OnChangeFn<IsMulti>
  onCreateOption?: (newOption: string) => void
  formatCreateLabel?: (inputValue: string) => string
  onFocus?: ({ name }: { name: string | undefined }) => void
  onMenuOpen?: () => void
  isMulti?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  isDisabled?: boolean
  isOptionDisabled?: () => void
  defaultValue?: (IsMulti extends true ? SelectValue[] : SelectValue) | null
  value?: (IsMulti extends true ? SelectValue[] : SelectValue) | null
  placeholder?: string
  label?: string
  labelInfo?: string
  labelInfoPlacement?: TooltipPlacementType
  variant?: 'default' | 'text-control' | 'dropdown' | 'primary'
  ariaLabel?: string
  className?: string
  inputId?: string
  async?: boolean
  creatable?: boolean
  name?: string
  fetch?: FetchFunction
  fetchOnFocus?: FetchFunction
  fetchedOptionsFormat?: (e: any) => void
  icon?: keyof typeof IconList
  noPadding?: boolean
  required?: boolean
  error?: string
  ref?: any
  testId?: string
  portal?: boolean
  dropdownPosition?: 'top' | 'bottom' | 'left' | 'right'
}

export type SelectProps = Omit<SelectTemplateProps<false>, 'isMulti'>
export type MultiSelectProps = SelectTemplateProps<true>

const MAX_MENU_HEIGHT = 300

// Template used to infer some prop types. `Select` and `MultiSelect` templated versions of this component are exported
// so that the component interface does not change and cause unexpected type errors when setting the `isMulti` prop.
export const SelectTemplate = <IsMulti extends boolean>({
  options,
  hideControls,
  openMenuOnFocus,
  onChange,
  onCreateOption,
  onFocus,
  onMenuOpen,
  isMulti,
  isClearable,
  isSearchable,
  isDisabled,
  isOptionDisabled,
  defaultValue,
  value,
  placeholder,
  label,
  labelInfo,
  labelInfoPlacement = 'top',
  variant = 'default',
  ariaLabel,
  className,
  inputId,
  async,
  creatable,
  name,
  fetch,
  fetchOnFocus,
  fetchedOptionsFormat,
  icon,
  noPadding,
  required,
  error,
  ref,
  testId,
  formatCreateLabel,
  portal = true,
  dropdownPosition,
}: SelectTemplateProps<IsMulti>) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [opts, setOptions] = useState<Array<OptionProps>>([])
  const [isLoading, setIsLoading] = useState(false)
  const { ref: modalProviderRef } = useContext(ModalContext)

  useEffect(() => {
    if (options) {
      setOptions(options)
    }
  }, [options])

  const onFetch = (fetchFn: AsyncThunkAction<any, any, any>) =>
    dispatch(fetchFn)
      .unwrap()
      .then((results: any) =>
        fetchedOptionsFormat
          ? fetchedOptionsFormat(results)
          : results.map((result: any) => ({ ...result, name }))
      )
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })

  const selectRef = useRef<HTMLDivElement>(null)
  const elSelectMenu = selectRef.current?.querySelector('.Select__control')
  const [placement, setPlacement] = useState(dropdownPosition || 'bottom')
  const autoFlip = () => {
    const { top } = elSelectMenu?.getBoundingClientRect() || { top: 0 }
    if (top + MAX_MENU_HEIGHT + 25 > window.innerHeight) {
      setPlacement('top')
    } else {
      setPlacement('bottom')
    }
  }

  let selectType: SelectType = async ? AsyncSelect : ReactSelect

  if (creatable) selectType = async ? AsyncCreatableSelect : CreatableSelect

  return (
    <StyledSelect ref={selectRef} className={className}>
      {label && (
        <StyledSelectLabel htmlFor={inputId || name}>
          {label} {required && <StyledSelectLabelRequiredIcon>*</StyledSelectLabelRequiredIcon>}
          {labelInfo && (
            <IconTooltip content={labelInfo} size='large' inline placement={labelInfoPlacement} />
          )}
        </StyledSelectLabel>
      )}
      {icon && <StyledPicto as={Icon} name={icon} />}
      <div data-testid={testId}>
        <StyledReactSelect
          inputId={inputId || name}
          $noPadding={noPadding}
          $withIcon={isPresent(icon)}
          menuPlacement={placement}
          maxMenuHeight={MAX_MENU_HEIGHT}
          as={selectType}
          $variant={variant}
          ref={ref}
          aria-label={ariaLabel}
          name={name}
          options={opts}
          classNamePrefix='Select'
          openMenuOnFocus={openMenuOnFocus}
          formatCreateLabel={formatCreateLabel}
          onCreateOption={onCreateOption && onCreateOption}
          onChange={(option: OptionalMultiSelectValue<IsMulti>) => {
            if (onChange) {
              onChange({ value: option, name })
            }
          }}
          isLoading={isLoading}
          onFocus={() => {
            if (onFocus) {
              onFocus({ name })
            }
            if (fetchOnFocus) {
              setIsLoading(true)
              onFetch(fetchOnFocus({ value: null, name }))
                .then(setOptions)
                .then(() => setIsLoading(false))
            }
          }}
          onMenuOpen={() => {
            autoFlip()
            if (onMenuOpen) {
              onMenuOpen()
            }
          }}
          isMulti={isMulti}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isOptionDisabled={isOptionDisabled}
          isSearchable={isSearchable}
          defaultValue={defaultValue}
          value={value}
          components={{
            Option: OptionComponent,
            SingleValue: SingleValueComponent,
            Menu: MenuComponent,
          }}
          $hideControls={hideControls}
          placeholder={isPresent(placeholder) ? placeholder : t('actions.select')}
          defaultOptions={fetchOnFocus && opts}
          loadOptions={debounce((v, cb) => {
            if (fetch) {
              onFetch(fetch({ value: v, name })).then(cb)
            }
          }, 500)}
          menuPortalTarget={portal && modalProviderRef?.current}
          closeMenuOnScroll={(e: React.UIEvent<HTMLElement>) => {
            const target = e.target as Element
            return isPresent(target.className) && !target.className.includes('Select__menu-list')
          }}
        />
      </div>

      {/*
        TODO: REFACTOR:
        This is leaky: it uses another component style (Input)
        There should be a way to centralize shared styles
         */}
      {error && (
        <StyledInputError>
          <Icon name='warning' /> {error}
        </StyledInputError>
      )}
    </StyledSelect>
  )
}

const Select = React.forwardRef<SelectRef, SelectProps>((props, ref) =>
  SelectTemplate<false>({ ...props, ref })
)

export { Select as SelectStorybook }

export default Select
