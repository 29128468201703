import React, { FC, ReactNode, SyntheticEvent, useState } from 'react'
import { v4 as uuid } from 'uuid'

import {
  StyledSwitchInput,
  StyledSwitchInputSwitch,
  StyledSwitchInputOption,
  StyledLabel,
} from 'components/switch_input/style'

import { isPresent } from 'services/helpers/values'

export interface SwitchInputProps {
  leftLabel?: string | ReactNode
  rightLabel?: string | ReactNode
  rightOptionChecked?: boolean
  name?: string
  variant?: 'default' | 'outline'
  className?: string
  onClick?: (rightOptionChecked: boolean) => void
  disabled?: boolean
  testId?: string
}
const SwitchInput: FC<SwitchInputProps> = ({
  leftLabel,
  rightLabel,
  rightOptionChecked,
  name,
  variant = 'default',
  className,
  onClick = () => {},
  disabled = false,
  testId,
}) => {
  const isControlled = isPresent(rightOptionChecked)
  const [defaultChecked, setDefaultChecked] = useState(false)
  const checked = isControlled ? rightOptionChecked : defaultChecked

  const onSwitch = (newRightOptionChecked: boolean) => {
    if (!isControlled) {
      setDefaultChecked(newRightOptionChecked)
    }
    if (newRightOptionChecked !== checked) {
      onClick(newRightOptionChecked)
    }
  }
  const id = uuid()
  return (
    <StyledSwitchInput
      $checked={checked}
      $variant={variant}
      className={className}
      $disabled={disabled}
    >
      {leftLabel && (
        <StyledSwitchInputOption
          $left
          $active={!checked}
          onClick={(e) => {
            e.preventDefault()
            onSwitch(false)
          }}
          disabled={disabled}
        >
          {leftLabel}
        </StyledSwitchInputOption>
      )}

      <StyledSwitchInputSwitch data-testid={testId}>
        <StyledLabel $disabled={disabled} htmlFor={id}>
          <input
            name={name}
            type='checkbox'
            id={id}
            checked={checked}
            onChange={(e: SyntheticEvent) => {
              const { checked: newChecked } = e.target as HTMLInputElement
              onSwitch(!!newChecked)
            }}
            disabled={disabled}
          />
        </StyledLabel>
      </StyledSwitchInputSwitch>

      {rightLabel && (
        <StyledSwitchInputOption
          $right
          $active={checked}
          onClick={(e) => {
            e.preventDefault()
            onSwitch(true)
          }}
          disabled={disabled}
        >
          {rightLabel}
        </StyledSwitchInputOption>
      )}
    </StyledSwitchInput>
  )
}

export default SwitchInput
