import React from 'react'
import { PropTypes } from 'prop-types'

import AvatarList from 'components/avatar_list'
import Avatar from 'components/avatar'

const ShipmentUsersList = ({ users }) => (
  <AvatarList>
    {users.map(({ id: uid, firstName, lastName, color }) => (
      <Avatar firstName={firstName} lastName={lastName} color={color} key={`user-${uid}`} />
    ))}
  </AvatarList>
)

ShipmentUsersList.propTypes = {
  /** Users */
  users: PropTypes.arrayOf(PropTypes.shape()),
}

ShipmentUsersList.defaultProps = {
  users: [],
}

export default ShipmentUsersList
