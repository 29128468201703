import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ShipmentContext from 'views/shipment/context'

import { References } from 'views/shipment/models'

import TextList from 'components/text_list'
import useUserCan from 'views/iam/hooks/use_user_can'
import { ORDER_ORGANIZATION_READ, SHIPMENT_PARTIES_READ } from 'constants/permissions'
import TextListItem from 'components/text_list/text_list_item'
import useShipment from 'views/shipment/hooks/use_shipment'

const PartiesBlock = () => {
  const { t } = useTranslation()
  const userCan = useUserCan()
  const { id } = useContext(ShipmentContext)
  const [shipment] = useShipment({ id: id! })
  const parties = useMemo(() => new References(shipment).parties, [shipment])

  return (
    <TextList>
      {userCan(SHIPMENT_PARTIES_READ) && (
        <>
          <TextListItem
            title={t('shipments.parties.consignor')}
            text={parties.consignor}
            key='text-list-item-consignor'
          />
          <TextListItem
            title={t('shipments.parties.consignee')}
            text={parties.consignee}
            key='text-list-item-consignee'
          />

          <TextListItem
            title={t('shipments.parties.forwarder')}
            text={parties.forwarder}
            key='text-list-item-forwarder'
          />
          <TextListItem
            title={t('shipments.parties.shipper')}
            text={parties.shipper}
            key='text-list-item-client'
          />
        </>
      )}
      {userCan(ORDER_ORGANIZATION_READ) && (
        <TextListItem
          title={t('shipments.parties.owner')}
          text={parties.owner}
          key='text-list-item-owner'
        />
      )}
    </TextList>
  )
}

export default PartiesBlock
