import styled from 'styled-components'

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 0 50px;
`

const Field = styled.div`
  width: 100%;
`

const S = { FormContent, Field }

export default S
