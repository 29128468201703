import React, { FC } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

import Icon from 'components/icon'
import Logo, { LogoType } from 'components/logo'
import {
  StyledItem,
  StyledSpan,
  StyledText,
  StyledContent,
  StyledItemIcon,
  StyledItemLogo,
  StyledItemCloseIcon,
} from 'components/notif_message/style'
import { getTestIdForNotification } from 'tests/e2e/test_ids'

export type NotificationTypes =
  | 'success'
  | 'info'
  | 'alert'
  | 'save'
  | 'tip'
  | 'minorInfo'
  | 'loading'

export interface NotifMessageProps {
  type: NotificationTypes
  text?: string | HTMLElement
  title?: string
  close?: boolean
  onClose?: () => void
  padded?: boolean
  className?: string
  fluid?: boolean
}

const NotifMessage: FC<NotifMessageProps> = ({
  type,
  text,
  title,
  close = false,
  onClose,
  padded = false,
  className,
  fluid = false,
}) => {
  const typeMsg = {
    success: {
      icon: 'check_plain',
    },
    info: {
      icon: 'info',
    },
    alert: {
      icon: 'warning',
    },
    save: {
      icon: 'save_plain',
    },
    tip: {
      logo: 'tip',
    },
    minorInfo: {
      icon: 'info',
    },
    loading: {},
  }[type]

  return (
    <StyledItem
      className={className}
      $type={type}
      $padded={padded}
      $fluid={fluid}
      data-testid={getTestIdForNotification(type)}
    >
      {type === 'loading' ? <ClipLoader color='grey' loading size={15} /> : <></>}

      {/* TODO: REFACTOR */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      {typeMsg.icon && <StyledItemIcon as={Icon} name={typeMsg.icon} />}
      {typeMsg.logo && <StyledItemLogo as={Logo} name={typeMsg.logo as LogoType} />}
      <StyledContent>
        <StyledSpan>{title}</StyledSpan>
        <StyledText>{text}</StyledText>
      </StyledContent>
      {close && typeMsg.icon && (
        <StyledItemCloseIcon onClick={onClose}>
          <Icon width={10} height={10} name='close' />
        </StyledItemCloseIcon>
      )}
    </StyledItem>
  )
}

export default NotifMessage
