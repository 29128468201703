import styled, { css } from 'styled-components'

import { StyledButton, StyledButtonIcon } from 'components/button/style'
import { StyledCo2 } from 'components/co2/style'
import { StyledBlockList } from 'components/block_list/style'
import { StyledBlockTransportation } from 'components/block_transportation/style'
import { StyledQuickInfoIcon } from 'components/quick_info/style'
import StyledIcon from 'components/icon/style'
import Tabs from 'components/tabs'
import { StyledPanelsWrapper } from 'components/tabs/style'
import { PageContentMaxWidth } from 'components/page/style'
import { StyledDatePickerLabel } from 'components/input_datepicker/style'

import { buttonSmall, h1, textSmallDescription, text14Regular } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'
import { desktop, desktopLarge } from 'styles/utils/breakpoints'
import { zIndexMobileSwitch } from 'styles/utils/z_index'
import { gradientLightOpacityReverse } from 'styles/utils/gradients'

import FadeMask from 'assets/masks/fade.svg'
import { StyledTabNavigation } from 'components/tabs/tab_navigation/style'
import StyledTabPanel from 'components/tabs/tab_panel/style'

export const shipmentLeftColWidth = '390px'

export const shipmentContentPaddingDesktop = 60

export const shipmentContentPaddingTablet = 30

export const StyledShipmentLeftCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 ${shipmentLeftColWidth};
  max-width: ${shipmentLeftColWidth};
  background-color: white;
  overflow: auto;
`
export const StyledShipmentViewSwitcher = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;

  ${StyledPanelsWrapper} {
    overflow-y: auto;
    min-height: 0;
  }
`
export const StyledShipmentViewSwitcherTimeline = styled.div`
  min-height: 0;
  overflow-y: auto;
`
export const StyledShipmentMain = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const StyledShipmentNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  ${({ $withBackToList }) => !$withBackToList && `padding-top: 25px;`}
`

export const StyledShipmentNavigationButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${StyledButton} {
    width: 24px;
    height: 24px;
    padding: 0;
    flex-shrink: 0;

    ${StyledIcon} {
      fill: ${(props) => props.theme.mediumGray};
    }
  }

  @media ${desktopLarge.up} {
    margin-left: 25px;
  }
`

export const StyledShipmentNavigationButtonText = styled.span`
  ${buttonSmall};
  color: ${(props) => props.theme.darkText};
`

export const StyledShipmentContentHeadRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
`

export const StyledShipmentContentCreationDate = styled.div`
  flex-shrink: 0;
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
`

export const StyledShipmentContentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
`
export const StyledShipmentContentHeadTitle = styled.h1`
  ${h1}
  overflow: hidden;
  text-overflow: ellipsis;

  ${StyledCo2} {
    margin-left: 12px;
  }
`

export const StyledShipmentFavorite = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  @media ${desktop.up} {
    width: ${shipmentContentPaddingTablet}px;
  }

  @media ${desktopLarge.up} {
    width: ${shipmentContentPaddingDesktop}px;
  }
`

export const StyledShipmentContentHeadLeft = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 12px;

  @media ${desktop.up} {
    margin-left: ${shipmentContentPaddingTablet * -1}px;
  }
  @media ${desktopLarge.up} {
    margin-left: ${shipmentContentPaddingDesktop * -1}px;
  }
`

export const StyledShipmentContentHeadLeftWrap = styled.div`
  display: flex;
  min-width: 0;
`

export const StyledShipmentWrap = styled.div`
  position: relative;
  z-index: 1;
  flex-grow: 1;
  margin-top: -30px;

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    ${gradientLightOpacityReverse};
    mask: url(${FadeMask});
    mask-size: cover;
  }
`

export const StyledShipmentContent = styled.div`
  position: relative;
  padding: 0 15px;
  margin-top: 10px;

  ${StyledTabNavigation} {
    margin-bottom: 35px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    bottom: 0;
    z-index: -1;
  }

  @media ${desktop.up} {
    padding: 0 ${shipmentContentPaddingTablet}px;
  }

  @media ${desktopLarge.up} {
    padding: 0 ${shipmentContentPaddingDesktop}px ${shipmentContentPaddingDesktop}px;
  }
`

export const StyledShipmentContentTabs = styled(Tabs)`
  ${StyledTabPanel} {
    @media ${desktopLarge.up} {
      max-width: ${PageContentMaxWidth};
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const StyledShipmentInformationContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  @media ${desktopLarge.up} {
    flex-direction: row;
    align-items: flex-start;
  }

  ${StyledBlockList} {
    margin-top: 28px;

    @media ${desktopLarge.up} {
      margin-top: 0;
      margin-left: 20px;
      flex: 0 0 calc(33.33% - 10px);
    }
  }

  ${StyledBlockTransportation} {
    @media ${desktopLarge.up} {
      flex: 0 0 calc(66.66% - 10px);
    }
  }
`

export const StyledShipmentStateSwitchButton = styled.button`
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: ${zIndexMobileSwitch};

  ${StyledButtonIcon} {
    fill: ${(props) => props.theme.textLight};
  }

  @media ${desktop.up} {
    display: none;
  }
`

export const StyledShipment = styled.div`
  position: relative;
  display: flex;
  height: 100%;

  @media ${desktop.down} {
    ${StyledQuickInfoIcon} {
      opacity: 0;
    }

    ${(props) => {
      if (props.$viewState === 'timeline') {
        return css`
          ${StyledShipmentMain} {
            display: none;
          }
          ${StyledShipmentLeftCol} {
            flex: 0 0 100%;
          }
        `
      }

      return css`
        ${StyledShipmentLeftCol} {
          display: none;
        }
      `
    }}
  }
`

export const StyledMap = styled.div`
  height: ${({ $fullscreen }) => ($fullscreen ? '100%' : '250px')};
`

export const StyledText = styled.p``

export const StyledShipmentReportedInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 10px 0;
  justify-content: flex-end;
`

export const StyledInfoIcon = styled.div`
  padding: 3px 10px 0 10px;
`

// dateDeclarationModal

export const StyledModal = styled.div``

export const StyledModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export const StyledMilestonesEditButton = styled.button`
  ${textSmallDescription};
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 3;
  color: ${({ theme }) => theme.textLight};
  transition: color 0.5s ${easeQuartOut};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`

export const StyledQuickInfoLabel = styled.div`
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 10px;
`

export const StyledMilestoneDatepickerLabel = styled(StyledDatePickerLabel)`
  display: flex;
  span {
    color: ${({ theme }) => theme.primary};
  }
  gap: 5px;
  align-items: flex-end;
`

export const StyledEditTimelineButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  margin-bottom: 15px;
`
