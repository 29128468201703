import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AlertList from 'components/alert_list'
import AlertListItem from 'components/alert_list_item'
import useAlerts from 'views/shipment/hooks/use_alerts'
import ShipmentContext from 'views/shipment/context'
import { fetchAlerts } from 'views/shipment/slice'
import ErrorNotification from 'views/errors/error_notification'
import { ALERT_TYPE_ET_CHANGES, ALERT_TYPE_LOCATION_CHANGES } from 'constants/alerts'
import DateHelper from 'services/helpers/date_helper'
import { isEmptyArray } from 'services/helpers/values'
import NotifMessage from 'components/notif_message'
import ShipmentReportedInfos from 'views/shipment/components/reported_info'
import useShipment from 'views/shipment/hooks/use_shipment'

const ShipmentAlertList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = useContext(ShipmentContext)
  const [shipment, , isFlagged] = useShipment({ id })
  const [alerts, status] = useAlerts({ id })

  useEffect(() => {
    if (!status.ready) {
      dispatch(fetchAlerts({ id }))
    }
  }, [dispatch, id, status.ready])

  return (
    <>
      {isFlagged && <ShipmentReportedInfos reportedReasons={shipment.orderFlags} />}
      {status.rejected && <ErrorNotification />}
      {isEmptyArray(alerts) && (
        <NotifMessage
          type='success'
          text={t('alerts.noResults.text')}
          title={t('alerts.noResults.title')}
          padded
        />
      )}
      <AlertList>
        {alerts.map((a) => {
          let title = t(`static.alerts.${a.type}`)
          let details = null
          if (ALERT_TYPE_ET_CHANGES.includes(a.type)) {
            title += ` ${new DateHelper(a.occurredAt).toLocale({ hours: true })}`
            details = a.details.location.name
          } else if (ALERT_TYPE_LOCATION_CHANGES.includes(a.type)) {
            details = `${a.details.from} => ${a.details.to}`
          }
          return (
            <AlertListItem
              title={title}
              declaredAt={a.declaredAt}
              details={details}
              key={`alert-${a.type}-${a.occurredAt}-${a.declaredAt}`}
            />
          )
        })}
      </AlertList>
    </>
  )
}

ShipmentAlertList.propTypes = {}

ShipmentAlertList.defaultProps = {}

export default ShipmentAlertList
