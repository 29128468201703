import {
  BOOKING_BOOKING_CONFIRMED,
  BOOKING_TRANSPORT_ORDER_NOT_SENT,
  BOOKING_TRANSPORT_ORDER_SENT,
} from 'constants/booking_confirmation'
import { STEP_STATUS_CURRENT, STEP_STATUS_FUTURE, STEP_STATUS_PAST } from 'constants/shipments'
import { isNull } from 'services/helpers/values'

import useShipment from 'views/shipment/hooks/use_shipment'

const useBookingConfirmation = ({ id }) => {
  const [shipment, shimentStatus] = useShipment({ id })

  // TODO: REFACTOR: Back-end should not have different bookingConfirmation response format for get /shipment and get /booking_confirmation
  if (isNull(shipment?.bookingConfirmation?.status)) return [{}, false]

  const { tet, tat, bet, bat, status } = shipment.bookingConfirmation
  const timelineStatus = {
    [BOOKING_TRANSPORT_ORDER_NOT_SENT]: STEP_STATUS_FUTURE,
    [BOOKING_TRANSPORT_ORDER_SENT]: STEP_STATUS_CURRENT,
    [BOOKING_BOOKING_CONFIRMED]: STEP_STATUS_PAST,
  }[status]
  const isTransportOrderSent = [BOOKING_TRANSPORT_ORDER_SENT, BOOKING_BOOKING_CONFIRMED].includes(
    status
  )
  const isBookingConfirmed = status === BOOKING_BOOKING_CONFIRMED
  return [
    { tet, tat, bet, bat, timelineStatus, isTransportOrderSent, isBookingConfirmed },
    shimentStatus.ready,
  ]
}

export default useBookingConfirmation
